import React, { useState } from 'react'
import s from './Filters.module.css';

//assets
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//materialUi
import {
    Grid,
    Modal,
    TextField,
    Button
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";

const FiltersModal = ({open, setOpen, editQuery}) => {

    const { t } = useTranslation();

    const [query, setQuery] = useState('');

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
        <Grid container>
            <Grid xs={12} md={8} lg={4.5} item container className={s.boxModal} sx={{ bgcolor: "#EEEEEE" }}>
               <Grid container item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                  <Grid item xs={11} className={s.exit}>
                     <div className={s.titleModal}>
                           <p>{t('filter')}</p>
                           <span>{t('filterConfig')}</span>
                     </div>
                     <CloseIcon onClick={() => setOpen(false)} className={s.exitIcon} sx={{ color: "primary.dark" }} />
                  </Grid>

                  <Grid item xs={10} sx={{my: 2}}>
                     <TextField
                        label={t('findByName')}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        sx={{width: '100%'}}
                     />
                  </Grid>

                  <Grid item xs={10} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3}} onClick={() => {
                     editQuery(query);
                     setOpen(false);
                  }}>
                     <Button variant='contained'>{t('search')}</Button>
                  </Grid>
               </Grid>
            </Grid>
        </Grid>
    </Modal>
    )
};

export default FiltersModal;