import React, { useState } from 'react'
import s from './AgencySelectModal.module.css';

//assets
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect } from "react";

import { getSubAgenciesByAgencie } from "../../../api/agencies/AgenciesService"

import { searchpointsInArray } from "../SearchBar/searchBarArray"

//materialUi
import {
    CircularProgress,
    TextField,
    Button,
    Grid,
    Modal,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Pagination
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";


const cellStyles = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    borderBottom: '1px solid rgba(0, 0, 0, 0.455)',
    cursor: "pointer"
}


const AgencySelectModal = ({open, setOpen, onSelect}) => {

    const { t } = useTranslation();
    const { textColor } = useSelector(state => state.local);
    const { activeOrganization } = useSelector(state => state.gamma)
    const { user_agencies } = useSelector (state => state.myAgencies)
    
    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const [search, setSearch] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [searching, setSearching] = useState(false)
    const [inputName, setInputName] = useState("")
    const [inputNumber, setInputNumber] = useState("")

    const handlePaginate = (e, v) => {
		setSearch(v)       
	}

    function getAgencyData(ag) {
        if (ag.agencyLevel === 'CONCENTRATOR' ) {
            const subAg = ag.childrenAgencies.map((subAg) => {
                return getAgencyData(subAg);
            }).flat();
            subAg.push(ag);
            return subAg
        } else {
            return [ag];
        }
    }

    function parseAgencyNumber(number) {
        const parts = number.split('-');
        const numberPart = parts[0];
        const subNumberPart = parts.length > 1 ? parts[1] : null;
        return {
            number: !isNaN(parseInt(numberPart)) ? parseInt(numberPart) : undefined,
            subNumber: !isNaN(parseInt(subNumberPart)) ? parseInt(subNumberPart) : undefined
        }
    }

    async function getAgencyEntries() {
        const allAgencies = user_agencies.map(ag => getAgencyData(ag)).flat();
        const hasSearchElements = inputNumber.length > 0 || inputName.length > 0;
        if(hasSearchElements) {
            const inputNumberFinal = parseAgencyNumber(inputNumber);
            const inputNameFinal = inputName || "";
            const resultsByNumber = allAgencies.filter(ag => ag.number === inputNumberFinal.number && (inputNumberFinal.subNumber === undefined || ag.subNumber === inputNumberFinal.subNumber)).map(ag => ag.id);
            const resultsByName = inputName?.length > 0 ? allAgencies.filter(ag => (ag?.name || "").toLowerCase().includes(inputNameFinal.toLowerCase())).map(ag => ag.id) : [];
            const allResults = resultsByNumber.concat(resultsByName.filter((item) => resultsByNumber.indexOf(item) < 0))
            const allResultsAgencies = allAgencies.filter(ag => allResults.includes(ag.id)).sort((a, b) => (-(a.number + (a.subNumber !== null ? '-' + a.subNumber.toString() : '')).localeCompare(b.number.toString() + (b.subNumber !== null ? '-' + b.subNumber.toString() : ''))));
            await setItems(allResultsAgencies);
            await setAllItems(allResultsAgencies);
        } else {
            await setItems(allAgencies);
            await setAllItems(allAgencies);
        }
        //     if (ag.agencyLevel === 'CONCENTRATOR' ) {
        //         getSubAgenciesByAgencie(activeOrganization?.id, ag.number, page, ag.subNumber || 0)
        //         .then(res => {
        //             setItems(res.childrenAgencies);
        //             setItems(prevState => [...prevState, ag]);
        //             setAllItems(prevState => [...prevState, ...res.childrenAgencies]);
        //             setAllItems(prevState => [...prevState, ag]);
        //             setTotalPages(res.totalPages);
        //         })
        //     } else {
        //         setAllItems(prevState => [...prevState, ag]);
        //         setItems(prevState => [...prevState, ag]);
        //     }
        // })
    }
    useEffect(() => {
        if(search) {
            setLoading(true);
            getAgencyEntries()
                .then(() => setLoading(false))
                .then(() => setSearch(false));
        }
    }, [search])

    const handleSearchName = (e) => {
        setInputName(e.target.value)
    }
    const handleSearchNumber = (e) => {
        setInputNumber(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSearching(true);
        setSearch(true);
    }

    const handleRefresh = (e) => {
        e.preventDefault()
        setSearching(false);
        setInputName('');
        setInputNumber('');
        setSearch(true);
    }


    return (
        <Modal open={open} onClose={() => setOpen(false)}>
        <Grid container>
            <Grid xs={12} md={8} lg={4.5} item container className={s.boxModal} sx={{ bgcolor: "#EEEEEE" }}>
            <Grid container item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                <Grid item xs={11} className={s.exit}>
                    <div className={s.titleModal}>
                        <p>{t('selectAgency')}</p>
                        <span>{t('select')}</span>
                    </div>
                    <CloseIcon onClick={() => setOpen(false)} className={s.exitIcon} sx={{ color: "primary.dark" }} />
                </Grid>
        
                <Grid item xs={11} sx={{mb: 2, display: "flex", justifyContent: 'space-between', alignItems: 'center'}} className={s.search}>
                    <Grid item xs={11.4} md={5.5} lg={5.5} sx={{mb: 2, mr: 2}}>
                        <TextField
                            label={t('findByName')}
                            value={inputName}
                            onChange={handleSearchName}
                            sx={{width: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={11.4} md={5.5} lg={5.5} sx={{mb: 2}}>
                        <TextField
                            label={t('findByNumber')}
                            value={inputNumber}
                            onChange={handleSearchNumber}
                            sx={{width: '100%'}}
                        />
                    </Grid>
                </Grid>

                <Grid item container xs={11} md={11} lg={11} sx={{mb: 2, display: "flex", justifyContent: "flex-start", alignItems: "center"}} className={s.search}>
                    <Grid item xs={3} sx={{mr: 1}}>
                        <Button variant='contained' type='submit' disabled={!inputName.length && !inputNumber.length} sx={{width: '90%'}} onClick={handleSubmit}>{t('apply')}</Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant='contained' disabled={!searching} onClick={handleRefresh} sx={{width: '90%'}}>{t('clear')}</Button>
                    </Grid>
                </Grid>

                {
                    loading ?
                    <Grid item xs={12} className={s.loader}>
                        <CircularProgress/>
                    </Grid> : items?.length ?
                    <Grid item container xs={11} className={s.data}>
                        <TableContainer className={s.tableContainer}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableCell sx={{backgroundColor: 'primary.main', color: textColor, fontSize: "14px", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'}}>{t('agencyNumber')}</TableCell>
                                    <TableCell sx={{backgroundColor: 'primary.main', color: textColor, fontSize: "14px", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'}}>{t('name')}</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        (items.sort((a, b) => a.number === b.number ? (a.subNumber - b.subNumber) : (a.number - b.number))).map((item, i) => {     
                                        let date = item.raffleDate?.split('T')[0].split('-').reverse().join('-');                               
                                        return (
                                        <>
                                            <TableRow key={item.id} sx={{"&:hover": {backgroundColor: "secondary.light"}}} onClick={() => {
                                                onSelect(item) 
                                                setOpen(false)
                                            }}>
                                                <TableCell sx={{...cellStyles,  backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12"}}>{`${item.number} ${item.subNumber ? ' - ' + item.subNumber :  item.agencyLevel === 'CONCENTRATOR' ? ' ' : ' - 0'}`}</TableCell>
                                                <TableCell sx={{...cellStyles,  backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12"}}>{item.name}</TableCell>
                                            </TableRow>
                                        </>
                                        )})
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> :
                    <Grid item xs={12} className={s.noresult}>
                        {t('noResult')}
                    </Grid>
                }
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', my: 5}}>
                    <Pagination count={totalPages} page={search} onChange={handlePaginate} color='primary' disabled={loading}/>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    </Modal>
    )
};

export default AgencySelectModal;