import { setTermsAndConditionError } from "../../store/slices/errors"

export async function getAllTermsAndConditions(page, perPage, dispatch) {
  try{
    const response = await fetch( `/api-terms/terms?pageNumber=${page || 1}&pageSize=${perPage || 10}&orderCriteria=ASC&orderField=title`);
    if(response.status === 200 ){
      dispatch(setTermsAndConditionError({error: false, code: response.status, trys: false}))
      return await response.json()
      
    }else{
      throw new Error(response.status)
    }
  }catch(e){
    console.log(e);
    dispatch(setTermsAndConditionError({error: true, code: e.status || e.message, trys: true}))
  }
  
}

export async function createTermsAndCondition(payload) {
  try{
    return await fetch(`/api-terms/terms`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    })
  }catch(e){
    console.log(e);
  }
}

export async function updateTermsAndCondition(payload) {
  try{
    return await fetch(`/api-terms/terms`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    })
  }catch(e){
    console.log(e);
  }
}

export async function deleteTermsAndCondition(TermsAndConditionId) {
  try{
    return await fetch(`/api-terms/terms/${TermsAndConditionId}`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'}
    })
  }catch(e){
    console.log(e);
  }
}