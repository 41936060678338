import index from "../../mock-data/uiConfig/index.json";

export const getAllThemes = async () => {
    try{
        const response = await fetch(`/api-themes/themes/pallete`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        return response.json();
    }catch(e){
        console.log(e)
    }
}

export  const currentTheme = async (organizationid) => {
    try{
        const response = await fetch(`/api-themes/themes/pallete/getCurrentThemes/${organizationid}`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        return response.json();
    }catch(e){
        console.log(e)
    }
}

export const addNewTheme = async (payload) => {
    try{
        const response = await fetch(`/api-themes/themes/pallete`,{
            method: "POST",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        return response;
    }catch(e){
        console.log(e)
    }
}

export const editTheme = async (payload) => {
    try{
        const response = await fetch(`/api-themes/themes/pallete`,{
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        return response.json();
    }catch(e){
        console.log(e)
    }
}

export const deleteTheme = async (themeId) => {
    try{
        return  await fetch(`/api-themes/themes/pallete/${themeId}`,{
            method: "DELETE",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch(e){
        console.log(e)
    }
}

export const setDefaultTheme = async (themeId, organizationId) => {
    let payload = {
        id: themeId,
        organizationId: organizationId,
        defaultP: true
    }

    try {
        return await fetch(`/api-themes/themes/pallete/setdefault`,{
            method: "POST",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch (e){
        console.log(e)
    }
}

export const setCurrentTheme = async (themeId, organizationId) => {
    let payload = {
        id: themeId,
        organizationId: organizationId,
        current: true
    }
    try {
        return await fetch(`/api-themes/themes/pallete/setcurrent`,{
            method: "POST",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch (e){
        console.log(e)
    }
}

export const getAllLogos = async () => {
    try{
        const response = await fetch(`/api-themes/themes/logo`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        return response.json();
    }catch(e){
        console.log(e)
    }
}

export const addNewLogo = async (payload) => {
    try{
        return await fetch(`/api-themes/themes/logo`,{
            method: "POST",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch(e){
        console.log(e)
    }
}

export const editLogo = async (payload) => {
    try{
        return await fetch(`/api-themes/themes/logo`,{
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch(e){
        console.log(e)
    }
}

export const deleteLogo = async (logoId) => {
    try{
        return await fetch(`/api-themes/themes/logo/${logoId}`,{
            method: "DELETE",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch(e){
        console.log(e)
    }
}

export  const currentLogo = async (organizationid) => {
    try{
        const response = await fetch(`/api-themes/themes/logo/getCurrentLogo/${organizationid}`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        return response.json();
        //return index.logos.primary;
    }catch(e){
        console.log(e)
    }
}

export const setDefaultLogo = async (logoId, organizationId) => {
    let payload = {
        id: logoId,
        organizationId: organizationId,
        defaultL: true
    }
    console.log("SetDefault payload",payload)
    try {
        return await fetch(`/api-themes/themes/logo/setdefault`,{
            method: "POST",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch (e){
        console.log(e)
    }
}

export const setCurrentLogo = async (logoId, organizationId) => {
    let payload = {
        id: logoId,
        organizationId: organizationId,
        current: true
    }
    try {
        return await fetch(`/api-themes/themes/logo/setcurrent`,{
            method: "POST",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch (e){
        console.log(e)
    }
}

