import { setUsersError } from "../../store/slices/errors"

export const create_user = async (payload) => {
    try{
        const response = await fetch(`/gamma-users/users`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' }
        });
        return response;
    }catch(e){
        console.log(e)
    }
}

export const editUser = async (payload) => {
    try{
        const response = await fetch(`/gamma-users/users`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' }
        });
        return response;
    }catch(e){
        console.log(e)
    }
}

export const deleteUser = async (userId) => {
    try{
        const response = await fetch(`/gamma-users/users/${userId}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        });
        return response;
    }catch(e){
        console.log(e)
    }
}

export const searchUser = (payload) => {
    return fetch(`/gamma-users/users?orderCriteria=ASC&orderField=lastname&query=${payload}`,{
        method: "GET",
        headers: { 'Content-Type': 'application/json' }
    })
      .then(res => res.json())
      .then(json => json)
      .catch(e => console.log(e))
}

export const searchUsersByOrganization = async (orgId, page, perPage, dispatch, filters, query) => {
    try{
        let response = await fetch(`/gamma-users/organizations/${orgId}/users?pageNumber=${page || 1}&pageSize=${perPage || 10}&orderCriteria=${filters?.increasing ? "ASC" : "DESC"}&orderField=${filters?.name || "firstname"}${query ? `&query=${query}` : ''}`,{
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        })
        if(response.status !== 200){
            throw new Error(response.status)
        }
        dispatch(setUsersError({error: false}))
        return await response.json()
    }catch(e){
        console.log(e)
        
    }
}

export const getUsersByUUID = async (payload) => {
    try {
        const response = await fetch (`/gamma-users/users/${payload}`,{
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        })
        return await response.json()
    } catch (e) {
        console.log(e)
    }
}
