import "./index.css";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import {resetLoggedUser} from "../../../store/slices/gammaSlice"
import { useDispatch } from "react-redux";
import {resetAgenciesSlice} from "../../../store/slices/my_agencies";
import { resetErrors } from "../../../store/slices/errors"


export const Login = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  dispatch(resetLoggedUser());
  dispatch(resetAgenciesSlice())
  dispatch(resetErrors())
  useEffect(() => {
    setTimeout(() => {
      !keycloak?.authenticated ? keycloak?.login() : navigate("check-user");
    }, 3000);
  }, [keycloak, navigate]);
};
