import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { resetGammaSlice } from "../../store/slices/gammaSlice";
import { resetLocalSlice } from "../../store/slices/localSlice";
import { resetAgenciesSlice } from "../../store/slices/my_agencies";
import { resetPaginationSlice } from "../../store/slices/paginationSlice";
import { resetErrors } from "../../store/slices/errors"
import { resetPointsSlice } from "../../store/slices/pointsSlice";
import { resetUserSlice } from "../../store/slices/userSlice";

export const LogOut = () => {

    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const resetStates = new Promise((res) => res(keycloak.logout()))

    resetStates.then(() => {
        dispatch(resetGammaSlice())
        dispatch(resetLocalSlice())
        dispatch(resetAgenciesSlice())
        dispatch(resetPaginationSlice())
        dispatch(resetErrors())
        dispatch(resetPointsSlice())
        dispatch(resetUserSlice())
    })
    
 
}