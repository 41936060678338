import { useDispatch} from "react-redux"
import { UsersPrevPage, UsersNextPage, UsersChangePage, loadingUsersPage } from "../../../store/slices/paginationSlice"
import { setActualUsers } from "../../../store/slices/userSlice";
import { searchUsersByOrganization } from "../../../api/users/UsersConfig";

const Pagination = (actualPage, totalPages, OrgId, filters) => {
    
    const dispatch = useDispatch()
    const currentAgencies = async () => {
        try{
            const response = await  searchUsersByOrganization(OrgId, null, null , dispatch, filters)
            dispatch(setActualUsers(response));
        }catch(e){
            console.log(e)
        }
    }

    const next = () => {
        actualPage !== totalPages && dispatch(UsersNextPage())
    }

    const prev = () => {
        actualPage !== 1 && dispatch(UsersPrevPage())
    }

    const jump = (page, perPage) => {
            dispatch(loadingUsersPage(true))
            searchUsersByOrganization(OrgId, page, perPage, dispatch, filters)
            .then(res => {
                dispatch(setActualUsers(res))
                dispatch(UsersChangePage(page))
                dispatch(loadingUsersPage(false))
            })
            
    }

    return {next, prev, jump, currentAgencies, actualPage, totalPages}
}

export default Pagination
