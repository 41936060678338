
import { useState } from "react"
import s from "./NewSearchBar.module.css"
import { Grid, Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import {searchUser, searchUsersByOrganization} from "../../../api/users/UsersConfig";
import { useTranslation } from "react-i18next";
import { searchInArray } from "./searchBarArray";

const NewSearchBar = ({setSearching, searching, setUsers, setLoading, setTotalPages, orgSelectedId, page, perPage, filters, users, allUsers}) => {
    
    const currentUser = useSelector((state) => state.gamma.logged_user);
    const dispatch = useDispatch()
    const [ input, setInput ] = useState("")


    console.log("usuarios que vienen de USERS", allUsers)

    const { t } = useTranslation();


    const handleSearch = (e) => {
        setInput(e.target.value)
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        searchUser(input)
        .then((res) => {
            if(res.content.length !== 0){
                setUsers(res.content);
                setTotalPages(res.totalPages)

            }else{
                setUsers([]);
                setTotalPages(1);
            }
        })
        .then(() => {
            setLoading(false)
            setSearching(true);
        })
    }

    const handleRefresh = (e) => {
        e.preventDefault()
        setSearching(false);
        setLoading(true);
        searchUsersByOrganization(orgSelectedId, page, perPage, dispatch, filters).then(res => {
            setUsers(res.content);
            setTotalPages(res.totalPages)
        })
        .then(() => {
            setLoading(false)
        })
        setInput("")
    }


    const handleSubmitUsersFilter = (e) => {
        e.preventDefault()
        setSearching(true)
        let resultFilt = searchInArray(allUsers, input)
        setUsers(resultFilt)
        
    }

    const handleRefreshUserFilter = (e) => {
        e.preventDefault()
        setSearching(false)
        setUsers(allUsers)
        setInput("")
    }


    return (
        <>

        { currentUser?.memberships[0].roles[0].name === "Administrador Loteria" ?
            <form onSubmit={(e) => handleSubmit(e)}>
                <Grid item xs={12} className={s.searchbar} sx={{borderColor: "primary.main"}}>
                    <TextField fullWidth type='text' value={input} placeholder={t("insertUser")} onChange={handleSearch} className={s.textfield} sx={{borderColor: 'primary.main'}}/>
                    <Button disabled={!input.length} variant='contained' type='submit' className={s.button}>{t("search")}</Button>
                    {
                        searching ? <Button variant='contained' onClick={handleRefresh} className={s.button}>{t("cleanFilters")}</Button> : <></>
                    }
                </Grid> 
            </form> :
            <form onSubmit={(e) => handleSubmitUsersFilter(e)}>
                <Grid item xs={12} className={s.searchbar} sx={{borderColor: "primary.main"}}>
                    <TextField fullWidth type='text' value={input} placeholder={t("insertUser")} onChange={handleSearch} className={s.textfield} sx={{borderColor: 'primary.main'}}/>
                    <Button disabled={!input.length} variant='contained' type='submit' className={s.button}>{t("search")}</Button>
                    {
                        searching ? <Button variant='contained' onClick={handleRefreshUserFilter} className={s.button}>{t("cleanFilters")}</Button> : <></>
                    }
                </Grid> 
            </form>
        }
        </>
    )
}

export default NewSearchBar