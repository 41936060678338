import * as React from "react";
import { useEffect, useState } from "react";
import { UserPoints } from "./UserPoints/UserPoints"
import { useSelector, useDispatch} from "react-redux";
import s from "./index.module.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupIcon from '@mui/icons-material/Group';
import IconButton from "@mui/material/IconButton";
import { Box, Grid } from "@mui/material/";
import NewSearchBar from "../../Shared/SearchBar/NewSearchBar"
import { UserData } from "./UserData/UserData.jsx"
import { UserRoles } from "./UserRoles/UserRoles.jsx"
import {
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  CircularProgress,
  MenuItem,
  Select,
  Tooltip,
  TextField
} from "@mui/material";
import { setErrors, setErrorsDefault} from "../../../store/slices/userSlice"
import { usersUpdate } from "../../../store/slices/gammaSlice"
import Typography from '@mui/material/Typography';
import {PointsChangePage} from "../../../store/slices/paginationSlice"
import { ConvertColorText} from "../../../helpers/ConvertColorText";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import noImage from '../../../assets/blank-user.jpg'
import { createRow } from "../../../helpers/tableFunctions";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { setActualUsers } from "../../../store/slices/userSlice";
import { t } from "i18next";
import { loadingUsersPage, setSearchUsersPageState} from "../../../store/slices/paginationSlice"
import DetailsUser from "./Details/DetailsUser";
import { orderUsers } from "../../../helpers/orderBy";
import HandleRequests from "../../Shared/handleRequests/HandleRequests";
import { deleteUser, searchUsersByOrganization, editUser, create_user } from "../../../api/users/UsersConfig";
import { addRelationship, sendRelationedAgencies } from "../../../api/agencies/AgenciesService";
import { useKeycloak } from "@react-keycloak/web";
import usePagination from "./Pagination.jsx"

export const Users = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const errors = useSelector(state => state.users?.errors)
  const { actualUsersOrg } = useSelector(state => state.users)
  const { activeOrganization } = useSelector((state) => state.gamma)
  const location = useLocation();
  const { createUser } = props
  const { textColor } = useSelector((state) => state.local);
  const { usersSearched } = useSelector(state => state.users)
  const agencieSelected = useSelector(state => state.myAgencies?.selected_agencie_to_consult);
  const [ consultingAgencie, setConsultingAgencie ] = useState({})
  const currentUser = useSelector((state) => state.gamma.logged_user);
  
  let id_agency = activeOrganization?.id;
  let name_agency = activeOrganization?.name;
  
  let obj = {
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    enabled: true,
    memberships: [
      {
        selected: true,
        organization: {
          id: id_agency,
          name: name_agency
        },
        roles: []
      }
    ]
  }

  const [filters, setFilters] = useState({
    name: 'lastname',
    increasing: true,
  });
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  
  const [selected, setSelected] = useState(createUser? obj : null);
  const [edit, setEdit] = useState(true);

  const [userEdit, setUserEdit] = useState(createUser? obj : {});
  const [chipData, setChipData] = useState([]);
  const [chipDataRoles, setChipDataRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [points, setPoints] = useState([]);
  const [userPoints, setUserPoints] = useState([])
  const [sent, setSent] = useState(false)

  const [openModal, setOpenModal] = useState(false);

  
  let save = true;
  if ( selected !== null && Object.keys(selected).length && Object.keys(userEdit).length &&  !Object.values(errors).some(obj => obj === true)){
    save = JSON.stringify(selected) === JSON.stringify(userEdit);
  }
  const {keycloak} = useKeycloak();
  const orgSelectedId = useSelector((state) => state?.gamma?.activeOrganization?.id)
  const usersRedux = useSelector((state) => state.gamma?.users);
  const { uiConfig } = useSelector((state) => state?.local);
  const rols = useSelector((state) => state.gamma?.roles?.content);
  const salePoints = useSelector((state) => state.gamma?.salePoints?.content);
  const backError = useSelector(state => state.errors.usersError)
  const { searchedUsersState } = useSelector(state => state.pagination)


  const [page, setPage] = useState(1);
  const [customPage, setCustomPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [ perPage, setPerPage ] = useState(10);
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rolLogin, setRolLogin] = useState();

  const _PAGINATION = usePagination(page, totalPages, orgSelectedId)

  const handlePagination = (e, v) => {
    _PAGINATION.jump(v);
    setPage(v);
  }

  ConvertColorText(uiConfig.primary.main)

  let create;

  if(userEdit.memberships?.length){
    create = userEdit.firstname !== "" && userEdit.lastname !== "" && userEdit.email !== "" && userEdit.password !== "" && !Object.values(errors).some(obj => obj === true)
  }

  useEffect(() => {
    setRolLogin(currentUser?.memberships[0].roles[0].name)
  }, [currentUser, dispatch])

  useEffect(() => {
    if(searchedUsersState){
      dispatch(loadingUsersPage(false))
      dispatch(setSearchUsersPageState(false))
    }
  }, [searchedUsersState, dispatch])

  useEffect(() => {
    setConsultingAgencie(agencieSelected)
  }, [agencieSelected])

  useEffect(() => {
    setRoles(rols);
    setPoints(salePoints);
    dispatch(PointsChangePage(1))
  }, [rols, salePoints, dispatch]);


  useEffect(() => {
    if(createUser){
      setEdit(false)
    }
    setPrimaryColor(uiConfig.primary.main);
    setSecondaryColor(uiConfig.secondary.main);
  }, [dispatch, uiConfig, createUser]);

  
  useEffect(() => {
    if(rolLogin === "Administrador Loteria"){
      setLoading(true);
      searchUsersByOrganization(orgSelectedId, page, perPage, dispatch, filters).then(res => {
        setUsers(res.content);
        setTotalPages(res.totalPages)
      })
      .then(() => setLoading(false))
    }else{
      
      setUsers(usersRedux);
      setAllUsers(usersRedux);
      dispatch(loadingUsersPage(false))
    }
  }, [dispatch, filters, orgSelectedId, page, perPage, rolLogin, usersRedux])


  const usersReduxnNoDuplication = Array.isArray(users) && users?.filter((valor, indice) => {
    return users?.indexOf(valor) !== indice;
  });

  const cellStyles = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.9rem',
    fontWeight: '400',
    borderBottom: '1px solid rgba(0, 0, 0, 0.455)',
    cursor: "pointer"
  }

  const handleChangePerPage = (e) => {
    setPerPage(e * 1)
  }

  const handleOnDetail = (item) => {
    setSelected(item);
    setUserEdit(item);
    dispatch(setErrorsDefault())
    let points = [];
    let roles = [];
    item.memberships.length &&
      item.memberships[0].roles?.length &&
      item.memberships[0].roles?.forEach((obj, i) => {
        roles.push({
          key: i,
          label: `${obj.name}`,
          info: `${obj.description}`,
        });
      });
    setChipData(points);
    setChipDataRoles(roles);
    setEdit(true);
  }

  const handleOnSelect = (item) => {
    setSelected(item);
    setUserEdit(item);
    dispatch(setErrorsDefault())
    let points = [];
    let roles = [];
    item.memberships.length &&
      item.memberships[0].roles?.length &&
      item.memberships[0].roles?.forEach((obj, i) => {
        roles.push({
          key: i,
          label: `${obj.name}`,
          info: `${obj.description}`,
        });
      });
    setChipData(points);
    setChipDataRoles(roles);
    setEdit(false);
  };

  const handleDelete = async (user) => {
    Swal.fire({
      title: `${t("areYouSure")} ${user?.email}?`,
      text: `${t("IfYouEliminate")} ${user?.firstname} ${user?.lastname} ${t("theChangeCannotBeReversed")}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t('cancel'),   
      confirmButtonText: t('accept'),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(user.id)
          .then(response => {
        if(response.status === 200){
          Swal.fire(t("deleted"), t("UserDeletedSuccessfully"), "success");
          let usersWithOneDelete = Array.isArray(users) && users?.filter((obj) => obj.email !== user?.email)
          setUsers(usersWithOneDelete);
          searchUsersByOrganization(id_agency, null, null, dispatch)
            .then(response => {
            dispatch(setActualUsers(response));
          })
          dispatch(usersUpdate(usersWithOneDelete))
          setSelected(null);
          setUserEdit({});
        }else{
          Swal.fire({
            icon: "error", 
            title: t('ErrorOcurred')
          })
        }
      })
      }
    });
  };
  const userUUID = keycloak.subject;


  const handleBan = () => {
    userEdit.enabled && (userEdit.id === userUUID) ? 
      Swal.fire({
        icon: 'error',
        title: t('Lo weFeelIt'),
        text: `${userEdit?.firstname} ${userEdit?.lastname} ${t('yourself')}`,
      }) :
      Swal.fire({
        title: userEdit.enabled
        ? `${t('areYouSureS')} ${userEdit?.email}?`
        : `${t('areYouSureR')}${userEdit?.email}?`,
        text: userEdit.enabled
        ? `${t('IfYouFail')} ${userEdit?.firstname} ${userEdit?.lastname} ${t("youCanRevertTheChangeAtAnyTime")}`
        : `${t('ifReactiveTo')} ${userEdit?.firstname} ${userEdit?.lastname} ${t("youCanRevertTheChangeAtAnyTime")}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: t('accept'),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            userEdit.enabled ? "Inactivo." : "Reactivado.",
            userEdit.enabled
            ? `${t('user')}  ${userEdit?.firstname} ${userEdit?.lastname} suspendido con éxito
            Por favor no olvides guardar los cambios`
            : `${t('user')}  ${userEdit?.firstname} ${userEdit?.userEdit} reactivado con éxito
            Por favor no olvides guardar los cambios`,
            "success"
            );
            setUserEdit({
              ...userEdit,
              enabled: !userEdit.enabled,
            });
          }
        });  
      };

  const handleSave = async () => {
    Swal.fire({
      title: t('AreYouSreToSaveTheChanges'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t('cancel'),
      confirmButtonText: t('accept'),
    }).then((result) => {
      if(result.isConfirmed){
        
        let userUpdate = users?.filter((obj) => obj.email !== selected?.email);
        const obj = {
          ...userEdit,
          memberships: userEdit?.memberships?.map(obj => {
            return {
              ...obj, 
              selected: true
            }
          })
        }
        editUser(obj)
          .then(response => {
        if(response.status === 200){
          userUpdate.push({ ...userEdit });
          setEdit(true);
            Swal.fire({
              icon: "success",
              title: t("changesSavedSuccessfully"),
              showConfirmButton: false,
              timer: 1500,
            })
            setSelected({ ...userEdit });
            setUsers(userUpdate);
            dispatch(usersUpdate(userUpdate))
            setUserPoints([])
            searchUsersByOrganization(id_agency, null, null, dispatch)
              .then(response => {
              dispatch(setActualUsers(response));
            })
              if(userPoints.length >= 1){
                let person = {
                  name: userEdit.firstname,
                  surname: userEdit.lastname,
                  phone: "",
                  mail: userEdit.email,
                  organization: {
                    id: id_agency,
                    name: name_agency
                  }
                }
                addRelationship(id_agency, {person: person, relationshipKind: userPoints.rol})
              }
            }else{
              Swal.fire({
                icon: "error",
                title: t("AnErrorOccurredTryAgain"),
                showConfirmButton: false,
                timer: 1500,
              })
            }
          })
        }
      })
  }


    const handleBack = () => {
      navigate('../users');
    }
    const handleCreate = () => {
      if(users?.find(u => u.email === userEdit.email)){
        Swal.fire({
          icon: 'error',
          title: t("weFeelIt"),
          text: `${t("theMail")} ${userEdit.email} ${t("alreadyRegistered")}`,
        })
      }else{
        const { enabled, ...rest } = userEdit
        create_user(rest)
          .then(response => {
            if(response.status === 200){
              response.json().then(res => {
                let newUsers = users?.filter(user => user.email !== userEdit.email)
                  newUsers.push(userEdit)
                  setUsers(newUsers)
                  Swal.fire({
                    icon: 'success',
                    title: 'Felicidades',
                    text: `${t('user')} ${userEdit.firstname} ${userEdit.lastname} ${t('successfullyCreated')}`,
                  })
                  setUserEdit(obj)
                  setSelected(obj)
                  setChipData([])
                  setChipDataRoles([])
                  setUserPoints([])
                  setSent(true)
                  if(userPoints.length >= 1){
                    // const ids = userPoints?.map(point => point.id)
                    sendRelationedAgencies(res.id, activeOrganization?.id, userPoints)
                  } 
                  handleBack()
              })
            }else{
                Swal.fire({
                  icon: "error",
                  title: t("AnErrorOccurredTryAgain"),
                  showConfirmButton: false,
                  timer: 1500,
                })
              }
          })
      }
    }

    const sx = {width: "90%", marginTop: 9, display: "flex", justifyContent: "center"}
    const sx2 = {width: "90%", marginTop: 9, display: "flex", justifyContent: "flex-start"}

    return (
      <div className={`${s.container} ${location.pathname.includes('main') ? s.marginOnMain : null}`}>
        <HandleRequests error={backError}>

        { !Object.entries(agencieSelected).length && rolLogin !== "Administrador Loteria" ? (
          <>
            {/* <Grid container className={s.titleContainer}>
              <Grid item lg={4} md={6} xs={12} className={s.addUserTitle}>
                <GroupIcon color={"primary"} sx={{
                  fontSize: 32,
                  color: primaryColor,
                }}/>
                <h2>{t('users')}</h2>
                </Grid>
            </Grid>
            <div className={s.noAgencieSelected}>
                <h3>{t('agencyNotSelected')}</h3>
                <Box sx={{backgroundColor: 'primary.main', color: 'white', height: 60}} className={s.redirectBtn}
                    onClick={() => navigate('../agencias')}
                >{t('selectAgency')}</Box>
            </div> */}

            <Box sx={!createUser && !selected ? sx: sx2} className={s.searchBox}>
            {
              !createUser && !selected ? (
              <Grid container className={s.titleContainer}>
                    <>
                    <Grid item lg={4} md={6} xs={12} className={s.addUserTitle}>
                      <GroupIcon color={"primary"} sx={{
                        fontSize: '1.5rem',
                        color: primaryColor,
                      }}/>
                      <h2>{t('users')}</h2>
                      <Link to={"./create"} className={s.createButton}>
                      <Box sx={{backgroundColor: 'primary.main', color: textColor, width: '100%', height: '100%', borderRadius: 2}} className={s.btn}>
                          {t('add')}
                      </Box>
                      </Link>
                    </Grid>
                    <Grid item lg={8} md={6} xs={12}>
                      {/* <SearchBar arr={users || []} keys={{ keys: ["email", "firstname", "lastname"] }} result={"email"} onSelect={handleOnSelect} format={formatResult} primaryColor={ligthPrimaryColor} placeholder={"Ingrese un nombre, apellido o mail para buscar..."} component={"users"}/>*/}
                      <NewSearchBar setSearching={setSearching} searching={searching} setUsers={setUsers} setLoading={setLoading} setTotalPages={setTotalPages} filters={filters} page={page} perPage={perPage} orgSelectedId={orgSelectedId} users={users} allUsers={allUsers} />
                    </Grid>
                    </>
              </Grid>
              )
              : !createUser ?(
                <div className={s.backarrow}>
                  <IconButton onClick={() => setSelected(null)}  >
                  <ArrowBackIcon color={"primary"} sx={{
                    fontSize: 36,
                    cursor: "pointer",
                  }} />
                  </IconButton>
                  <Typography sx={{ fontSize: "36px", fontFamily: 'Roboto, sans-serif', fontWeight: 300, marginLeft: '10px'}} className={s.formTitle}>
                  {
                    createUser ? t('createUser') : t("userData")
                  } 
                  </Typography>
                </div>
              ) : 
              ( <div className={s.backarrow}>
                  <IconButton onClick={() => navigate(-1)}  >
                    <ArrowBackIcon color={"primary"} sx={{
                      fontSize: 36,
                      cursor: "pointer",
                    }} />
                  </IconButton>
                  <Typography sx={{fontSize: "32px", fontFamily: 'Roboto, sans-serif', fontWeight: 300, marginLeft: '10px'}} className={s.formTitle}>
                  {console.log("usuario que viene de redux", users)}
                  {
                      createUser ? t('createUser') : t("userData")
                  }
                  </Typography>
                </div>
              )
            }
            </Box>
            <div className={s.result}>
              {selected ? (
                !edit ? (
                  <div className={s.card}>
                    <UserData userEdit={userEdit} edit={edit} primaryColor={primaryColor}
                      banUnban={handleBan} setUserEdit={setUserEdit} createUser={createUser}
                      errors={errors} setErrors={setErrors} sent={sent} setSent={setSent}
                    />
                    <Grid container className={s.cardSelectors}>
                      <Grid item lg={3.5} xs={11} className={s.cardBody}>
                        <Box sx={{ borderRadius: '10px', p:1, width: '100%'}} display="flex" justifyContent="center">
                          <Box sx={{margin: 1, textAlign: "center", width: '100%'}} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <UserRoles userEdit={userEdit} edit={edit} setUserEdit={setUserEdit} setEdit={setEdit}
                            chipDataRoles={chipDataRoles} setChipDataRoles={setChipDataRoles} users={users}
                            roles={roles} secondaryColor={secondaryColor} primaryColor={primaryColor} errors={errors} createUser={createUser}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item lg={5} xs={11} className={s.cardBody}>
                        <Box sx={{ borderRadius: '10px', p:1, width: '100%'}} display="flex" justifyContent="center">
                          <Box sx={{margin: 1, textAlign: "center", width: '100%'}} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <UserPoints  setEdit={setEdit} chipData={chipData} setChipData={setChipData} points={points}
                            setOpenModal={setOpenModal} openModal={openModal} primaryColor={primaryColor}
                            secondaryColor={secondaryColor} createUser={createUser} setUserPoints={setUserPoints} userPoints={userPoints} userEdit={userEdit}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item lg={3.5} xs={11} className={s.fileInput}>
                        <Box sx={{ borderRadius: '10px', p:1, width: '100%'}} display="flex" justifyContent="center">
                          <Box sx={{margin: 1, textAlign: "center", width: '100%'}} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <h4>{t('profilePicture')}</h4>
                            <img className={s.addImage} src={noImage} alt="Logo" />
                            <label htmlFor={"primaryLogo"} className={s.labelUpload}>
                              <input
                                  type="file"
                                  name="primaryLogo"
                                  id={"primaryLogo"}
                                  className={s.input1}
                                  disabled
                              />
                              <CloudUploadRoundedIcon sx={{ fontSize: 30, color: primaryColor }}></CloudUploadRoundedIcon>
                              <h5>{'Subir imagen'}</h5>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                      <div className={s.saveButton}>
                        {
                          createUser ? (
                            <>
                            <Button
                              variant="outlined"
                              sx={{ width: "50%", mt: 2, mb: 2 }}
                              className={s.button}
                              onClick={handleCreate}
                              disabled={!create}
                            >
                              {create ? t('createUser')  : t('PleaseCompleteTheFields')}
                            </Button>
                            </>
                          ) : (
                            <>
                            {
                            (!edit || selected.enabled !== userEdit.enabled) && (
                            <Button
                              variant="outlined"
                              sx={{ width: "50%", mt: 2, mb: 2 }}
                              className={s.button}
                              onClick={handleSave}
                              disabled={save}
                            >
                              {save ? "Sin cambios para guardar" : "Guardar cambios"}
                            </Button>)
                            }
                            </>
                          )
                        }
                      </div>
                  </div>

                ) : (
                  <DetailsUser userEdit={userEdit}/>
                )
              ) : (
                <div className={s.addUser}>
                  {
                    !loading ? !users?.length ? 
                      <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "300px"}}>
                        <span>{t('noResult')}</span>
                      </Grid> : <>
                    <Grid item sx={{width: "100%"}} className={s.tableContainer}>
                      <TableContainer className={s.table}>
                        <Table>
                          <TableHead className={s.tableHead}>
                            <TableRow color={primaryColor} style={{backgroundColor: primaryColor}} sx={{"&:hover": {backgroundColor: "secondary.light"}}}>
                              {createRow(t('state'), 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'})}
                              {createRow(`${t('name')} ${filters.name === 'firstname' ? filters.increasing ? '▼' : '▲': ''}`, 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif', cursor: 'pointer'}, () => {
                                filters.name === 'firstname' ? setFilters({...filters, increasing: !filters.increasing}) : setFilters({ name: 'firstname', increasing: true});
                              })}
                              {createRow(`${t('surname')} ${filters.name === 'lastname' ? filters.increasing ? '▼' : '▲': ''}`, 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif', cursor: 'pointer'}, () => {
                                filters.name === 'lastname' ? setFilters({...filters, increasing: !filters.increasing}) : setFilters({ name: 'lastname', increasing: true});
                              })}
                              {createRow(t('dni'), 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'})}
                              {createRow(`Email ${filters.name === 'email' ? filters.increasing ? '▼' : '▲': ''}`, 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif', cursor: 'pointer'}, () => {
                                filters.name === 'email' ? setFilters({...filters, increasing: !filters.increasing}) : setFilters({ name: 'email', increasing: true});
                              })}
                              {createRow(t('edit'), 'center', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'})}
                              {createRow(t('delete'), 'center', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'})}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              users?.map((user, i) => {
                              return (
                                  <TableRow key={i} sx={{"&:hover": {backgroundColor: "secondary.light"}}}>
                                      {createRow(user.enabled ? t('active') : t('inactive'), "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow(user.firstname, "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow(user.lastname, "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow('-', "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow(user.email, "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow(<EditIcon color={"primary"} sx={{fontSize: '1.8rem', cursor: "pointer", color: primaryColor}} onClick={() => handleOnSelect(user)} className={s.btn}/>, "center" , i % 2 === 0, cellStyles)}
                                      {createRow(<DeleteForeverIcon color={"primary"} sx={{fontSize: '1.8rem', cursor: "pointer", color: primaryColor}} onClick={() => handleDelete(user)} className={s.btn}/>, "center" , i % 2 === 0, cellStyles)}
                                  </TableRow>
                                  )
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    </> :
                    <Grid item xs={11} className={s.loader}>
                      <CircularProgress size={'4rem'}/>
                    </Grid>
                  }
                  {/* <Grid item container xs={7} sx={{justifyContent: 'space-evenly', alignItems: 'center', mb: 5, mt: 5}}>
                    <Select disabled={!users.length} value={perPage} onChange={(e) => handleChangePerPage(e.target.value)}>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>  
                    <Stack spacing={2}>
                      <Pagination
                      showFirstButton 
                      showLastButton
                      count={totalPages}
                      page={page}
                      onChange={handlePagination}
                      color={"secondary"}
                      disabled={!users.length}
                      />
                    </Stack>
                    <form style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '120px'}} onSubmit={e => e.preventDefault()}>
                      <TextField disabled={!users.length} type={'number'} value={customPage} onChange={(e) => setCustomPage(e.target.value)} sx={{width: '100%', mr: '5px'}}/>
                      <Button disabled={!users.length} variant='contained' onClick={() => {
                        if( 0 <= customPage && customPage <= totalPages){
                          setPage(customPage * 1);
                        }else{
                          setCustomPage(page);
                        }
                      }}>Ir</Button>
                    </form>
                  </Grid> */}
                </div>
              )}
              </div>


          </>
          ):(
            <>
            <Box sx={!createUser && !selected ? sx: sx2} className={s.searchBox}>
            {
              !createUser && !selected ? (
              <Grid container className={s.titleContainer}>
                    <>
                    <Grid item lg={4} md={6} xs={12} className={s.addUserTitle}>
                      <GroupIcon color={"primary"} sx={{
                        fontSize: '1.8rem',
                        color: primaryColor,
                      }}/>
                      <h2>{t('users')}</h2>
                      <Link to={"./create"} className={s.createButton}>
                      <Box sx={{backgroundColor: 'primary.main', color: textColor, width: '100%', height: '100%', borderRadius: 2}} className={s.btn}>
                          {t('add')}
                      </Box>
                      </Link>
                    </Grid>
                    <Grid item lg={8} md={6} xs={12}>
                      {/* <SearchBar arr={users || []} keys={{ keys: ["email", "firstname", "lastname"] }} result={"email"} onSelect={handleOnSelect} format={formatResult} primaryColor={ligthPrimaryColor} placeholder={"Ingrese un nombre, apellido o mail para buscar..."} component={"users"}/>*/}
                      <NewSearchBar setSearching={setSearching} searching={searching} setUsers={setUsers} setLoading={setLoading} setTotalPages={setTotalPages} filters={filters} page={page} perPage={perPage} orgSelectedId={orgSelectedId}/>
                    </Grid>
                    </>
              </Grid>
              )
              : !createUser ?(
                <div className={s.backarrow}>
                  <IconButton onClick={() => setSelected(null)}  >
                  <ArrowBackIcon color={"primary"} sx={{
                    fontSize: '1.8rem',
                    cursor: "pointer",
                  }} />
                  </IconButton>
                  <Typography sx={{ fontSize: "1.5rem", fontFamily: 'Roboto, sans-serif', fontWeight: 300, marginLeft: '10px'}} className={s.formTitle}>
                  {
                    createUser ? t('createUser') : t("userData")
                  } 
                  </Typography>
                </div>
              ) : 
              ( <div className={s.backarrow}>
                  <IconButton onClick={() => navigate(-1)}  >
                    <ArrowBackIcon color={"primary"} sx={{
                      fontSize: '1.8rem',
                      cursor: "pointer",
                    }} />
                  </IconButton>
                  <Typography sx={{fontSize: "1.5rem", fontFamily: 'Roboto, sans-serif', fontWeight: 300, marginLeft: '10px'}} className={s.formTitle}>
                  {
                      createUser ? t('createUser') : t("userData")
                  }
                  </Typography>
                </div>
              )
            }
            </Box>
            <div className={s.result}>
              {selected ? (
                !edit ? (
                  <div className={s.card}>
                    <UserData userEdit={userEdit} edit={edit} primaryColor={primaryColor}
                      banUnban={handleBan} setUserEdit={setUserEdit} createUser={createUser}
                      errors={errors} setErrors={setErrors} sent={sent} setSent={setSent}
                    />
                    <Grid container className={s.cardSelectors}>
                      <Grid item lg={3.5} xs={11} className={s.cardBody}>
                        <Box sx={{ borderRadius: '10px', p:1, width: '100%'}} display="flex" justifyContent="center">
                          <Box sx={{margin: 1, textAlign: "center", width: '100%'}} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <UserRoles userEdit={userEdit} edit={edit} setUserEdit={setUserEdit} setEdit={setEdit}
                            chipDataRoles={chipDataRoles} setChipDataRoles={setChipDataRoles} users={users}
                            roles={roles} secondaryColor={secondaryColor} primaryColor={primaryColor} errors={errors} createUser={createUser}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item lg={5} xs={11} className={s.cardBody}>
                        <Box sx={{ borderRadius: '10px', p:1, width: '100%'}} display="flex" justifyContent="center">
                          <Box sx={{margin: 1, textAlign: "center", width: '100%'}} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <UserPoints  setEdit={setEdit} chipData={chipData} setChipData={setChipData} points={points}
                            setOpenModal={setOpenModal} openModal={openModal} primaryColor={primaryColor}
                            secondaryColor={secondaryColor} createUser={createUser} setUserPoints={setUserPoints} userPoints={userPoints} userEdit={userEdit}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item lg={3.5} xs={11} className={s.fileInput}>
                        <Box sx={{ borderRadius: '10px', p:1, width: '100%'}} display="flex" justifyContent="center">
                          <Box sx={{margin: 1, textAlign: "center", width: '100%'}} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <h4>{t('profilePicture')}</h4>
                            <img className={s.addImage} src={noImage} alt="Logo" />
                            <label htmlFor={"primaryLogo"} className={s.labelUpload}>
                              <input
                                  type="file"
                                  name="primaryLogo"
                                  id={"primaryLogo"}
                                  className={s.input1}
                                  disabled
                              />
                              <CloudUploadRoundedIcon sx={{ fontSize: 30, color: primaryColor }}></CloudUploadRoundedIcon>
                              <h5>{'Subir imagen'}</h5>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                      <div className={s.saveButton}>
                        {
                          createUser ? (
                            <>
                            <Button
                              variant="outlined"
                              sx={{ width: "50%", mt: 2, mb: 2 }}
                              className={s.button}
                              onClick={handleCreate}
                              disabled={!create}
                            >
                              {create ? t('createUser')  : t('PleaseCompleteTheFields')}
                            </Button>
                            </>
                          ) : (
                            <>
                            {
                            (!edit || selected.enabled !== userEdit.enabled) && (
                            <Button
                              variant="outlined"
                              sx={{ width: "50%", mt: 2, mb: 2 }}
                              className={s.button}
                              onClick={handleSave}
                              disabled={save}
                            >
                              {save ? "Sin cambios para guardar" : "Guardar cambios"}
                            </Button>)
                            }
                            </>
                          )
                        }
                      </div>
                  </div>

                ) : (
                  <DetailsUser userEdit={userEdit}/>
                )
              ) : (
                <div className={s.addUser}>
                  {
                    !loading ? !users?.length ? 
                      <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "300px"}}>
                        <span>{t('noResult')}</span>
                      </Grid> : <>
                    <Grid item sx={{width: "100%"}} className={s.tableContainer}>
                      <TableContainer className={s.table}>
                        <Table>
                          <TableHead className={s.tableHead}>
                            <TableRow color={primaryColor} style={{backgroundColor: primaryColor}} sx={{"&:hover": {backgroundColor: "secondary.light"}}}>
                              {createRow(t('state'), 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'})}
                              <Tooltip title={t('sort')} placement="top-start">
                              {createRow(`${t('name')} ${filters.name === 'firstname' ? filters.increasing ? '▼' : '▲': ''}`, 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif', cursor: 'pointer'}, () => {
                                filters.name === 'firstname' ? setFilters({...filters, increasing: !filters.increasing}) : setFilters({ name: 'firstname', increasing: true});
                              })}
                              </Tooltip>
                              <Tooltip title={t('sort')} placement="top-start">
                              {createRow(`${t('surname')} ${filters.name === 'lastname' ? filters.increasing ? '▼' : '▲': ''}`, 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif', cursor: 'pointer'}, () => {
                                filters.name === 'lastname' ? setFilters({...filters, increasing: !filters.increasing}) : setFilters({ name: 'lastname', increasing: true});
                              })}
                              </Tooltip>
                              {createRow(t('dni'), 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'})}
                              <Tooltip title={t('sort')} placement="top-start">
                              {createRow(`Email ${filters.name === 'email' ? filters.increasing ? '▼' : '▲': ''}`, 'left', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif', cursor: 'pointer'}, () => {
                                filters.name === 'email' ? setFilters({...filters, increasing: !filters.increasing}) : setFilters({ name: 'email', increasing: true});
                              })}
                              </Tooltip>
                              {createRow(t('edit'), 'center', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'})}
                              {createRow(t('delete'), 'center', false, {color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'})}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              users?.map((user, i) => {
                              return (
                                  <TableRow key={i} sx={{"&:hover": {backgroundColor: "secondary.light"}}}>
                                      {createRow(user.enabled ? t('active') : t('inactive'), "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow(user.firstname, "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow(user.lastname, "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow('-', "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow(user.email, "left" , i % 2 === 0, cellStyles, () => handleOnDetail(user))}
                                      {createRow(<EditIcon color={"primary"} sx={{fontSize: 30, cursor: "pointer", color: primaryColor}} onClick={() => handleOnSelect(user)} className={s.btn}/>, "center" , i % 2 === 0, cellStyles)}
                                      {createRow(<DeleteForeverIcon color={"primary"} sx={{fontSize: 30, cursor: "pointer", color: primaryColor}} onClick={() => handleDelete(user)} className={s.btn}/>, "center" , i % 2 === 0, cellStyles)}
                                  </TableRow>
                                  )
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    </> :
                    <Grid item xs={11} className={s.loader}>
                      <CircularProgress size={'4rem'}/>
                    </Grid>
                  }
                  <Grid item container xs={7} sx={{justifyContent: 'space-evenly', alignItems: 'center', mb: 5, mt: 5}}>
                    <Select disabled={!users?.length} value={perPage} onChange={(e) => handleChangePerPage(e.target.value)}>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>  
                    <Stack spacing={2}>
                      <Pagination
                      showFirstButton 
                      showLastButton
                      count={totalPages}
                      page={_PAGINATION.actualPage || 1}
                      onChange={handlePagination}
                      color={"secondary"}
                      disabled={!users?.length}
                      />
                    </Stack>
                    <form style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '120px'}} onSubmit={e => e.preventDefault()}>
                      <TextField disabled={!users?.length} type={'number'} value={customPage} onChange={(e) => setCustomPage(e.target.value)} sx={{width: '100%', mr: '5px'}}/>
                      <Button disabled={!users?.length} variant='contained' onClick={() => {
                        if( 0 <= customPage && customPage <= totalPages){
                          setPage(customPage * 1);
                        }else{
                          setCustomPage(page);
                        }
                      }}>Ir</Button>
                    </form>
                  </Grid>
                </div>
              )}
              </div>
          </>
          )
        }
          
        </HandleRequests>
      </div>
    );
};
