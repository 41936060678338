import React, { useState, useEffect } from 'react';
import s from './Users.module.css';

//Assets
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import CreateUser from './Create';
import Detail from './Details';

//Servicio
// import { deleteUser, searchUsersByOrganization, editUser, create_user } from "../../../api/users/UsersConfig";
import { searchUsersByOrganization, deleteUser } from "../../../api/users/UsersConfig";
import { getAllUsersFromAgencies } from '../../../api/agencies/AgenciesService';
import { getUsersByUUID } from '../../../api/users/UsersConfig';

//Material Ui
import {
   Grid,
   TableContainer,
   Table,
   TableHead,
   TableBody,
   TableRow,
   TableCell,
   CircularProgress,
   Select,
   MenuItem,
   Pagination,
   TextField,
   Button,
   Snackbar,
   Alert,
   Tooltip
} from '@mui/material';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import GroupIcon from '@mui/icons-material/Group';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FiltersModal from './Filters';

const cellStyles = {
   fontFamily: 'Roboto, sans-serif',
   fontSize: '0.9rem',
   fontWeight: '400',
   borderBottom: '1px solid rgba(0, 0, 0, 0.455)',
 }

const UsersRefactor = () => {
   const { t } = useTranslation();
   const { textColor } = useSelector(state => state.local);
   const { activeOrganization } = useSelector((state) => state.gamma);
   const currentUser = useSelector(state => state.gamma.logged_user);
   const my_agencies = useSelector((state) => state?.myAgencies?.user_agencies)
   const dispatch = useDispatch();

   //Estados
   const [loading, setLoading] = useState(false);
   const [page, setPage] = useState(1);
   const [pageSize, setPageSize] = useState(10);
   const [customPage, setCustomPage] = useState(1);
   const [totalPages, setTotalPages] = useState(1);
   const [filters, setFilters] = useState({
      name: 'lastname',
      increasing: true,
    });

   const [items, setItems] = useState([]);
   const [openError, setOpenError] = useState(false);
   const [open, setOpen] = useState(false);
   const [query, setQuery] = useState('');
   const [detail, setDetail] = useState({status: false, data: null})
   const [create, setCreate] = useState({
      status: false,
      editing: false,
      data: null
   })

   const getData = () => {
      if(currentUser.memberships[0].roles[0].name === "Administrador Loteria"){
         setLoading(true);
         searchUsersByOrganization(activeOrganization?.id, page, pageSize, dispatch, filters, query)
         .then(res => {
            setItems(res.content);
            setTotalPages(res.totalPages);
            setLoading(false);
         })
         .catch(e => {
            setItems([]);
            setLoading(false);
            setOpenError(true);
         })
      }else{
         setLoading(true);
         const ids = currentUser?.memberships[0].agencies;
         const subagenciesids = my_agencies?.map(a => a.childrenAgencies.map(s => s.id));
         getAllUsersFromAgencies([...ids, subagenciesids].flat(Infinity))
         .then(res => res.filter((user, index) => res.findIndex((u) => u.userUUID === user.userUUID) === index))
         .then(users => {
            Promise.all(users?.map(obj => getUsersByUUID(obj.userUUID)))
            .then(res => {
               if(query){
                  setItems(res.filter(item => item.id).filter(user => user.firstname.toLowerCase() === query.toLocaleLowerCase() || user.lastname.toLowerCase() === query.toLocaleLowerCase()))
               }else{
                  setItems(res.filter(item => item.id));
               }
               setLoading(false);
            })
         })
      }
   };

   const handleSort = (item) => {
      filters.name === item ? setFilters({...filters, increasing: !filters.increasing}) : setFilters({ name: item, increasing: true});
   }

   const handleDelete = (item) => {
      Swal.fire({
         title: `${t("areYouSure")} ${item?.email}?`,
         text: `${t("IfYouEliminate")} ${item?.firstname} ${item?.lastname} ${t("theChangeCannotBeReversed")}`,
         icon: "question",
         showCancelButton: true,
         confirmButtonColor: "green",
         cancelButtonColor: "#d33",
         cancelButtonText: t('cancel'),   
         confirmButtonText: t('accept'),
      })
      .then(result => {
         if(result.isConfirmed){
            deleteUser(item.id)
            .then(res => {
               if(res.status === 200){
                  Swal.fire(t("deleted"), t("UserDeletedSuccessfully"), "success");
                  getData();
               }else{
                  Swal.fire({icon: "error", title: t('ErrorOcurred')})
               };
            });
         };
      });
   };

   useEffect(() => {
      getData();
   }, [page, pageSize, filters, query]);

   return (
      <Grid container className={s.container}>
      {
         detail.status ? <Detail detail={detail} setDetail={setDetail}/> :
         create.status ? <CreateUser create={create} setCreate={setCreate} getData={getData}/> :
         <>
         <Grid item xs={12} className={s.header}>
            <Grid item container xs={12} className={s.titleContainer}>
               <Grid item xs={8} sx={{display: 'flex', justifyContent: "flex-start", alignItems: 'center'}}>
                  <GroupIcon sx={{fontSize: '2rem', color: 'primary.main', mr:2}}/>
                  <h4>{t('users')}</h4>
                  <Button 
                     variant='contained' 
                     sx={{ml: 1, height: '2.3rem', textTransform: 'capitalize', fontSize: '0.9rem', fontWeight: 300}}
                     onClick={() => setCreate({
                        status: true,
                        editing: false,
                        data: null,
                     })}
                  >
                     {t('add')}
                  </Button>
               </Grid>
               <Grid item xs={3} sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                  <Button variant='contained' className={s.filterButton} onClick={() => setOpen(true)}>
                     <TuneRoundedIcon sx={{ color: "secondary.light", fontSize: "1.8rem" }} />
                     <p>{t('filter')}</p>
                  </Button>
                  {
                     query.length ?
                     <Button variant='contained' sx={{ ml: 1 }} className={s.filterButton} onClick={() => setQuery('')}>
                        <p>{t('cleanFilters')}</p>
                     </Button> : null
                  }
               </Grid>
            </Grid>
         </Grid>

         {
            loading ? 
            <Grid item xs={11} className={s.loader}>
               <CircularProgress size={'4rem'}/>
            </Grid> :
            items.length ?
            <Grid item xs={11} className={s.data}>
               <TableContainer className={s.tableContainer}>
                  <Table>
                     <TableHead sx={{backgroundColor: 'primary.main'}}>
                        <TableCell sx={{color: textColor}} className={s.tableHeader}>{t('status')}</TableCell>
                        <Tooltip title={t('sort')} placement='top-start'><TableCell onClick={() => handleSort('firstname')} sx={{color: textColor, cursor: 'pointer'}} className={s.tableHeader}>{`${t('name')} ${filters.name === 'firstname' ? filters.increasing ? '▼' : '▲': ''}`}</TableCell></Tooltip>
                        <Tooltip title={t('sort')} placement='top-start'><TableCell onClick={() => handleSort('lastname')} sx={{color: textColor, cursor: 'pointer'}} className={s.tableHeader}>{`${t('surname')} ${filters.name === 'lastname' ? filters.increasing ? '▼' : '▲': ''}`}</TableCell></Tooltip>
                        <TableCell sx={{color: textColor}} className={s.tableHeader}>DNI/CUIT</TableCell>
                        <Tooltip title={t('sort')} placement='top-start'><TableCell onClick={() => handleSort('email')} sx={{color: textColor, cursor: 'pointer'}} className={s.tableHeader}>{`${t('mail')} ${filters.name === 'email' ? filters.increasing ? '▼' : '▲': ''}`}</TableCell></Tooltip>
                        <TableCell align='center'  sx={{color: textColor}} className={s.tableHeader}>{t('edit')}</TableCell>
                        <TableCell align='center'  sx={{color: textColor}} className={s.tableHeader}>{t('delete')}</TableCell>
                     </TableHead>
                     <TableBody>
                        {
                           items.map((item, i) => {
                              return(
                                 <TableRow key={i} sx={{'&:hover': {backgroundColor: 'secondary.light'}}} >
                                    <TableCell sx={{...cellStyles, backgroundColor: i%2 ? "#dbdbdb76" : "#dbdbdd12", cursor: 'pointer'}} onClick={() => setDetail({status: true, data: item})}>{item?.enabled ? t('active') : t('inactive')}</TableCell>
                                    <TableCell sx={{...cellStyles, backgroundColor: i%2 ? "#dbdbdb76" : "#dbdbdd12", cursor: 'pointer'}} onClick={() => setDetail({status: true, data: item})}>{item?.firstname}</TableCell>
                                    <TableCell sx={{...cellStyles, backgroundColor: i%2 ? "#dbdbdb76" : "#dbdbdd12", cursor: 'pointer'}} onClick={() => setDetail({status: true, data: item})}>{item?.lastname}</TableCell>
                                    <TableCell sx={{...cellStyles, backgroundColor: i%2 ? "#dbdbdb76" : "#dbdbdd12", cursor: 'pointer'}} onClick={() => setDetail({status: true, data: item})}>{item?.dni || item?.cuit || '-'}</TableCell>
                                    <TableCell sx={{...cellStyles, backgroundColor: i%2 ? "#dbdbdb76" : "#dbdbdd12", cursor: 'pointer'}} onClick={() => setDetail({status: true, data: item})}>{item?.email}</TableCell>
                                    <TableCell align='center' sx={{...cellStyles, backgroundColor: i%2 ? "#dbdbdb76" : "#dbdbdd12"}}><CreateIcon sx={{fontSize: '1.8rem', color: 'primary.main'}} className={s.iconbtn} onClick={() => setCreate({status: true, editing: true, data: item})}/></TableCell>
                                    <TableCell align='center'  sx={{...cellStyles, backgroundColor: i%2 ? "#dbdbdb76" : "#dbdbdd12"}}><DeleteForeverIcon sx={{fontSize: '1.8rem', color: 'primary.main'}} className={s.iconbtn} onClick={() => handleDelete(item)}/></TableCell>
                                 </TableRow>
                              )
                           })
                        }
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid> :
            <Grid item xs={11} className={s.loader}>
               {t('noResults')}
            </Grid>
         }
         <Grid item container xs={11} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 5}}>
            <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
               <Select disabled={!items?.length} value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
               </Select>  
            </Grid>
            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
               <Pagination
                  showFirstButton 
                  showLastButton
                  count={totalPages}
                  page={page}
                  onChange={(e, value) => setPage(value)}
                  color={"secondary"}
                  disabled={!items?.length}
                  />
            </Grid>
            <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
               <form style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '120px'}} onSubmit={e => e.preventDefault()}>
                  <TextField disabled={!items?.length} type={'number'} value={customPage} onChange={(e) => setCustomPage(e.target.value)} sx={{width: '100%', mr: '5px'}}/>
                  <Button disabled={!items?.length} variant='contained' onClick={() => {
                  if( 0 <= customPage && customPage <= totalPages){
                     setPage(customPage * 1);
                  }else{
                     setCustomPage(page);
                  }
                  }}>Ir</Button>
               </form>
            </Grid>
         </Grid>
         <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
            <Alert variant="filled" onClose={() => setOpenError(false)} severity="error" sx={{ width: '100%' }}>
               {t('tryInOtherMoment')}
            </Alert>
         </Snackbar>
         </>
      }
      { open ? <FiltersModal open={open} setOpen={setOpen} editQuery={setQuery}/> : null}
      </Grid>
   )
}

export default UsersRefactor;