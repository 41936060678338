export const getAgencieSettlements = async (
  agencie,
  dateFrom,
  dateTo,
  page
) => {
  try {
    const response = await fetch(
      `/gamma-movements/agencies/${agencie.id}/agencySettlements?pageNumber=${
        page || 1
      }&pageSize=20&orderCriteria=ASC&orderField=id&fromDate=${dateFrom}&toDate=${dateTo}&settlementPaymentStatus=CHARGE_PENDING`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getHomologatedGames = async () => {
  try {
    const response = await fetch(`/gamma-general/homologated/games`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

// TODO: Migrar estos servcios a su Service correspondiente
//       getOrganizationConfiguration pertenece a Ckecking Account 13000
export const getOrganizationConfiguration = async (organization) => {
  try {
    const response = await fetch(
      `/gamma-movements/organizations/${organization}/configurations`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

// TODO: Migrar estos servcios a su Service correspondiente
//       agencySettlement pertenece a Checking Account 13000
export const getSettlements = async (agencie, subagency) => {
  try {
    const response = await fetch(
      `/gamma-movements/settlements/agencies/${
        agencie?.id
      }/report?pageSize=10000000&movementSize=10000000${
        subagency && agencie?.id !== subagency?.id
          ? `&agencyIds=${subagency?.id}`
          : ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};
