export function subTotal(movements) {
  let money = 0;
  Array.isArray(movements) && movements.forEach((m) => {
      if (m.status === "PENDING") {
         if (m.accountingCode.kind !== "CREDIT") {
          money += m.amount;
         }
         else {
           money -= m.amount;
         }
      }
  })
  return money;
}
// function movementForGame(movements, gameId, imputations) {
//   console.log("gameId", gameId);
//   console.log("imputations", imputations);
//   const imp = imputations.filter(i => i.sourceId === gameId);
//   console.log("moveFor IMP", imp)
//   const gameMovement = imp.map(i => movements.filter(m => m.accountingCode.imputation.id === i.id))
//   console.log("moveFor gameMovement", gameMovement)
//   return gameMovement
// }
export function calculateSaldo(gameMovement, accountingCode) {
  const mov = {
    CreationDate: "",
    ImputationDate: "",
    cuenta: accountingCode.name,
    saldo: 0,
  }
  if (gameMovement?.length) {
    const cuentaMov = gameMovement.filter(m => m.accountingCode.accountingCodeKind.id === accountingCode.id)
    cuentaMov.forEach(m => {
      if (m.status === "PENDING") {
        mov.saldo =   mov.saldo + m.amount
      }
    })
  }
  return mov;
}



// export function movementRow(movements, gameId, imputations, accountingCode) {
//   const mov = movementForGame(movements, gameId, imputations);
//   return saldo(mov, accountingCode);
// }

export function totalToDeposit(movements) {
  let total = 0;
  Array.isArray(movements) && movements.forEach(m => {
    if (m.status === "PENDING") {
      if (m.accountingCode.kind === "CREDIT") {
        total += m.amount;
      }
    }
  })
  return total
}

export const Neto = (movements, imputations, agencieID) => {
  let money = 0;
  if (!Array.isArray(movements)){
    return money
  }

  let mov = movements.filter(m => m.agency.id === agencieID)
  Array.isArray(mov) && mov.forEach((m) => {
    const imputation = imputations.find(i => i.id === m?.accountingCode?.imputation?.id)
    if (imputation !== undefined) {
      if (m.status === "PENDING") {
        if (m.accountingCode.kind === "CREDIT") {
          money += m.amount;
        }
        // else if (m.accountingCode.kind === "DEBIT") {
        //   money -= m.amount;
        // }
      }
    }
  })
  return money;
}

export const PrizesPaid = (movements) => {
  let money = 0;
  Array.isArray(movements) && movements.forEach((m) => {
    if ([17, 10, 7, 8].includes(m?.accountingCode?.accountingCodeKind?.id)) {
      money += m.amount
    }
  })
  return money
}

export const MovementsByImputation = (movements, imputationId) => {
  let mov = movements?.filter((m) => m?.imputation?.id === imputationId)
  return mov.movements
}

export const MovementsRecaudación = (movements) => {
  let mov =  Array.isArray(movements) && movements.filter((m) => m?.accountingCode?.imputation?.id === 46)
  return mov
}

export const findImputation = (content) => {
  const imputation = []
  content.forEach(movements => {
    console.log("content", movements);
    const imputationIds = movements?.imputation?.sourceId !== null && obtenerValoresDiferentes('sourceId', movements?.imputation.sourceId) || []
    imputationIds.forEach(imp => {
      content.find(con => con?.imputation?.sourceId === imp).then(res => imputation.push(res))
    });
  });
  return imputation
}

export const groupByImputation = (movements) => {
  let m = movements.filter( mov => mov.imputationId !== null).reduce((groups, movement) => {
    groups[movement.imputationId] =  groups[movement.imputationId] || [];
    groups[movement.imputationId].push(movement);
    return groups;
  }, []);
  return m;
}

function obtenerValoresDiferentes(parametro, lista) {
  return lista.reduce((valores, objeto) => {
    valores.add(objeto[parametro]);
    return objeto;
  }, new Set());
}