export default function printTable(component) {
  const content = `
    <html>
      <head>
        <style>
          .print-table {
            border-collapse: collapse;
            font-family: 'Roboto', sans-serif;
            width: 100%;
            margin-bottom: 20px;
            margin-top: 20px;
          }

          .texto{
            font-family: 'Roboto', sans-serif !important;
          }

          .logo{
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .logo img{
            width: 200px;
          }

          .print-table td, .print-table th {
            border: 1px solid #00000077;
            font-size: 11px;
            text-align: left;
            font-weight: 400;
            font-family: 'Roboto', sans-serif;
          }

          .print-table th{
            text-align: center;
            background-color: #222222;
            color: #EEEEEE;
          }

          .print-table .oneline td{
            border: 1px dotted #000000;
          }

          .alinear-izquierda{
            text-align: left !important;
          }

          .alinear-derecha{
            text-align: right !important;
          }

          .alinear-centro{
            text-align: center !important;
          }

          .print-table .noprint{
            display: none;
          }
        </style>
      </head>
      <body>
        ${component?.innerHTML}
      </body>
    </html>
`;

//Creo iframe con la tabla a imprimir
  const print = window.document.createElement('iframe');
  print.style.display = 'none';
  document.body.appendChild(print);

  print.contentDocument.write(content);
  print.contentDocument.close();
  print.contentWindow.focus();
  print.contentWindow.print(); //Imprimo

  //Quito el iframe al terminar
  document.body.removeChild(print);
}