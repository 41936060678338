export const logOutPath = `/main/logout`;

const AGENCY_NUMBER_INDEX = 3;

export const AgencyPage = {
    ControlCaja: '/main/control-caja',
    Premios: '/main/premios',
    CuentaCorriente: '/main/cuenta-corriente'
}

export const getPresentAgencyInPath = (path, agencies) => {
    const agencieNumber = path.slice(AGENCY_NUMBER_INDEX);
    return firstIfExists(getFlatAgencyList(agencies).filter(agency => agencieNumber.includes(getAgencyNumberDisplay(agency))));
}

export const getAgencyNumberDisplay = (agency) => {
    return agency.number + (agency.subNumber != null ? "-" + agency.subNumber : "");
}

export const hasAgencyInPath = (path) => {
    return path.slice(AGENCY_NUMBER_INDEX).length > 0;
}

const firstIfExists = (list) => {
    return Array.isArray(list) && list.length > 0 ? list[0] : null;
}

const getFlatAgencyList = (agencies) => {
    return agencies.map((agency) => agency.agencyLevel === 'CONCENTRATOR' ? [agency, getFlatAgencyList(agency?.childrenAgencies || [])].flat() : agency).flat();
}