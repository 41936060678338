export const getTresury = async (agencie, dateFrom,dateTo,kind,page,perPage) => {
    try {
        const response = await fetch (`/gamma-movements/treasury/agencies/${agencie}/executedObligations?pageNumber=${page}&pageSize=${perPage}&orderCriteria=ASC&orderField=id&fromCreated=${dateFrom}&toCreated=${dateTo}`,{
            method:"GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        return response.json();
    } catch (e) {
        console.log(e);
    }
}