/* eslint-disable no-undef */
import styles from "./index.module.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useTranslation } from "react-i18next";
import {
  getMPDetallePagos,
  getMPTotalPagos,
} from "../../../api/mercadoPago/index";
import s from "./Resumen.module.css";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import { format, parse } from "date-fns";
import usePagination from "./Pagination";
import { Currency, currencyFormat } from "../../../helpers/currencyConvert";
import { responsiveFontSizes } from "@mui/material";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import ModalProviders from "./ModalProviders/index";
import printTable from "../../../helpers/imprimir";
import my_agencies, {
  setSelectedAgencieToConsult,
} from "../../../store/slices/my_agencies";
import { useLocation, useNavigate } from "react-router-dom";
import AgencySelectModal from "../../Shared/AgencySelectModal/AgencySelectModal";
import {
  formatJava,
  formatJavaCompleto,
  formatJavaTiempo,
  timeFormat,
  formatArg,
} from "../../../config/strings";
import { configDate } from "../../../helpers/convertDate";
import {
  AgencyPage,
  getPresentAgencyInPath,
  logOutPath,
  hasAgencyInPath,
  getAgencyNumberDisplay,
} from "../../../helpers/path";
import { unique } from "../../../helpers/arrays";
import AgencieSelected from "../Concentrators/AgencieSelected/AgencieSelected";
import { Response } from "sync-fetch";
import { BorderAll } from "@mui/icons-material";
import ModalApuestas from "./Modales/ModalApuestas";
import ModalJugadas from "./Modales/ModalDetalles";
import { updateLocation } from "../../../store/slices/localSlice";

export const MercadoPago = () => {
  const [isReady, setIsReady] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [dateFrom, setDateFrom] = useState(diaHoy());
  const [dateTo, setDateTo] = useState(diaHoy());
  const { imputations, organization_Configuration } = useSelector(
    (state) => state.gamma
  );
  const [openDetails, setOpenDetails] = useState(false);
  const [openPlay, setOpenPlay] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { activeOrganization } = useSelector((state) => state.gamma);
  const [movimientos, setMovimientos] = useState({});
  const [respuesta, setRespuesta] = useState([]);
  const [listadoFiltrado, setListadoFiltrado] = useState([]);
  const [rangoDias, setRangoDias] = useState();
  const [openLista, setOpenLista] = useState(false);
  const [detalles, setDetalles] = useState();
  const [detallesPDV, setDetallesPDV] = useState([]);
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  const { textColor } = useSelector((state) => state.local);
  const [search, setSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [print, setPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult
  );
  const [filterParams, setFilterParams] = useState({
    dateFrom: diaAyer(),
    dateTo: diaHoy(),
    game: "",
    concept: "",
  });
  const location = useLocation();
  const actualPage = useSelector(
    (state) => state.pagination.currentAccountActualPage
  );
  const [showModal, setShowModal] = useState();

  useEffect(() => {
    dispatch(updateLocation({ mercadoPago: true }));
  }, []);
  const page = usePagination(
    actualPage,
    imputations?.totalPages,
    agencieSelected?.id
  );

  const { formatCurrency } = currencyFormat();

  const defaultMovimientos = {
    headers: [],
    movements: [],
  };

  const handlePlay = (detalles, fecha, ag, subAg) => {
    listarDetallePorPDV(detalles, fecha, ag, subAg);
    setOpenPlay(!openPlay);
  };

  console.log("AGENCIAS, ", agencies);

  const bodyMP = {
    mapAgenciasSubAgencias:
      agencieSelected.childrenAgencies.length !== 0
        ? agencieSelected.childrenAgencies.map((subAgencia) => {
            return `${agencieSelected.number}-${subAgencia.subNumber}`;
          })
        : `${agencieSelected.number}-${agencieSelected.subNumber}`,
    agencias: agencieSelected.number,
    subagencias: agencies.map((agencia) => {
      return agencia.childrenAgencies.map(
        (child) => `${agencia.number}: ${child.subNumber}`
      );
    }),
    maquinas:
      agencieSelected.childrenAgencies.length !== 0
        ? agencieSelected.childrenAgencies.map((agencia) => {
            return `${agencieSelected.number}-${agencia.subNumber}-1`;
          })
        : `${agencieSelected.number}-${agencieSelected.subNumber}-1`,
    fecha_desde:
      dateFrom !== undefined
        ? `${JSON.stringify(dateFrom).substring(0, 11).split('"')[1]}`
        : `${format(new Date(), formatJavaCompleto)}`,
    fecha_hasta:
      dateTo !== undefined
        ? `${JSON.stringify(dateTo).substring(0, 11).split('"')[1]}`
        : `${format(new Date(), formatJavaCompleto)}`,
    rango_dias: rangoDias ? rangoDias : 0,
  };

  function convertirFecha(fechaString) {
    let partes = fechaString.split(" ");
    let fechaPartes = partes[0].split("/");
    return new Date(
      parseInt(fechaPartes[2]), // Año
      parseInt(fechaPartes[1]) - 1, // Mes (restamos 1 porque los meses van de 0 a 11)
      parseInt(fechaPartes[0]) // Día
    );
  }

  function diaAyer() {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    return format(d, formatArg);
  }

  function diaHoy() {
    return format(new Date(), formatJavaCompleto);
  }

  const handleClick = (index) => {
    index === currentIndex ? setOpenLista(!openLista) : setCurrentIndex(index);
  };

  console.log("Respuesta MP: ", respuesta);
  console.log("Detalle MP: ", detalles);

  const filtrarListadoMP = (respuesta) => {
    const listado = respuesta?.filter(
      (item) => item?.cobros != 0 || item?.devoluciones != 0
    );
    console.log("LISTADO: ", listado);
    console.log(
      "LISTADO ORDENADO: ",
      listado?.sort((a, b) => {
        if (a.agencia !== b.agencia) {
          return a.agencia - b.agencia;
        }
        if (a.subagencia !== b.subagencia) {
          return a.subagencia - b.subagencia;
        }
        let fechaA = convertirFecha(a.fecha);
        let fechaB = convertirFecha(b.fecha);
        return fechaA - fechaB;
      })
    );
    setListadoFiltrado((prev) =>
      listado?.sort((a, b) => {
        let fechaA = convertirFecha(a.fecha);
        let fechaB = convertirFecha(b.fecha);
        return fechaA - fechaB;
      })
    );
  };

  const listarDetallePorPDV = (respuesta, fecha, agencia, subagencia) => {
    const listado = respuesta?.filter(
      (item) =>
        item.fecha_pago.includes(fecha) &&
        item.caja.agencia == agencia &&
        item.caja.subagencia == subagencia
    );
    setDetallesPDV((prev) => listado);
  };

  
  useEffect(() => {
    const traerDatosMP = async () => {
      try {
        setLoading(true);
        const response = await getMPTotalPagos(
          process.env.REACT_APP_API_CODIGO_ORGANIZACION_MP,
          bodyMP.mapAgenciasSubAgencias.toString(),
          bodyMP.rango_dias,
          bodyMP.fecha_desde,
          bodyMP.fecha_hasta
        );
        setRespuesta(response);
        filtrarListadoMP(response);
        setLoading(false);
      } catch (e) {
        setDetalles("Hubo un error.");
        console.log("Error en index mercado pago: ", e);
      }
    };
    traerDatosMP();
  }, [agencieSelected, bodyMP.rango_dias]);

  useEffect(() => {
    const traerDetallesMP = async () => {
      try {
        setLoading(true);
        const response = await getMPDetallePagos(
          process.env.REACT_APP_API_CODIGO_ORGANIZACION_MP,
          bodyMP.mapAgenciasSubAgencias.toString(),
          bodyMP.rango_dias,
          bodyMP.fecha_desde,
          bodyMP.fecha_hasta
        );
        setDetalles(response);
        setLoading(false);
      } catch (e) {
        console.log("Error en index mercado pago: ", e);
      }
    };
    traerDetallesMP();
  }, [agencieSelected, bodyMP.rango_dias]);

  function showHide() {
    setShowModal(!showModal);
  }

  const handleConsulting = (agencie) => {
    if (typeof agencie === "object" && Object.keys(agencie).length !== 0) {
      dispatch(setSelectedAgencieToConsult(agencie));
    }
    setLoading(true);
    loadMovimientos(agencie, filterParams.dateTo, filterParams.dateFrom).then(
      (mov) => {
        console.log("MOV");
        console.log(mov);
        setMovimientos(mov);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (print === true) {
      printTable(document.getElementById("table-print"));
    }
    setPrint(false);
  }, [print]);

  console.log("movimientos");
  console.log(movimientos);

  return !isReady ? (
    <Grid container className={styles.container}>
      <Grid item container xs={11} className={styles.header}>
        <Grid item lg={6} md={6} xs={12} className={styles.titleContainer}>
          <AttachMoneyIcon sx={{ fontSize: "1.8rem", color: "primary.main" }} />
          <h4>{t("dailyTransactionsDetail")}</h4>
        </Grid>

        <Grid item xs={12} md={5} lg={5} className={styles.filters}>
          <Button
            variant="contained"
            className={styles.filterButton}
            onClick={() => setOpenFilter(true)}
          >
            <TuneRoundedIcon
              sx={{ color: "secondary.light", fontSize: "1.8rem" }}
            />
            <p style={{ fontSize: "0.9rem" }}>{t("filter")}</p>
          </Button>
          {searching ? (
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              className={styles.filterButton}
              onClick={() => {
                setFilterParams({
                  dateFrom: diaAyer(),
                  dateTo: diaHoy(),
                  concept: "",
                });
                setDateFrom(diaAyer());
                setDateTo(diaHoy());
                setSearching(false);
              }}
            >
              <p style={{ fontSize: "0.9rem" }}>{t("cleanFilters")}</p>
            </Button>
          ) : null}
          <Button
            sx={{ ml: 1 }}
            onClick={showHide}
            color="primary"
            className={styles.filterButton}
            variant="contained"
          >
            <p style={{ fontSize: "0.7rem" }}>{t("selectAgency")}</p>
          </Button>
        </Grid>

        <ModalProviders
          open={openFilter}
          setOpen={setOpenFilter}
          setFilters={setFilterParams}
          filters={filterParams}
          setSearching={setSearching}
          setSearch={setSearch}
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setRangoDias={setRangoDias}
          rangoDias={rangoDias}
          movimientos={movimientos}
        />
      </Grid>

      <Grid className={styles.selectAgency} item xs={11}>
        <p style={{ fontSize: "1.3rem", fontWeight: "300" }}>
          {agencieSelected?.name
            ? `${t("salePoint")}: (${agencieSelected.number}${
                agencieSelected.subNumber !== null
                  ? " - " + agencieSelected.subNumber
                  : ""
              }) ${agencieSelected.name}`
            : t("agencyNotSelected")}
        </p>
        {showModal && (
          <AgencySelectModal
            open={showModal}
            setOpen={setShowModal}
            onSelect={handleConsulting}
          />
        )}
      </Grid>

      {loading ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size="3rem" />
        </Grid>
      ) : (
        <>
          <Grid className={s.filtersContainer} xs={12} container item></Grid>
          <Grid className={s.filtersContainer} xs={12} container>
            <Table stickyHeader>
              <TableHead sx={{ bgcolor: "primary.main" }}>
                <TableRow color="primary.main" className={s.tableRow}>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    }}
                    className={s.tableCell}
                  >
                    Fecha
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    }}
                    className={s.tableCell}
                  >
                    Cobros
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    }}
                    className={s.tableCell}
                  >
                    Devoluciones
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    }}
                    className={s.tableCell}
                  >
                    Neto
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    }}
                    className={s.tableCell}
                  >
                    PDV
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {respuesta?.length == 0 || listadoFiltrado.length == 0 ? (
                  <TableRow className={s.tableRow}>
                    <TableCell colSpan={6}>
                      <p>{t("noInfo")}</p>
                    </TableCell>
                  </TableRow>
                ) : (
                  listadoFiltrado.map((item, i) => (
                    //console.log("item", item),
                    <TableRow key={i}>
                      <TableCell
                        align="left"
                        sx={
                          {
                            //backgroundColor: data.color,
                          }
                        }
                      >
                        {item.fecha || "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={
                          {
                            //backgroundColor: data.color,
                          }
                        }
                      >
                        {`${
                          item.cobros === 0
                            ? "$ -"
                            : formatCurrency(item.cobros)
                        }`}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={
                          {
                            //backgroundColor: data.color,
                          }
                        }
                        style={{ color: "red" }}
                      >
                        {`${
                          item.devoluciones === 0
                            ? "$ -"
                            : formatCurrency(item.devoluciones)
                        }`}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={
                          {
                            //backgroundColor: data.color,
                          }
                        }
                      >
                        {`${
                          item.devoluciones === 0 && item.cobros === 0
                            ? "$ -"
                            : item.devoluciones === 0
                            ? formatCurrency(item.cobros)
                            : item.cobros === 0
                            ? formatCurrency(item.devoluciones)
                            : formatCurrency(
                                Number(item.cobros) - Number(item.devoluciones)
                              )
                        }`}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={
                          {
                            //backgroundColor: data.color,
                          }
                        }
                      >
                        {`${item.agencia} - ${item.subagencia}`}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title={t("playDetails")} placement="left">
                          <BarChartRoundedIcon
                            sx={{
                              color: "rgb(91, 150, 233)",
                            }}
                            className={s.icon}
                            onClick={() => {
                              handlePlay(
                                detalles,
                                item.fecha,
                                item.agencia,
                                item.subagencia
                              );
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Grid>
        </>
      )}
      {openPlay ? (
        <ModalJugadas
          openPlay={openPlay}
          setOpenPlay={setOpenPlay}
          handlePlay={handlePlay}
          data={detallesPDV}
        />
      ) : null}
    </Grid>
  ) : (
    <Grid container className={styles.container}>
      <Grid item container xs={11} className={styles.header}>
        <Grid item lg={6} md={6} xs={12} className={styles.titleContainer}>
          <h4>Próximamente.</h4>
        </Grid>
      </Grid>
    </Grid>
  );
};
