import { setConcentratorSelectedError, setAgencieSelectedError, setMy_AgenciesError } from "../../store/slices/errors"

const api_conf_base_url = process.env.REACT_APP_API_CONF_URL;

export const getAgencieById = async (payload, dispatch) => {
    try{
        const response = await fetch(`/gamma-agencies/agencies/${payload}`)
        if(response.status === 200){
            dispatch(setAgencieSelectedError({error: false, code: response.status}))
            dispatch(setConcentratorSelectedError({error: false, code: response.status}))
            console.log("todo ok")
            return await response.json()
        }else{
            console.log("todo mal")
            throw new Error(response.status)

        }
    }catch(e){
        console.log(e)
        dispatch(setMy_AgenciesError({error: true, code: e.status || e.message}))
        dispatch(setAgencieSelectedError({error: true, code: e.status || e.message}))
        dispatch(setConcentratorSelectedError({error: true, code: e.status || e.message})) 
    }
}

export const getAgencieByIdAwait = async (payload) => {
    try{
        return await fetch(`/gamma-agencies/agencies/${payload}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", "accept": "application/json" }
        })
    }catch(e){
        console.log(e)
    }
}

export const editAgencieData = async (payload) => {
    try{
        return await fetch(`/gamma-agencies/agencies`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json", "accept": "application/json" }
        })
    }catch(e){
        console.log(e)
    }
}


export const addRelationship = async (agencieId, payload) => {
    try{
        return  await fetch(`/gamma-agencies/agencies/${agencieId}/relationships`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json", "accept": "application/json" }
        })
    }catch(e){
        console.log(e)
    }
}

export const getAgencieConcentrator = async (organizationId ) => {
    try{
        const response = await fetch(`/gamma-agencies/organizations/${organizationId}/agencies?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name&agencyKind=CONCENTRATOR`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"} 
        })
        return await response.json()
    }catch(e){
        console.log(e)
    }
}

export const getRelationsShips = async (agencieId) => {
    try{
        const response = await fetch(`/gamma-agencies/agencies/${agencieId}/relationships?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        return response.json();
    }catch(e){
        console.log(e)
    }
}

export const deleteRelationShip = async (agencieId, relationshipId) => {
    try{
        return await fetch(`/gamma-agencies/agencies/${agencieId}/relationships/${relationshipId}`, {
            method: "DELETE",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
    }catch(e){
        console.log(e)
    }
}

export const agencieSearch = async (organizationId, payload, agencyKind, page, status, sort) => {
    try{
        const response = await fetch(`/gamma-agencies/organizations/${organizationId}/agencies?${page ? `pageNumber=${page}&`: ''}pageSize=10&orderCriteria=${sort?.criteria ? sort?.criteria : 'ASC'}${status ? `&status=${status}`: ''}&orderField=${sort?.field ? sort?.field : 'name'}&agencyKind=${agencyKind || "CONCENTRATOR"}${ payload ? `&query=${payload}` : ''}`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"} 
        })
        return response.json()
    }catch(e){
        console.log(e)
    }

}

export const agencieSearchByNumber = (organizationId, query) => {
    return fetch(`/gamma-agencies/organizations/${organizationId}/agencies/findByNumberAndSubNumber?number=${query.number}${query.subnumber ? `&subNumber=${query.subnumber}` : ''}`,{
        method: "GET",
        headers: {"Content-Type": "application/json", "accept": "application/json"}
    })
      .then(res => {
          if(res.status === 200){
              return res.json();
          }else{
              throw res
          }
      })
      .catch(e => {throw e})
}

export const getFavsAgencies = async (userId, orgId) => {
    try{
        const response = await fetch(`/api-configuration/settings/bookmarks/${userId}?organizationId=${orgId}`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"} 
        })
        console.log(response)
        return await response.json()
    }catch(e){
        console.log(e)
    }
}

export const sendFavsAgencies = async (userId, orgId, payload) => {
    const jsonToSend = {
        "userUUID": userId,
        "content": payload.map(agencie => agencie?.id),
        "organizationId": orgId 
    }
    try{
        return await fetch(`/api-configuration/settings/bookmarks`, {
            method: "POST",
            body: JSON.stringify(jsonToSend),
            headers: {"Content-Type": "application/json", "accept": "application/json"} 
        })
    }catch(e){
        console.log(e)
    }
}

export const sendRelationedAgencies = async (userId, orgId, payload) => {
    const intPayload = payload.map(str => parseInt(str));
    console.log("add Pto Vta. payload", intPayload)
    const jsonToSend = {
        "userUUID": userId,
        "content": intPayload,
        "organizationId": orgId 
    }
    try{
        return await fetch(`/api-configuration/settings/users/agencies`, {
            method: "POST",
            body: JSON.stringify(jsonToSend),
            headers: {"Content-Type": "application/json", "accept": "application/json"} 
        })
    }catch(e){
        console.log(e)
    }
}

export const getAgenciesRelationed = async (userUuid, orgId) => {
    try{
        const response = await fetch(`/api-configuration/settings/users/agencies/${userUuid}?organizationId=${orgId}`)
        return await response.json()
    }catch(e){
        console.log(e)
    }
}

export const getAllUsersFromAgencies = async (payload) => {
    try {
        const response = await fetch(`/api-configuration/settings/users/agencies/findByAgency?pageNumber=1&pageSize=999&orderCriteria=ASC&userUUID=userUUID`,{
            method: "POST",
            body: JSON.stringify(payload),
            headers: {"Content-Type": "application/json", "accept": "application/json"} 
        })
        const json =  await response.json()
        return json.content 
        
    } catch (e) {
        console.log(e)
    }
}

export const getSubAgenciesByAgencie = async (organizationId, agencyId, page, subnumber) => {
    try {
        const response = await fetch(`/gamma-agencies/organizations/${organizationId}/agencies/findByNumberAndSubNumber?number=${agencyId}&subNumber=${subnumber}`,{
            method: "GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"} 
        })
        // console.log("respuesta del llamado sub AGENCIAS", response)
        const json =  await response.json()
        return json 
        
    } catch (err) {
        console.log(err)
    }
}
