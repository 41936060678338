import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { ColorPalette } from "./Themes"
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloakRoutes } from "./components/Navigation/routes/keycloakRoutes";
// import { standardRoutes } from "./components/Navigation/routes/standardRoutes"
import { createTheme } from '@mui/material/styles';
import { GetLanguage} from './helpers/getLanguage'


function App() {
  GetLanguage()
  const router = createBrowserRouter(
    keycloakRoutes
    // standardRoutes
  );

  const loteryColors = createTheme(ColorPalette());

  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <ThemeProvider theme={loteryColors}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
