// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewSearchBar_searchbar__mFvtj{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.NewSearchBar_searchbar__mFvtj .NewSearchBar_textfield__o0du5{\n    height: 100%;\n    border: 0;\n}\n\n.NewSearchBar_searchbar__mFvtj .NewSearchBar_button__C\\+KbH{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 15px;\n    font-size: 12px;\n    width: 25%;\n    height: 40px;\n    border-radius: 10px;\n\n}", "",{"version":3,"sources":["webpack://./src/components/Shared/SearchBar/NewSearchBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,UAAU;IACV,YAAY;IACZ,mBAAmB;;AAEvB","sourcesContent":[".searchbar{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.searchbar .textfield{\n    height: 100%;\n    border: 0;\n}\n\n.searchbar .button{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 15px;\n    font-size: 12px;\n    width: 25%;\n    height: 40px;\n    border-radius: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchbar": "NewSearchBar_searchbar__mFvtj",
	"textfield": "NewSearchBar_textfield__o0du5",
	"button": "NewSearchBar_button__C+KbH"
};
export default ___CSS_LOADER_EXPORT___;
