import {  FormControl, Select, MenuItem, TableCell, TextField} from "@mui/material";
import styles from "./functionsStyles.module.css";

// TODO: transformar estas funciones javascript en componentes react y pasarle los parametros como props

let key = 0;

export const createRow = (content, align, bool, personalStyles, fn, columspan) => {
    key += 1
    return (
      <TableCell  key={key} align={align || "left"} sx={{height: "30px", fontSize: ".9rem", backgroundColor: bool ?  "#dbdbdb76" : "#dbdbdd12", ...personalStyles}} colSpan={columspan || 1} onClick={fn}>
            {content}
      </TableCell>
    );
};

export const createCtaCteRow = (content, bool, personalStyles, highlightedNumber, claseImpreso) => {
  return (
    <TableCell className={claseImpreso} sx={{height: "30px", fontSize: ".9rem", backgroundColor: bool ?  "#dbdbdb76" : "#dbdbdd12", color: highlightedNumber && 'green', ...personalStyles}}>
          {content}
    </TableCell>
  );
};
  
export const createCtaCteRowMoney = (content, bool, personalStyles, highlightedNumber, claseImpreso) => {
  return (
    <TableCell className={claseImpreso} sx={{height: "30px", fontSize: ".9rem", backgroundColor: bool ?  "#dbdbdb76" : "#dbdbdd12", color: highlightedNumber && 'green', ...personalStyles}} align="right">
          {content}
    </TableCell>
  );
};
export const createEditRow = (content, string, bool, fn, value, dis) => {
  return (
    <TableCell sx={{width: "30%"}} style={{backgroundColor: bool ?  "#dbdbdb76" : "#dbdbdd12"}} name={string}>  
          <TextField
            disabled={dis}
            sx={{width: "100%"}}
            id="outlined-multiline-static"
            name={string}
            required
            rows={1}
            value={value[string]}
            onChange={(e) => fn(e)}
          />
      </TableCell>
  );
};

export const createRowSelect = (string, selection, arr, setSelection, bool, num, disabled) => {
  return <TableCell align={"left"} sx={{width: "25%", backgroundColor: bool ?  "#dbdbdb76" : "#dbdbdd12"}} name={string} key={num}>
    <FormControl sx={{width: "100%"}}>
    <Select
      disabled={disabled}
      labelId={"demo-simple-select-label"}
      id={"demo-simple-select"}
      value={selection}
      sx={{color: "black"}}
      onChange={(e) => setSelection(e.target.value)}
    >
    {
      arr.map((r, i) => <MenuItem key={i} value={r}>{r}</MenuItem>)
    }
    </Select>
    </FormControl>
  </TableCell>
}

export const formatResultConcentrators = (item) => {
  return (
      <div className={styles.msg_container}>
        <span className={styles.name}>{item.name}</span>
        <span className={styles.agencie}>{item.number}-{item.subNumber || "0"}</span>
      </div>
  );
};

export const formatResultUsers = (item) => {
  return (
    <div className={styles.msg_container}>
      <span className={styles.name}>
        {item.firstname} {item.lastname}
      </span>
      <span className={styles.email}>{item.email}</span>
    </div>
  );
};

export const sortArrayFirstOwners = (x, y) => {
  if(x.relationshipKind === "OWNER" && y.relationshipKind !== "OWNER"){
      return -1
  }
  if(y.relationshipKind === "OWNER" && x.relationshipKind !== "OWNER"){
      return 1;
  }
  return 0
}

export const createPrizeRow = (data) => (
  <TableCell sx={{backgroundColor: data.color}}>
    {data.content}
  </TableCell>
)

export const createPrizeRowMoney = (data) => (
  <TableCell sx={{backgroundColor: data.color}} align="right">
    {data.content}
  </TableCell>
)

export const createPrizeRowCenter = (data) => (
  <TableCell sx={{backgroundColor: data.color}} align="center">
    {data.content}
  </TableCell>
)