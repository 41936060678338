import { createSlice } from "@reduxjs/toolkit";
import { getAllUsersFromAgencies } from "../../api/agencies/AgenciesService.jsx";
import { getUsersByUUID } from  "../../api/users/UsersConfig";

const initialState = {
  logged_user: {},
  users: {},
  users_login: [],
  agencies: {},
  allOrganizations: [],
  gameOffers: [],
  organizationAgencies: {},
  organizationAgenciesSearch: {},
  concentratorSelectedRedux: [],
  roles: {},
  salePoints: {},
  movements:{},
  imputations:[],
  activeOrganization: '',
  accountingCodeKinds: {},
  appConfig: [],
  agencySettlement : [],
  homologated_Games: [],
  organization_Configuration : {}
};

const gammaSlice = createSlice({
  name: "apiCalls",
  initialState,
  reducers: {
    homologatedGames(state,action){
      state.homologated_Games = action.payload;
    },
    loggedUser(state, action){
      state.logged_user = action.payload;
    },
    setUserLogin(state, action){
      state.users_login = action.payload;
      state.users = action.payload;
    },
    usersUpdate(state, action) {
      state.users = action.payload;
    },
    agenciesUpdate(state, action) {
      state.agencies = action.payload;
    },
    gameOffersUpdate(state, action) {     
      state.gameOffers = action.payload;
    },
    organizationAgenciesUpdate(state, action) {
      state.organizationAgencies = action.payload;
    },
    organizationsAgenciesToSearchBar(state, action) {
      state.organizationAgenciesSearch = action.payload
    },
    organizationAgenciesUpdateAfterEdit(state, action){
      state.organizationAgencies = {...state.organizationAgencies, content: [...action.payload]}
    },
    agencySettlementUpdate(state,action){
      state.agencySettlement = action.payload;
    },
    agencySettlementUpdateConcentrator(state,action){
      state.agencySettlement = [...state.agencySettlement, ...action.payload]
    },
    setConcentratorSelectedRedux(state, action){
      state.concentratorSelectedRedux = action.payload
    },
    allRolesUpdate(state, action) {
      state.roles = action.payload;
    },
    allSalePointsUpdate(state, action) {
      state.salePoints = action.payload;
    },
    allOrganizations(state, action) {
      state.allOrganizations = action.payload;
    },
    setActiveOrganization(state, action) {
      state.activeOrganization = action.payload;
    },
    movementsUpdate(state, action){
      state.movements = {...state.movements, movements: [...action.payload]}
    },
    agencySettlement(state, action){
      state.movements = {...state.movements, header: [...action.payload]};
    },
    accountingCodeKindsUpdate(state,action){
      state.accountingCodeKinds = action.payload
    },
    appConfigLoader(state, action){
      state.appConfig = action.payload
    },
    resetGammaSlice(state, action){
      state.logged_user =  {}
      state.users =  {}
      state.users_login = []
      state.agencies =  {}
      state.allOrganizations =  []
      state.gameOffers =  []
      state.organizationAgencies =  {}
      state.organizationAgenciesSearch =  {}
      state.concentratorSelectedRedux =  []
      state.roles =  {}
      state.salePoints =  {}
      state.movements = []
      state.agencySettlement= []
      state.imputations = []
      state.activeOrganization =  ''
      state.accountingCodeKinds =  {}
      state.myAgencies.selected_agencie_to_consult = {}
      state.errors.concentratorsError = {}
      state.errors.concentratorSelectedError = {}
      state.errors.agencieSelectedError = {}
      state.errors.termsAndConditionError = {}
      state.errors.my_agenciesError = {}    
    },
    resetLoggedUser(state,action){
      state.logged_user =  {}
    },
    organizationConfiguration (state,action){
      state.organization_Configuration = action.payload;
    }
  }
});



export const allUsersOfTheLoginInUser = (users) => (dispatch) => {
  try {
    Promise.all(users?.map(obj => getUsersByUUID(obj.userUUID)))
    .then(res => dispatch(setUserLogin(res.filter(item => item.id))))
  }catch (e) {
    console.log(e)
  }
}

export const {
  loggedUser,
  usersUpdate,
  setUserLogin,
  agenciesUpdate,
  resetLoggedUser,
  gameOffersUpdate,
  organizationAgenciesUpdate,
  organizationsAgenciesToSearchBar,
  organizationAgenciesUpdateAfterEdit,
  setConcentratorSelectedRedux,
  allRolesUpdate, 
  allSalePointsUpdate,
  allOrganizations,
  setActiveOrganization,
  movementsUpdate,
  movementsUpdateAgencie,
  imputationUpdate,
  accountingCodeKindsUpdate,
  agencySettlement,
  resetGammaSlice,
  appConfigLoader,
  homologatedGames,
  organizationConfiguration
} = gammaSlice.actions;

export default gammaSlice.reducer;
