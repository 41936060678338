import React, { useState } from 'react'
import s from './Filters.module.css';

//assets
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//materialUi
import {
    Grid,
    Modal,
    TextField,
    Button,
    MenuItem
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import Select, { SelectChangeEvent } from '@mui/material/Select';

const FiltersModal = ({open, setOpen, filter, setFilter,setSearching}) => {

    const { t } = useTranslation();


    return (
        <Modal open={open} onClose={() => setOpen(false)}>
        <Grid container>
            <Grid xs={12} md={8} lg={4.5} item container className={s.boxModal} sx={{ bgcolor: "#EEEEEE" }}>
               <Grid container item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                  <Grid item xs={11} className={s.exit}>
                     <div className={s.titleModal}>
                           <p>{t('filter')}</p>
                           <span>{t('filterConfig')}</span>
                     </div>
                     <CloseIcon onClick={() => setOpen(false)} className={s.exitIcon} sx={{ color: "primary.dark" }} />
                  </Grid>

                  <Grid item xs={10} sx={{my: 2}}>
                     <label  className={s.state}>
                        {t('state')}
                     </label>
                     <Select
                        label={t('findByName')}
                        value={filter.status}
                        onChange={(e) => {setFilter({...filter, status: e.target.value })
                        setSearching(true)}}
                        sx={{width: '100%'}}
                     >
                        <MenuItem value={'ALL'} >Todos</MenuItem>
                        <MenuItem value={'ENABLED'}>{t('enabled')}</MenuItem>
                        <MenuItem value={'DISABLED'}>{t('disabled')}</MenuItem>
                     </Select>
                     <label  className={s.label}>
                     {t('type')}
                     </label>
                     <Select
                        label={t('findByName')}
                        value={filter.type}
                        onChange={(e) => {setFilter({...filter, type: e.target.value })
                         setSearching(true)}}
                        sx={{mt: 2 , width: '100%'}}
                     >
                        <MenuItem value={'ALL'} >{t('all')}</MenuItem>
                        <MenuItem value={'CONCENTRATOR'}>{t('concentradora')}</MenuItem>
                        <MenuItem value={'SALE_POINT'}>{t('agency')}</MenuItem>
                     </Select>
                  </Grid>

                  <Grid item xs={10} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3}} onClick={() => {
                     setOpen(false);
                  }}>
                  </Grid>
               </Grid>
            </Grid>
        </Grid>
    </Modal>
    )
};

export default FiltersModal;