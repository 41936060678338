import React, { useState } from 'react'
import s from './AgencyModal.module.css';

//assets
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect } from "react";

import { getSubAgenciesByAgencie } from "../../../api/agencies/AgenciesService"

import { searchpointsInArray } from "../SearchBar/searchBarArray"

//materialUi
import {
    CircularProgress,
    TextField,
    Button,
    Grid,
    Modal,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Pagination
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";


const cellStyles = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    borderBottom: '1px solid rgba(0, 0, 0, 0.455)',
    cursor: "pointer"
}


const AgencyModal = ({open, setOpen, onSelect}) => {

    const { t } = useTranslation();
    const { textColor } = useSelector(state => state.local);
    const { activeOrganization } = useSelector(state => state.gamma)
    const { user_agencies } = useSelector (state => state.myAgencies)
    
    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searching, setSearching] = useState(false)
    const [inputName, setInputName] = useState("")
    const [inputNumber, setInputNumber] = useState("")

    const handlePaginate = (e, v) => {
		setPage(v)       
	}

    async function respuestas() {
        user_agencies.forEach(ag => {
            if (ag.agencyLevel === 'CONCENTRATOR' ) {
                getSubAgenciesByAgencie(activeOrganization?.id, ag.number, page, ag.subNumber || 0)
                .then(res => {
                    setItems(res.childrenAgencies);
                    setItems(prevState => [...prevState, ag]);
                    setAllItems(prevState => [...prevState, ...res.childrenAgencies]);
                    setAllItems(prevState => [...prevState, ag]);
                    setTotalPages(res.totalPages);
                })
            } else {
                setAllItems(prevState => [...prevState, ag]);
                setItems(prevState => [...prevState, ag]);
            }
        })
    }
    useEffect(() => {
        setLoading(true);
        respuestas().then(() => setLoading(false));
    }, [page])

    const handleSearchName = (e) => {
        setInputName(e.target.value)
    }
    const handleSearchNumber = (e) => {
        setInputNumber(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSearching(true)
        let resultFilt =  searchpointsInArray(allItems, inputName, inputNumber)
        setItems(resultFilt)

    }

    const handleRefresh = (e) => {
        e.preventDefault()
        setSearching(false);
        setPage(1);
        setItems(allItems)
    }


    return (
        <Modal open={open} onClose={() => setOpen(false)}>
        <Grid container>
            <Grid xs={12} md={8} lg={4.5} item container className={s.boxModal} sx={{ bgcolor: "#EEEEEE" }}>
            <Grid container item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                <Grid item xs={11} className={s.exit}>
                    <div className={s.titleModal}>
                        <p>{t('selectAgency')}</p>
                        <span>{t('select')}</span>
                    </div>
                    <CloseIcon onClick={() => setOpen(false)} className={s.exitIcon} sx={{ color: "primary.dark" }} />
                </Grid>
        
                <Grid item xs={11} sx={{mb: 2, display: "flex", justifyContent: 'space-between', alignItems: 'center'}} className={s.search}>
                    <TextField
                        label={t('findByName')}
                        value={inputName}
                        onChange={handleSearchName}
                        sx={{width: '60%'}}
                    />
                    <br></br>
                    <TextField
                        label={t('findByNumber')}
                        value={inputNumber}
                        onChange={handleSearchNumber}
                        sx={{width: '60%'}}
                    />
                    <Button variant='contained' type='submit' disabled={!inputName.length && !inputNumber.length}  onClick={handleSubmit}>{t('apply')}</Button>
                    {
                    searching ?<Button variant='contained' onClick={handleRefresh}>{t('cleanFilters')}</Button>: <></>
                    }
                </Grid>

                {
                    loading ?
                    <Grid item xs={12} className={s.loader}>
                        <CircularProgress/>
                    </Grid> : items?.length ?
                    <Grid item container xs={11} className={s.data}>
                        <TableContainer className={s.tableContainer}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableCell sx={{backgroundColor: 'primary.main', color: textColor, fontSize: "14px", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'}}>{t('agencyNumber')}</TableCell>
                                    <TableCell sx={{backgroundColor: 'primary.main', color: textColor, fontSize: "14px", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'}}>{t('name')}</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        items.map((item, i) => {     
                                        let date = item.raffleDate?.split('T')[0].split('-').reverse().join('-');                               
                                        return (
                                        <>
                                            <TableRow key={item.id} sx={{"&:hover": {backgroundColor: "secondary.light"}}} onClick={() => {
                                                onSelect(item) 
                                                setOpen(false)
                                            }}>
                                                <TableCell sx={{...cellStyles,  backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12"}}>{`${item.number} ${item.subNumber ? ' - ' + item.subNumber : ''}`}</TableCell>
                                                <TableCell sx={{...cellStyles,  backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12"}}>{item.name}</TableCell>
                                            </TableRow>
                                        </>
                                        )})
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> :
                    <Grid item xs={12} className={s.noresult}>
                        {t('noResult')}
                    </Grid>
                }
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', my: 5}}>
                    <Pagination count={totalPages} page={page} onChange={handlePaginate} color='primary' disabled={loading}/>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    </Modal>
    )
};

export default AgencyModal;