export const getImputation = async() =>{
    try {
        const response = await fetch(`/gamma-accounting/imputations?pageNumber=1&pageSize=200&orderCriteria=ASC&orderField=name`,{
            method:"GET",
            headers:{"Content-Type": "application/json", "accept": "application/json"}
        })
        return await response.json();
    } catch (e) {
        console.log(e);
    }
}