export async function getAllConfigurations() {
  try{
    const response = await fetch( `/api-configuration/settings/config`);
    return await response.json();
  }catch(e){
    console.log(e)
  }
}

export async function createConfigItem(payload) {
  try{
    const response = await fetch(`/api-configuration/settings/config/create`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    })
    return response;
  }catch(e){
    console.log(e)
  }
}

export function searchConfigItem(configKey) {
    return fetch(`/api-configuration/settings/config/predicted/${configKey}`)
      .then(res => res.json())
      .then(json => {return json})
      .catch((err) => console.log(err));
}

export async function updateConfigItem(payload) {
  try{
    const response = await fetch(`/api-configuration/settings/config/edit`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    })
    return response;
  }catch(e){
    console.log(e)
  }
}

export async function deleteConfigItem(configItemId) {
  try{
    const response = await fetch(`/api-configuration/settings/config/delete?id=${configItemId}`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'}
    })
    console.log("delete config response",response)
    return response;
  }catch(e){
    console.log(e)
  }
}
