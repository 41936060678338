import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
//  url: "http://localhost:8090/auth",
 /*url: "https://keycloak.tecnoaccion.borealit.biz/auth",*/
 url: process.env.REACT_APP_KEYCLOAK_URL,
 realm: "tecnoaccion",
 clientId: "abaco-auth",
});

export default keycloak;