export const getMPDetallePagos = async (
    inst, 
    agencias, 
    rango_dias, 
    fecha_desde, 
    fecha_hasta
) => 
    {
        try {
            const response = fetch (
                `/apiMercadoPago/api/consultarDetallePagos/abaco?inst=${inst}&agencias=${agencias}&fecha_desde=${fecha_desde+"T00:00:00"}&fecha_hasta=${fecha_hasta+"T23:59:59"}`,
            )
            .then(response =>response.json())
            const respuesta = response;
            console.log("Respuesta MP: ", respuesta);
            return respuesta;
        } catch (e) {
            console.log("ERROR FETCH TOTAL MP: ", e);
            const respuesta = [];
            return respuesta;
        }
}

export const getMPTotalPagos = async (inst, agencias, rango_dias, fecha_desde, fecha_hasta) => {
    try{
        const response = await fetch (
            `/apiMercadoPago/api/consultarTotalPagos/abaco?inst=${inst}&agencias=${agencias}&fecha_desde=${fecha_desde+"T00:00:00"}&fecha_hasta=${fecha_hasta+"T23:59:59"}`,
        )
        const respuesta = await response.json();
        console.log("Respuestax MP: ", respuesta);
        return respuesta;
    }
    catch (error) {
        console.log("ERROR FETCH TOTAL MP: ", error)
        const respuesta = [];
        return respuesta;
    }

}