import * as React from 'react';
import Slide from '@mui/material/Slide';
import s from './CreateUser.module.css';
import { Users } from "../"


export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CreateUser = () => {

  return (
    <div className={s.container}>
      <Users createUser={true}/>
    </div>
  );
}