import React, { useEffect, useState } from 'react';
import s from './Detalles.module.css';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPlayDetails } from '../../../../../../api/saaservices/prize';

import {
    Modal,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Button
} from '@mui/material';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

const ModalJugadas = ({openPlay, handlePlay, data}) => {
    const { t } = useTranslation();
    const { textColor } = useSelector(state => state.local);
    // const { organizationId } = useSelector((state) => state.gamma)
    
    const [loading, setLoading] = useState(false);
    const [play, setPlay] = useState(null); 
    const [error, setError] = useState(false);

    const getData = () => {
        setLoading(true);
        // getPlayDetails(organizationId, data?.numeroApuesta)
        getPlayDetails(23, data?.numeroApuesta)
        .then(res => {
            setPlay(res);
            setError(false);
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
            setError(true)
        })
    }

    useEffect(() => {
        getData();
    }, [])

  return (
    <Modal open={openPlay} onClose={() => handlePlay(false)}>
        <Grid container>
            <Grid xs={12} md={10} lg={8} item container className={s.boxModal} sx={{ bgcolor: "#EEEEEE" }}>
            <Grid container item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                <Grid item xs={11} className={s.exit}>
                    <div className={s.titleModal}>
                        <BarChartRoundedIcon sx={{mr: 1, color: 'primary.main'}}/>
                        <p>{t('playDetails')}</p>
                    </div>
                    <CloseIcon onClick={() => handlePlay(false)} className={s.exitIcon} sx={{ color: "primary.dark" }} />
               </Grid>

               {
                loading ? 
                <Grid item xs={11} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px'}}>
                    <CircularProgress sx={{fontSize: '3rem'}}/>
                </Grid> : error ?
                <Grid item xs={11} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '400px'}}>
                    <ErrorIcon color='error' sx={{fontSize: "3rem"}}/>
                    <p>{t('sorryErrorOcurred')}</p>
                    <Button variant='outlined' onClick={getData} sx={{mt: 2}}>{t('retry')}</Button>
                </Grid> :
                <Grid item xs={11.5} sx={{height: '400px'}}>
                        <TableContainer className={s.tableContainer}>
                            <Table stickyHeader>
                                <TableHead>
                                {
                                    play?.cabecera?.map((item, i) => {
                                        return(<TableCell sx={{backgroundColor: 'primary.main', color: textColor, fontWeight: 500, fontSize: '.8rem'}} key={i}>{item}</TableCell>)
                                    })
                                }
                                </TableHead>
                                <TableBody>
                                {
                                    play?.lineas?.map((fila, i) => {
                                        return(
                                            <TableRow key={i}>
                                                {fila.linea.map((item, key) => <TableCell key={key}>{item}</TableCell>)}
                                            </TableRow>
                                        )})
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                </Grid>
                }
            </Grid>
            </Grid>
        </Grid>
    </Modal>
  )
}

export default ModalJugadas;

