import React, {useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal } from "../../../Shared/Modal/Modal";
import AgencyModal from "../../../Shared/AgencyModal/AgencyModal";

import styles from "./UserPoints.module.css"
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import StoreIcon from "@mui/icons-material/Store";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import Alert from '@mui/material/Alert';
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import { useEffect } from "react";
import { callSalePointsById } from "../../../../store/slices/my_agencies"
import { getAgenciesRelationed, sendRelationedAgencies } from "../../../../api/agencies/AgenciesService";
import { t } from "i18next";

const ListItem = styled("li")(() => ({
    listStyle: "none",
}));


export const UserPoints = (props) => {

    const dispatch = useDispatch();
    const { activeOrganization } = useSelector((state) => state.gamma)
    const {setEdit, chipData, setChipData, points, setOpenModal, openModal, primaryColor, secondaryColor, createUser, setUserPoints, userEdit} = props
    const currentUser = useSelector(state => state.gamma.logged_user)
    const salePointsRedux = useSelector(state => state.myAgencies.sale_points)
    const [salePoints, setSalePoints ] = useState([])
    const [openAgencyModal, setOpenAgencyModal] = useState(false)
    let rol
    const [point, setPoint] = useState(null)

    const formatResult = (item) => {
      return (
        <div className={styles.msg_container}>
          <span className={styles.name}>
            {item.name}
          </span>
          <span className={styles.number}>{`${item.number}-${item.subNumber || 0}`}</span>
        </div>
      );
    };

    useEffect(() => {
      !createUser && getAgenciesRelationed(userEdit?.id, activeOrganization?.id)
      .then(res => {
        if(res?.content?.length >= 1){
          console.log("resultado de Agencias relacionadas",res)
          //const points = res.content.split(",")
          if(res?.content?.length){
            setSalePoints(res.content)
            dispatch(callSalePointsById(res.content))
          }
        }else{
          dispatch(callSalePointsById([]))
        }
      })
    }, [activeOrganization?.id, currentUser?.id, dispatch, createUser, userEdit])


    useEffect(() => {
      if(salePointsRedux?.length && !createUser){
        const chips = salePointsRedux?.length && salePointsRedux?.map(agencie => {
          return {key: agencie?.id, label: `${agencie?.number}-${agencie?.subNumber || 0}-${agencie?.id}`}
        })
        setChipData(chips)
      }else{
        setChipData([])
      }
    }, [salePointsRedux, createUser, setChipData])

    const handleDeletePoint = (chiptoDelete) => {
        setChipData((chips) =>
          chips.filter((chip) => chip.key !== chiptoDelete.key)
        );
        let new_points = [...salePoints].filter(p => p * 1 !== chiptoDelete.label.split("-")[2] * 1 )
        sendRelationedAgencies(userEdit?.id, activeOrganization?.id, new_points)
        setSalePoints(new_points)
        setUserPoints(new_points)
        setEdit(false);
    };
    
    const handleAddPoint = (agencie, rol) => {
        const [number, subNumber] = [
          agencie.split("-")[0] * 1,
          agencie.split("-")[1] * 1,
        ];

        let repeat = chipData.some((r) => {
          const [number2, subNumber2] = [
            r.label.split("-")[0] * 1,
            r.label.split("-")[1] * 1,
          ];
          return number2 === number && subNumber2 === subNumber;
        })
        if(repeat){
          Swal.fire({
            icon: "error",
            title: t("weFeelIt"),
            text: t("TheSamePointOfSaleCannotBeAddedTwice"),
          });
        }else{ 
          chipData.length === 0 ? setChipData([{ key: 1, label: agencie }]) : setChipData([
            ...chipData,
            { key: chipData[chipData.length - 1].key + 1, label: agencie },
          ])
          const ids = salePoints.length ? [...salePoints, agencie.split("-")[2]] : [agencie.split("-")[2]];
          setEdit(false);
          setSalePoints(ids);
          if(!createUser){
            sendRelationedAgencies(userEdit?.id, activeOrganization?.id, ids)
          }else{
            console.log("ids de los que voy a crear: ", ids)
            setUserPoints(ids)
          }
          setPoint("")
        }
    };
    
    const handleSelectPoint = (item) => {
      console.log("lo que llega al handleSelectPoint", item)
      setPoint(`${item?.number}-${item?.subNumber || 0}-${item?.id}`);
    };

    const handleClickOpenModal = () => {
        setOpenModal(true);
    };
    
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    
    const handleAddPointsToUser = () => {
      handleAddPoint(point, rol)
      handleCloseModal();
    }

    return (
        <div className={styles.container}>
        <div className={`${styles.sellPoints}`}>
        <div className={styles.sellIcon}>
          <Tooltip title="Puntos de venta" placement="right">
            <StoreIcon
              sx={{
                fontSize: 35,
                color: primaryColor,
                transition: "0.5s ease",
              }}
            />
          </Tooltip>
          <Typography sx={{fontSize: '25px', fontFamily:'Roboto, sans-serif', fontWeight: 300, textAlign:"center" }} className={styles.titlePoints}>{t("points")}</Typography>
        </div>
        <div className={styles.addAgencies}>
          {
            currentUser.memberships[0].roles[0].name === "Administrador Loteria" ?
            <Modal 
              icon={<AddCircleOutlineIcon sx={{fontSize: 30,color: primaryColor, cursor: "pointer",transition: "0.5s ease"}}/>} 
              open={openModal} 
              onOpen={handleClickOpenModal} 
              onClose={handleCloseModal} 
              title={t("pointOfSaleToAdd")}
              className={styles.modalContainer}
              component={"sale_points"}
              text={point ? 
                <div className={styles.addUserContainer}>
                  <Button variant="contained" sx={{marginTop: 2, backgroundColor: secondaryColor, color: "white", ":hover": `backgroundColor: ${primaryColor.main}`}} 
                  onClick={handleAddPointsToUser}>{t("add")}</Button>
                </div> : 
                <h4 style={{fontFamily:'Roboto, sans-serif', fontWeight:300, fontSize:'20px', textAlign: 'center'}}>{t("searchAndSelect")}</h4>}
              arr={points}
              keys={{keys: ["name", "number", "subNumber"],}}
              result={"name"}
              onSelect={handleSelectPoint}
              format={formatResult}
              placeholder={t("EnterAPointOfSale")}
              primaryColor={primaryColor}
            /> :
            <AddCircleOutlineIcon sx={{fontSize: 30,color: primaryColor, cursor: "pointer",transition: "0.5s ease"}}
            onClick={() => setOpenAgencyModal(true)}
            />
            
          }
            {
              openAgencyModal ?
              <AgencyModal open={openAgencyModal}  setOpen={setOpenAgencyModal} onSelect={handleAddPoint} /> 
              : null
            }
        </div>
      </div>
      <div className={styles.agencies}>
        {console.log("chip para mostrar en pdv", chipData)}
        {chipData?.length ? (
          chipData?.map((data) => {
            return (
              <ListItem sx={{ m: 1 }} key={data.key}>
                <Chip
                  label={data.label.split("-")[0] + "-" + data.label.split("-")[1]}
                  onDelete={() => handleDeletePoint(data)}
                  deleteIcon={<DeleteIcon sx={{ color: "white"}} />}
                  sx={{
                    backgroundColor: secondaryColor,
                    width: "100%",
                    height: "40px",
                    fontSize: "1em",
                    color: "white",
                  }}
                />
              </ListItem>
            );
          })
        ) : (
          <div>
          <h1 className={styles.noPoints}>
            {t("doesNotHavePointsOfSale")}
          </h1>
            <Alert severity="warning" sx={{fontSize: "90%"}}>{createUser ? t("TheUserWillBeCreatedWithoutAssignedSalesPoints") :  t("TheUserWillBeEditedWithoutAssignedSalesPoints") }</Alert>
          </div>
        )}
      </div>
      </div>
    )
}
