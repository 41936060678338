export const System = {
    SAA: "SAA"
};

export const getHomologationForName = (saaId, homologations) => {
    const homologation = homologations?.find(res => res.ownerId === saaId && res.integration.find(i => i.module === "SAA"));
    if (homologation === undefined) {
      return "-";
    } else {
      return homologation.name;
    }
}

export const getHomologationForId = (gammaId, homologations) => {
    const homologation = homologations?.find(res => res.ownerId === gammaId && res.integration.find(i => i.module === "SAA"));
    if (homologation === undefined) {
      return "-";
    } else {
      return homologation.name;
    }
}

export const getHomologatedGamesFrom = (system, homologations) => {
    return homologations?.filter(h => h.integration.find(i => i.module === system)) || [];
}