
function compareNames( a, b ) {
    if ( a.firstname < b.firstname ){
      return -1;
    }
    if ( a.firstname > b.firstname ){
      return 1;
    }
    return 0;
  }

  function compareLast( a, b ) {
    if ( a.lastname < b.lastname ){
      return -1;
    }
    if ( a.lastname > b.lastname ){
      return 1;
    }
    return 0;
  }

  function compareEmail( a, b ) {
    if ( a.email < b.email ){
      return -1;
    }
    if ( a.email > b.email ){
      return 1;
    }
    return 0;
  }
export const orderUsers = (items, order) => {
    if(order.name === 'firstname'){
        return order.increasing ? items.slice().sort(compareNames) : items.slice().sort(compareNames).reverse();
    }
    if(order.name === 'lastname'){
        return order.increasing ? items.slice().sort(compareLast) : items.slice().sort(compareLast).reverse();
    }
    if(order.name === 'email'){
        return order.increasing ? items.slice().sort(compareEmail) : items.slice().sort(compareEmail).reverse();
    }
}