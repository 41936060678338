import React, { useState } from "react"
import { useDispatch } from "react-redux"
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./UserData.module.css"
import GppBadIcon from "@mui/icons-material/GppBad";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import { Select, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from "@mui/material/Grid";


export const UserData = (props) => {

    const dispatch = useDispatch()
    const {userEdit, edit, primaryColor, banUnban, setUserEdit, createUser, errors, setErrors, sent, setSent} = props

    const [ showPassword, setShowPassword ] = useState({
      password: false,
      confirmation: false
    })


    const [ password2, setPasword2] = useState("")
    const [errorEqual, setErrorEqual] = useState(false)


    const handleCompare = (e) => {
      setPasword2(e.target.value)
      if(e.target.value !== userEdit.password){
        setErrorEqual(true)      
        dispatch(setErrors({prop: "confirm", status: true}))
      }else{
        setErrorEqual(false)
        dispatch(setErrors({prop: "confirm", status: false}))
      }
    }

    React.useEffect(() => {
      sent && setPasword2("")
      password2 === "" && setSent(false)
    }, [sent, password2, setPasword2, setSent])

    const handleEdit = (e) => {
            setUserEdit({
                ...userEdit,
                [e.target.name]: e.target.value
            })

            let lengthError = e.target.value.length < 3 || e.target.value.length > 25
            if(e.target.name === "firstname" || e.target.name === "lastname"){
                if(lengthError || !/^[a-zA-Z ]*$/.test(e.target.value)){
                  dispatch(setErrors({prop: e.target.name, status: true}))
                }else{
                  dispatch(setErrors({prop: e.target.name, status: false}))
                }
                }else if(e.target.name === "password"){
                  if(e.target.value !== password2){
                    setErrorEqual(true)
                    dispatch(setErrors({prop: "confirm", status: true}))
                }else{
                  setErrorEqual(false)
                  dispatch(setErrors({prop: "confirm", status: false}))
                }
                if(lengthError || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_#<+¡}{¿>$@$!%*?&])[A-Za-z\d-_#<+¡}{¿>$@$!%*?&]{8,20}$/.test(e.target.value)){
                  dispatch(setErrors({prop: e.target.name, status: true}))
                }else{
                  dispatch(setErrors({prop: e.target.name, status: false}))
                }
              }
              else if(e.target.name === "email"){
                if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e.target.value)){
                  dispatch(setErrors({prop: e.target.name, status: true}))
                }else{
                  dispatch(setErrors({prop: e.target.name, status: false}))
                }
            }
    };

    return (
        <>
        <Grid container justifyContent='center' alignItems='center' className={styles.cardName_and_Last}>
              <div className={`${styles.selectInput}`}>
              <FormControlLabel
                  control={
                  <label className={styles.labelInput}>
                      {userEdit?.enabled ? (
                      <GppGoodIcon
                      sx={{
                          fontSize: 40,
                          color: primaryColor,
                          transition: "0.5s ease",
                          marginRight: "4px",
                      }}
                      />
                      ) : (
                          <GppBadIcon
                          sx={{
                          fontSize: 40,
                          color: "red",
                          transition: "0.5s ease",
                          marginRight: "4px",
                          }}
                          />
                          )}
                      {userEdit.enabled ? "Usuario activo" : "Usuario inactivo"}
                      <Switch checked={userEdit.enabled} sx={{ ms: 7 }} />
                  </label>
                  }
                  sx={{fontSize: "24px", fontFamily: 'Roboto, sans-serif',  fontWeight: 400}}
                  onClick={() => banUnban()}
              />
              </div>
            <Grid container className={styles.nameContainer}>
              <Grid item md={5} xs={12} className={styles.inputField}>
                  <TextField
                  sx={{ width: "90%", backgroundColor: "white" }}
                  disabled={edit}
                  id="outlined-multiline-static"
                  name="firstname"
                  label="Nombre *"
                  //required
                  rows={1}
                  value={userEdit.firstname}
                  className={styles.cardFirstname}
                  onChange={handleEdit}
                  />
                  <div className={styles.errorContainer}>
                    {
                      errors.firstname ?   <Alert severity="error" sx={{fontSize: "90%",  width:"100%"}}>Por favor ingrese un nombre valido.</Alert> : <p>* Obligatorio</p>
                    }
                  </div>
                </Grid>
                <Grid item md={5} xs={12} className={styles.inputField}>
                  <TextField
                  sx={{ width: "90%", backgroundColor: "white" }}
                  disabled={edit}
                  id="outlined-multiline-static"
                  label="Apellido *"
                  name="lastname"
                  onChange={handleEdit}
                  //required
                  rows={1}
                  value={userEdit.lastname}
                  />
                  <div className={styles.errorContainer}>
                  {
                    errors.lastname ?  <Alert severity="error" sx={{fontSize: "90%",  width:"100%"}}>Por favor ingrese un apellido valido.</Alert> : <p>* Obligatorio</p>
                  }
                  </div>
              </Grid>
            </Grid>

            <Grid container className={styles.emailDni}>
              <Grid item className={styles.inputField} md={5} xs={12}>
                <TextField
                sx={{width: "90%", backgroundColor: "white"}}
                disabled={edit}
                id="outlined-multiline-static"
                label="Correo Electrónico *"
                name="email"
                onChange={handleEdit}
                //required
                rows={1}
                value={userEdit.email}
                />
                <div className={styles.errorContainer}>
                  {
                    errors.email ? <Alert severity="error" sx={{fontSize: "100%",  width:"100%"}}>Por favor ingrese un correo electronico valido.</Alert>: <p>* Obligatorio</p>
                }
                </div>
              </Grid>

              <Grid item container className={styles.inputFieldDni} md={5} xs={12}>
              <FormControl className={styles.selector} size="small">
                <InputLabel id="demo-simple-select-label">Tipo de documento</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={'Tipo'}
                  label="Tipo"
                  disabled={edit}
                  sx={{height:'55px', backgroundColor: '#EEEEEE'}}
                >
                  <MenuItem value={'dni'}>DNI</MenuItem>
                  <MenuItem value={'lc'}>LC</MenuItem>
                  <MenuItem value={'le'}>LE</MenuItem>
                  <MenuItem value={'ci'}>CI</MenuItem>
                </Select>
              </FormControl>
                <TextField
                sx={{width: "65%", backgroundColor: '#EEEEEE' }}
                disabled={edit}
                id="outlined-multiline-static"
                label="Número"
                name="Número"
                rows={1}
                />
                <div className={styles.errorContainer}>
                    <p></p>
                </div>
              </Grid>
            </Grid>

            <Grid container className={styles.passwords}>
            {
              createUser ?   <>
              <Grid item md={5} xs={12} className={styles.passwordInput}>
                <FormControl sx={{ width: "90%"}} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Contraseña *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword.password?"text":"password"}
                    value={userEdit.password}
                    disabled={edit}
                    rows={1}
                    name="password"
                    onChange={(e) => handleEdit(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
                          edge="end"
                        >
                          {showPassword.password ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Contraseña *"
                  />
                </FormControl>
                <div className={styles.errorContainer}>
                  {
                    errors.password ? <Alert severity="error" sx={{fontSize: "80%",  width:"100%"}}>La contraseña tiene que tener almenos 8 caracteres, 1 mayuscula, números y almenos 1 simbolo.</Alert>: <p>* Obligatorio</p>
                  }
                </div>
              </Grid>
              <Grid item md={5} xs={12} className={styles.passwordInput}>
                <FormControl sx={{width: "90%"}} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Repita la contraseña *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword.confirmation?"text":"password"}
                    value={password2}
                    onChange={handleCompare}
                    disabled={edit}
                    rows={1}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword({...showPassword, confirmation: !showPassword.confirmation})}
                          edge="end"
                        >
                          {showPassword.confirmation ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Repita la contraseña *"
                  />
                </FormControl>
                <div className={styles.errorContainer}>
                  {
                    errorEqual ? <Alert severity="error" sx={{fontSize: "80%",  width:"100%"}}>Las contraseñas tienen que coincidir.</Alert>: <p>* Obligatorio</p>
                }
                </div>
              </Grid>
              </>: <></>
            }
            </Grid>
            </Grid>
        </>
    )
}