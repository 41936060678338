import ConfigsSearcher from "../config/Configs";

const NEGATIVE_NUMBER_COLOR = '#FF3333';

export const currencyFormat = () => {
	const config = ConfigsSearcher();
	const locale = config.configFinder('LOCALE');
	const currency = config.configFinder('CURRENCY');
	const formatCurrency = (value) => {
		const quantity = value?.toLocaleString(locale, {
			style: 'currency',
			currency: currency,
		}) || '-'
		let partes = quantity.toString().split('.');
		partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		let res = partes.join('.');
		return res
	}
	return {formatCurrency}
}

export const numberTwoDecimals = (value) => {
  const format = value.toFixed(2)
  const parseString = parseFloat(format)

  return parseString
}

export const Currency = ({value}) => {
	const config = ConfigsSearcher();

	const locale = config.configFinder('LOCALE');
	const currency = config.configFinder('CURRENCY');

	const color = value < 0 ? NEGATIVE_NUMBER_COLOR : 'black';

	const quantity = !isNaN(value) ? value?.toLocaleString(locale, {
		style: 'currency',
		currency: currency
	}) : '-'

	return(
		<p style={{margin: 0, color: color}}>{quantity}</p>
	);
}

export const Quantity = ({value}) => {
	const config = ConfigsSearcher();

	const locale = config.configFinder('LOCALE');

	const quantity = !isNaN(value) ? value?.toLocaleString(locale, {
		maximumFractionDigits: 0
	}) : '-'

	return(
		<p style={{margin: 0}}>{quantity}</p>
	);
}

export const Number = ({value, decimals = 2}) => {	
	const config = ConfigsSearcher();

	const locale = config.configFinder('LOCALE');

	const quantity = !isNaN(value) ? value?.toLocaleString(locale, {
		maximumFractionDigits: decimals != null ? decimals : undefined
	}) : '-';

	return(
		<p style={{margin: 0}}>{quantity}</p>
	);
}