import React, { useEffect, useState } from 'react'
import s from './AgencyModal.module.css';

//assets
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//materialUi
import {
    CircularProgress,
    Grid,
    Modal,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Pagination,
    TextField,
    Button
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { agencieSearch, agencieSearchByNumber } from '../../../api/agencies/AgenciesService';

const cellStyles = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '.9rem',
    fontWeight: '400',
    borderBottom: '1px solid rgba(0, 0, 0, 0.455)',
    cursor: "pointer"
}

const AgencyModalAdmin = ({open, setOpen, onSelect}) => {

    const { t } = useTranslation();
    const { textColor } = useSelector(state => state.local);
    const { activeOrganization } = useSelector(state => state.gamma)
    
    const [items, setItems] = useState([]);

    //paginado
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(0);
    const [query, setQuery] = useState({
        name: '',
        number: '',
        subnumber: ''
    });
    const [sort, setSort] = useState({
        criteria: 'ASC',
        field: 'name'
    })
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handlePaginate = (e, v) => {
		setPage(v)
	}

    useEffect(() => {
        setLoading(true);
        if(query.number){
            agencieSearchByNumber(activeOrganization?.id, query)
              .then(res => {
                const sale = []
                sale.push(res)
                  if (res.childrenAgencies.length > 0) {
                    res.childrenAgencies.forEach(ag => {
                        sale.push(ag)  
                    });                             
                  } 
                  setItems(sale);
                  setTotalPages(res.totalPages);
              })
              .then(() => setLoading(false))
              .catch(() => {
                  setItems([]);
                  setTotalPages(1);
                  setLoading(false);
              })
        }else{
            agencieSearch(activeOrganization?.id, query.name, 'SALE_POINT', page, 'ENABLED', sort)
            .then(res => {
                setItems(res.content);
                setTotalPages(res.totalPages);
            })
            .then(() => setLoading(false));
        }
    }, [page, searching, sort])

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
        <Grid container>
            <Grid xs={12} md={8} lg={4.5} item container className={s.boxModal} sx={{ bgcolor: "#EEEEEE" }}>
            <Grid container item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                <Grid item xs={11} className={s.exit}>
                    <div className={s.titleModal}>
                        <p>{t('agency')}</p>
                        <span>{t('select')}</span>
                    </div>
                    <CloseIcon onClick={() => setOpen(false)} className={s.exitIcon} sx={{ color: "primary.dark" }} />
               </Grid>

               <Grid item container xs={11} sx={{mb: 2, display: "flex", justifyContent: 'flex-start', alignItems: 'center'}} className={s.search}>
                   <Grid item xs={11.4} md={5.5} lg={5.5} sx={{mb: 2, mr: 2}}>
                       <TextField
                            label={t('number')}
                            value={query.number}
                            type={'number'}
                            onChange={e => setQuery(prev => ({...prev, number: e.target.value}))}
                            sx={{width: '100%'}}
                        />
                   </Grid>
                   <Grid item xs={11.4} md={5.5} lg={5.5} sx={{mb: 2}}>
                       <TextField
                         label={t('subnumber')}
                         value={query.subnumber}
                         disabled={!query.number}
                         type={'number'}
                         onChange={e => setQuery(prev => ({...prev, subnumber: e.target.value}))}
                         sx={{width: '100%'}}
                       />
                   </Grid>
                   <Grid item xs={11.4} sx={{mb: 1}}>
                       <TextField
                         label={t('name')}
                         value={query.name}
                         onChange={e => setQuery(prev => ({...prev, name: e.target.value}))}
                         sx={{width: '100%'}}
                       />
                   </Grid>
               </Grid>

                <Grid item container xs={11} md={11} lg={11} sx={{mb: 2, display: "flex", justifyContent: "flex-start", alignItems: "center"}} className={s.search}>
                    <Grid item xs={3} sx={{mr: 1}}>
                        <Button variant='contained' disabled={query.name === '' && (query.number === '' || query.number === '0')} onClick={() => {
                            setPage(1);
                            setSearching(prev => prev + 1);
                        }} sx={{width: '90%'}}>{t('find')}</Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant='contained' disabled={searching === 0} onClick={() => {
                            setPage(1);
                            setSearching(0);
                            setQuery({
                                name: '',
                                number: '',
                                subnumber: ''
                            });
                        }} sx={{width: '90%'}}>{t('clear')}</Button>
                    </Grid>
                </Grid>

                {
                    loading ?
                    <Grid item xs={12} className={s.loader}>
                        <CircularProgress/>
                    </Grid> : items?.length ?
                    <Grid item container xs={11} className={s.data}>
                        <TableContainer className={s.tableContainer}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableCell sx={{'&:hover': {cursor: 'pointer'} ,backgroundColor: 'primary.main', color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'}}
                                        onClick={() => {
                                            setSort( prev => ({
                                                criteria: prev.criteria === 'ASC' ? 'DESC' : 'ASC',
                                                field: 'number',
                                            }))
                                        }}
                                    >{`${t('agencyNumber')} ${sort.field === 'number' ? sort.criteria === 'ASC' ? '▼' : '▲': ''}`}</TableCell>
                                    <TableCell sx={{'&:hover': {cursor: 'pointer'}, backgroundColor: 'primary.main', color: textColor, fontSize: ".9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'}}
                                        onClick={() => {
                                            setSort( prev => ({
                                                criteria: prev.criteria === 'ASC' ? 'DESC' : 'ASC',
                                                field: 'name',
                                            }))
                                        }}
                                    >{`${t('name')} ${sort.field === 'name' ? sort.criteria === 'ASC' ? '▼' : '▲': ''}`}</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        items.map((item, i) => {                                 
                                        return (
                                        <>
                                            <TableRow key={item.id} sx={{"&:hover": {backgroundColor: "secondary.light"}}} onClick={() => {
                                                 onSelect(item);
                                                 setOpen(false);
                                            }}>
                                                <TableCell sx={{...cellStyles,  backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12"}}>{`${item.number} ${item.subNumber ? ' - ' + item.subNumber :  item.agencyLevel === 'CONCENTRATOR' ? ' ' : ' - 0'}`}</TableCell>
                                                <TableCell sx={{...cellStyles,  backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12"}}>{item.name}</TableCell>
                                            </TableRow>
                                        </>
                                        )})
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> :
                    <Grid item xs={12} className={s.noresult}>
                        {t('noResults')}
                    </Grid>
                }
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', my: 5}} className={s.pagination}>
                    <Pagination count={totalPages} page={page} onChange={handlePaginate} color='primary' disabled={loading}/>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    </Modal>
    )
};

export default AgencyModalAdmin;