export const getloggedUser = (userId) => {
  return fetch(`/gamma-users/users/${userId}`)
};

export const getGameOffers = (organizationId, enabled, pageNumber, pageSize) => {
  return fetch(`/gamma-organizations/organizations/${organizationId}/gameOffers?pageNumber=${pageNumber || 1}&pageSize=${pageSize || 2}0&orderCriteria=ASC&orderField=id&enabled=${enabled === null || enabled === undefined ? enabled : true}`)
    .then((response) => response.json())
    .then((json) => {
      return json
    })
    .catch((err) => console.log(err));
};

export const getOrganizationAgencies = (organizationId, pageNumber, perPage, sort, filter) => {
  return fetch(`/gamma-agencies/organizations/${organizationId || 9}/agencies?pageNumber=${pageNumber || 1}&pageSize=${perPage || 10}&orderCriteria=${sort?.criteria || 'ASC'}&orderField=${sort?.field || 'name'}${filter?.status ? filter.status !== 'ALL' ? `&status=${filter?.status}` : "" : ""}${filter?.type ? filter.type !== 'ALL' ? `&agencyKind=${filter?.type}` : "" : ""}`,{
      method: "GET",
      headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {
      if(response.status !== 200){
        throw response.status;
      }else{
        return response;
      }
    })
    .catch((err) => {throw err});
};

export const getRoles = () => {
  return fetch(`/gamma-users/roles?pageNumber=1&pageSize=100&orderCriteria=ASC&orderField=name`)
    .then((response) => response.json())
    .then((json) => {
      return json
    })
    .catch((err) => console.log(err));
};

export const getSalePoints = () => {
  return fetch(`/gamma-agencies/agencies?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name&status=ENABLED&agencyKind=SALE_POINT`, {
      method: "GET",
      headers: {'Content-Type': 'application/json'}
    })
    .then((response) => response.json())
    .then((json) => {
      return json
    })
    .catch((err) => console.log(err));
};

export const getAllOrganizations = () => {
  return fetch(`/gamma-all-organizations/organizations?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name`)
    .then((response) => response.json())
    .then((json) => {
      return json
    })
    .catch((err) => console.log(err));
};

export const getAccountingCodeKinds = () => {
  return fetch(`/gamma-accounting/accountingCodeKinds?pageNumber=1&pageSize=100&orderCriteria=ASC&orderField=name`)
    .then((response) => response.json())
    .then((json) => {
      return json
    })
    .catch((err) => console.log(err));
};
