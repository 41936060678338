export const getCashStatus = async (organizacionId, agencia, subagencia) => {
  try {
    const response = await fetch(
      `/saaServices/saaServices/services/abaco/resumenCaja?organizacionId=${
        /*organizacionId*/ 23
      }&agencia=${agencia}&subagencia=${
        subagencia !== undefined ? subagencia : -1
      }`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};
