export const getAgencieReport = async (agencie, date, subagency) => {
    try {
        const response = await fetch (`/gamma-movements/settlements/agencies/${agencie?.id}/report?settlementDate=${date}&pageSize=10000000&movementSize=10000000${subagency && agencie?.id !== subagency?.id ? `&agencyIds=${subagency?.id}` : ''}`,{
            method:"GET",
            headers: {"Content-Type": "application/json", "accept": "application/json"}
        })
        if (response.status === 200) {
           return response.json(); 
        }
        else{
            return null
        }
    } catch (e) {
        console.log(e);
    }
}