import { useDispatch} from "react-redux"
import { currentAccountPrevPage, currentAccountNextPage, currentAccountChangePage } from "../../../store/slices/paginationSlice"
import {movementsUpdate} from "../../../store/slices/gammaSlice";
import { getAgencieReport } from "../../../api/movement/MovementService";

const Pagination = (actualPage, totalPages, id_agency) => {
    
    const dispatch = useDispatch()

    const currentAgencies = async () => {
        try{
            // const response = await getAgencieReport(id_agency, actualPage);
            // dispatch(movementsUpdate(response));
        }catch(e){
            console.log(e)
        }
    }

    const next = () => {
        actualPage !== totalPages && dispatch(currentAccountNextPage())
    }

    const prev = () => {
        actualPage !== 1 && dispatch(currentAccountPrevPage())
    }

    const jump = async (page) => {
        // const response = await getMovements(id_agency, page)
        // dispatch(movementsUpdate(response))
        // dispatch(currentAccountChangePage(page))
    }

    return {next, prev, jump, currentAgencies, actualPage, totalPages}
}

export default Pagination
