import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedAgencies: [],
};

const pointsSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {

        pushAgencie(state, action) {
            state.selectedAgencies.push(action.payload);
        },
        popAgencie(state, action) {
            const equal = (element) => element.id === action.id;
            const index = state.selectedAgencies.findIndex(equal);
            state.selectedAgencies.splice(index, 1);
        },
        resetPointsSlice(state, action) {
            state.selectedAgencies = []
        }

    },
})

// Action creators are generated for each case reducer function
export const { pushAgencie, popAgencie, resetPointsSlice } = pointsSlice.actions
export default pointsSlice.reducer
