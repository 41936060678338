import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
 const { keycloak } = useKeycloak();

 const isLoggedIn = keycloak.authenticated;
 const navigate = useNavigate();

 return isLoggedIn ? children : navigate('/');
};

export default PrivateRoute;