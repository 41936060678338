
//control de caja
export const getCashControl = async (organizacionId,fecha,agencia,subagencia) => {
    try{
        const response = await fetch(`/saaServices/saaServices/services/abaco/controlesCaja?organizacionId=${/*organizacionId*/23}&fecha=${fecha}&agencia=${agencia}&subagencia=${subagencia}`,{
            method: "GET",
            headers: {"Content-Type": "application/json"}
        })
        return response.json();
    }catch(e){
        console.log(e)
    }
}
