// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".functionsStyles_msg_container__-haWN{\n  height: 45px;\n}\n\n.functionsStyles_msg_container__-haWN .functionsStyles_name__hmuiz{\n  font-size: 18px;\n}\n\n.functionsStyles_msg_container__-haWN .functionsStyles_agencie__QCIXU{\n  font-size: 16px;\n  color: rgb(117, 117, 117);\n}\n\n.functionsStyles_name__hmuiz{\n  display: block;\n  font-weight: 600;\n  font-size: 19px;\n  text-transform: capitalize;\n}\n\n.functionsStyles_email__kTed\\+{\n  color: rgb(117, 117, 117);\n  font-size: 16px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/helpers/functionsStyles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".msg_container{\n  height: 45px;\n}\n\n.msg_container .name{\n  font-size: 18px;\n}\n\n.msg_container .agencie{\n  font-size: 16px;\n  color: rgb(117, 117, 117);\n}\n\n.name{\n  display: block;\n  font-weight: 600;\n  font-size: 19px;\n  text-transform: capitalize;\n}\n\n.email{\n  color: rgb(117, 117, 117);\n  font-size: 16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msg_container": "functionsStyles_msg_container__-haWN",
	"name": "functionsStyles_name__hmuiz",
	"agencie": "functionsStyles_agencie__QCIXU",
	"email": "functionsStyles_email__kTed+"
};
export default ___CSS_LOADER_EXPORT___;
