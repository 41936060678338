
export const getTableRanking = async (organizacionId, agencia, subAgencia, codigoJuego, fechaDesde, fechaHasta, maquinaConVenta) => {
    try {
        const response = await fetch(`/saaServices/saaServices/services/abaco/rankingPorBoca?organizacionId=${/*organizacionId*/23}&agencia=${agencia}&subAgencia=${subAgencia}&codigoJuego=${codigoJuego}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&habilitadas=true&conVenta=${maquinaConVenta}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        })
        return response.json();
    } catch (e) {
        console.log(e)
    }
}

export const getProvRanking = async (organizacionId, codigoJuego, fechaDesde, fechaHasta) => {
    try {
        const response = await fetch(`/saaServices/saaServices/services/abaco/rankingprovincias?organizacionId=${/*organizacionId*/23}&codigoJuego=${codigoJuego}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        })
        return response.json();
    } catch (e) {
        console.log(e)
    }
}

export const getExpiredRanking = async (organizacionId, agencia, codigoJuego, fechaDesde, fechaHasta) => {
    try {
        const response = await fetch(`/saaServices/saaServices/services/abaco/rankingcaducos?organizacionId=${/*organizacionId*/23}&agencia=${agencia}&codigoJuego=${codigoJuego}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        })
        return response.json();
    } catch (e) {
        console.log(e)
    }
}

