import { createSlice } from "@reduxjs/toolkit";
import { getAgencieReport } from "../../api/movement/MovementService.js";
import { yesterday, today, EmptyMovement } from "../../config/strings.js";
import { setMy_AgenciesError } from "./errors";
import {
  getAgencieById,
  getAgencieByIdAwait,
} from "../../api/agencies/AgenciesService.jsx";
import { useSelector } from "react-redux";

const initialState = {
  agencies_favs: [],
  user_agencies: [],
  my_movements: [],
  total_movement: [],
  selected_agencie_to_consult: {},
  sale_points: [],
  selected_concentrator: {},
  analisisUsers: true,
};

const premiosDataSlice = createSlice({
  name: "premiosData",
  initialState,
  reducers: {
    setResumenData: (state, action) => {
      state.resumenData = action.payload;
    },
    setPagadosData: (state, action) => {
      state.pagadosData = action.payload;
    },
    setPagadosPorOtraData: (state, action) => {
      state.porOtraData = action.payload;
    },
    setPagadosParaOtraData: (state, action) => {
      state.paraOtraData = action.payload;
    },
    setCaducosData: (state, action) => {
      state.user_agencies = action.payload;
    }
  },
});

export const {
  setResumenData,
  setPagadosData,
  setPagadosParaOtraData,
  setPagadosPorOtraData,
  setCaducosData
} = premiosDataSlice.actions;


export default premiosDataSlice.reducer;
