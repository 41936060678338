// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginTerms_container__v5Das {\n    width: 100%;\n    height: 86vh;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.LoginTerms_title__XezB2{\n    font-family: sans-serif;\n    font-weight: 500;\n    font-size: 3em;\n}\n\n.LoginTerms_container_terminos__a0kbN{\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/Pages/LoginTerms/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".container {\n    width: 100%;\n    height: 86vh;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.title{\n    font-family: sans-serif;\n    font-weight: 500;\n    font-size: 3em;\n}\n\n.container_terminos{\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LoginTerms_container__v5Das",
	"title": "LoginTerms_title__XezB2",
	"container_terminos": "LoginTerms_container_terminos__a0kbN"
};
export default ___CSS_LOADER_EXPORT___;
