import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    errors: {
        firstname: false,
        lastname: false,
        email: false,
        password: false,
        confirm: false,
    },
    actualUsersOrg: [],
    usersSearched: []
};

const localSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setErrors(state, action) {
        state.errors = {
            ...state.errors,
            [action.payload.prop]: action.payload.status 
        }
    },
    setErrorsDefault(state, action){
        state.errors = {
            firstname: false,
            lastname: false,
            email: false,
            password: false,
            confirm: false,
        }
    },
    resetUserSlice(state, action){
        state.errors = {
            firstname: false,
            lastname: false,
            email: false,
            password: false,
            confirm: false,
        }
    },
    setActualUsers(state, action){
        state.actualUsersOrg = action.payload
    },
    setSearchedUsers(state, action) {
        state.usersSearched = action.payload
    }

  },
});

export const {
    setErrors,
    setErrorsDefault,
    resetUserSlice,
    setActualUsers,
    setSearchedUsers,
} = localSlice.actions;

export default localSlice.reducer;





