import React from "react"
import styles from "./UserRoles.module.css"
import Tooltip from "@mui/material/Tooltip";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Swal from "sweetalert2";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Menu from "@mui/material/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Alert from '@mui/material/Alert';
import { styled } from "@mui/material/styles";
import { useSelector} from "react-redux";

const ListItem = styled("li")(({ theme }) => ({
    listStyle: "none",
}));


export const UserRoles = (props) => {

    const {userEdit, setUserEdit, setEdit, chipDataRoles, setChipDataRoles, users, roles, secondaryColor, primaryColor, createUser, edit} = props
    const currentUser = useSelector((state) => state.gamma.logged_user);
    const handleDeleteRol = (chiptoDelete) => {
        setChipDataRoles((chips) =>
          chips.filter((chip) => chip.key !== chiptoDelete.key)
        );
        let new_roles = [];
        if (userEdit.memberships[0].roles.length >= 2) {
          new_roles = userEdit.memberships[0].roles?.filter(
            (obj) => obj.name !== chiptoDelete.label
          );
        }
        let userUpdate = {
          ...userEdit,
          memberships: [
            {
              ...userEdit.memberships[0],
              roles: new_roles,
            },
          ],
        };
        setUserEdit(userUpdate);
        let newUsers = Array.isArray(users) && users?.filter((user) => user.email !== userEdit.email);
        newUsers.push(userUpdate);
        setEdit(false);
      };
    
      const handleAddRol = (rol) => {
        if (chipDataRoles.length === 0) {
          setChipDataRoles([{ key: 1, label: rol.name }]);
        } else {
          if (chipDataRoles.some((r) => r.label === rol.name)) {
            Swal.fire({
              icon: "error",
              title: "Lo siento...",
              text: "No se puede agregar dos veces el mismo rol",
            });
          } else {
            setChipDataRoles([
              ...chipDataRoles,
              { key: chipDataRoles[chipDataRoles.length - 1].key + 1, label: rol.name },
            ]);
          }
        }
        let userUpdate = {
          ...userEdit,
          memberships: [
            {
              ...userEdit.memberships[0],
              roles: [
                    ...userEdit.memberships[0].roles,
                    {
                      id: rol.id,
                      name: rol.name,
                      description: rol.description,
                    },
                  ]
            },
          ],
        };
        setUserEdit(userUpdate);
        setEdit(false);
      };

    return(
        <div className={styles.container}>
        <div className={styles.rolesSection}>
        <div className={styles.rolIcon}>
          <Tooltip title="Roles">
            <AssignmentIndIcon
              sx={{
                fontSize: 35,
                color: primaryColor,
                transition: "0.5s ease",
              }}
            />
          </Tooltip>
        
          <Typography sx={{fontSize: '25px', fontFamily:'Roboto, sans-serif', fontWeight: 300, textAlign:"center" }} className={styles.titleRoles}>Roles </Typography>
        </div>
          <div className={styles.addRoles}>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <IconButton
                    variant="contained"
                    {...bindTrigger(popupState)}
                  >
                    <Tooltip title="Agregar roles" placement="right">
                      <AddCircleOutlineIcon
                        sx={{
                          fontSize: 30,
                          marginLeft: 3,
                          color: primaryColor,
                          cursor: "pointer",
                          transition: "0.5s ease",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    {console.log("rol de usuario registrado", currentUser.memberships[0].roles[0].name)}
                    { 
                      currentUser.memberships[0].roles[0].name === "Administrador Loteria" ?
                      roles.filter(item => item.name === 'Administrador Loteria' || item.name === 'Usuario Agenciero').map((rol, i) => {
                        return (
                          <div
                            onClick={() => handleAddRol(rol)}
                            key={i}
                          >
                            <MenuItem onClick={popupState.close}>
                              {rol.name === 'Usuario Agenciero' ? 'Titular Agencia' : rol.name}
                            </MenuItem>
                          </div>
                        );
                      }):
                      roles.filter(item => item.name === 'Usuario Agenciero').map((rol, i) => {
                        return (
                          <div
                            onClick={() => handleAddRol(rol)}
                            key={i}
                          >
                            <MenuItem onClick={popupState.close}>
                              {rol.name === 'Usuario Agenciero' ? 'Titular Agencia' : rol.name}
                            </MenuItem>
                          </div>
                        );
                      })

                    }
                  </Menu>
                </>
              )}
            </PopupState>
          </div>
      </div>
      <div className={styles.roles}>
      {chipDataRoles?.length ? (
        chipDataRoles.map((data) => {
          return (
            <ListItem sx={{ m: 1 }} key={data.key}>
              <Tooltip title={data.info} placement="top">
                <Chip
                  label={data.label}
                  onDelete={() => handleDeleteRol(data)}
                  deleteIcon={
                    <DeleteIcon
                      className={styles.deleteRolAndPointIcon}
                    />
                  }
                  sx={{
                    backgroundColor: secondaryColor,
                    width: "100%",
                    height: "40px",
                    color: "white",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "10px"
                  }}
                />
              </Tooltip>
            </ListItem>
          );
        })
      ) : (
        <div>
          <h1 className={styles.noPoints}>- No posee roles -</h1>
          <Alert severity="warning" sx={{fontSize: "100%"}}>{createUser ? "El usuario se creara sin roles asignados." : "El usuario se editara sin roles asignados."}</Alert>
        </div>
      )}
    </div>
    </div>
    )
}
