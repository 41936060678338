// import {useNavigate} from "react-router-dom";
// import {useKeycloak} from "@react-keycloak/web";
// import {roles} from "../config/strings";
// import {
//   getRoles,
//   getAllOrganizations,
//   getloggedUser,
// } from "../api/gamma";
// import {getUiConfig, getLegal, getLegals} from "../api/local";
// import {currentLogo, currentTheme} from "../api/themes/ThemeService"
// import {
//   allRolesUpdate,
//   allOrganizations,
//   loggedUser,
//   setActiveOrganization, appConfigLoader,organizationConfiguration
// } from "../store/slices/gammaSlice";
// import {
//   uiConfigUpdate,
//   legalTermsUpdate,
//   uiLogosUpdate,
//   uiProfilePictureUpdate,
//   uiOrganizationLogo,
//   resetLogos
// } from "../store/slices/localSlice";
//  import {callAgeniesById, alreadyAnalisis } from "../store/slices/my_agencies"
// import {useDispatch, useSelector} from "react-redux";
// import {useEffect} from "react";
// import { getAgenciesRelationed, 
//   // getFavsAgencies 
// } from "../api/agencies/AgenciesService";
// import {getAllConfigurations} from "../api/config/ConfigService";
// import { getOrganizationConfiguration } from "../api/general/generalService";
// export const Preload = () => {
//   const navigate = useNavigate();
//   const {keycloak} = useKeycloak();
//   const currentUser = useSelector((state) => state.gamma.logged_user);
//   const {legalTerms, uiConfig} = useSelector((state) => state.local);
//   const dispatch = useDispatch();

//   dispatch(alreadyAnalisis(true));

//   useEffect(() => {
//     dispatch(resetLogos())
//   }, [dispatch])

//     getUiConfig(keycloak.subject).then((res) => {
//       dispatch(uiConfigUpdate(res.colors));
//       dispatch(uiProfilePictureUpdate(res.profilePicture));
//       dispatch(uiLogosUpdate(res.logos));
//     });
//     getRoles().then((res) => dispatch(allRolesUpdate(res)));
//     getAllOrganizations().then((res) => {
//       dispatch(allOrganizations(res.content));
//     });
//     // Se debe resetear el usuario porque persiste en redux
//     getloggedUser(keycloak.subject)
//     .then((res) => {
//       // console.log("res",res);
//       if(res?.status === "NOT_FOUND"){
//         // TODO: Deberia notificar que el usuario no existe y sacarlo del sistema,  
//         navigate(`../server-error/404`)
//       }else if(res?.status >= 500){
//         // TODO: Deberia pedir que intente dentro de unos momentos y llevarlo al login.
//         navigate(`../server-error/${res.status}`)
//       }else{
//         getAgenciesRelationed(keycloak.subject, res?.memberships[0]?.organization?.id).then(response => {
//           if(response?.content){
//             //const ids = response?.content?.map(id => id * 1)
//             dispatch(loggedUser({...res, memberships: [{...res.memberships[0], agencies: response?.content}]}))
//           }else{
//             dispatch(loggedUser({...res, memberships: [{...res.memberships[0], agencies: []}]}))
//           }
//         }
//         )
//       }
//     })
//     .catch(e =>  {
//       navigate(`../server-error/${e.status}`)
//     })

//   getAllConfigurations()
//     .then((res) => {
//       dispatch(appConfigLoader(res.content))
//     });
    
//     if (currentUser?.memberships?.length) {
//       const userOrganization = currentUser?.memberships[0]?.organization
//       if (keycloak.subject) {
//         const ids = [];
//         currentUser?.memberships[0].agencies?.map((agencie) => ids.push(agencie))
//         dispatch(callAgeniesById(ids))
//       }
//       console.log("currentUser",currentUser);
//       getOrganizationConfiguration(userOrganization?.id)
//         .then((res) => dispatch(organizationConfiguration(res)));
//       dispatch(setActiveOrganization(userOrganization));

//       if (Array.isArray(currentUser?.memberships) && currentUser?.memberships.length != 0) {
//         if (currentUser?.memberships[0]?.roles[0]?.name === roles.administradorLoteria) {
//           /* ES ADMINISTADOR DE LOTERIA */
//           navigate(`../admin/users`)
//         } else if (currentUser?.memberships[0]?.roles[0]?.name === roles.agenciero || currentUser?.memberships[0]?.roles[0]?.name === roles.concentradora || currentUser?.memberships[0]?.roles[0]?.name === roles.empleado) {
//           /* ES AGENCIERO */
//           getLegals(userOrganization?.id)
//             .then(currentLegalTerms => {
//               // TODO: validar que hacer si no hay current Terms
//               if (currentLegalTerms.id) {
//                 getLegal(keycloak.subject, userOrganization?.id, currentLegalTerms.id)
//                   .then(resp => dispatch(legalTermsUpdate(resp)))
//                   .then(() => {
//                     legalTerms.accepted
//                       ? navigate(`../main/agencias`)
//                       : navigate("../terms")
//                   })
//               } else {
//                 /* NO HAY TERMINOS INGRESA DIRECTO */
//                 navigate(`../main/agencias`)
//               }
//             })
//         } else {
//           /* TODO: No tiene Rol hay que poner pantalla de No autorizado con logout automatico  */
//           navigate(`../admin/logout`)
//         }
//       }else{
//         navigate(`../admin/logout`)
//       }
 
  
//       currentTheme(userOrganization?.id)
//         .then(currentSelectedTheme => {
//           const themeColors = {
//             id: currentSelectedTheme.id,
//             title: currentSelectedTheme.displayName,
//             primary: {
//               main: currentSelectedTheme.primary.normal,
//               light: currentSelectedTheme.primary.light,
//               dark: currentSelectedTheme.primary.dark
//             },
//             secondary: {
//               main: currentSelectedTheme.secondary.normal,
//               light: currentSelectedTheme.secondary.light,
//               dark: currentSelectedTheme.secondary.dark
//             },
//             custom: uiConfig.custom.length ? [...uiConfig.custom] : []
//           }
//           dispatch(uiConfigUpdate(themeColors));
//         })
//       currentLogo(userOrganization?.id)
//         .then(currLogo => {
//             dispatch(uiOrganizationLogo(currLogo));
//           }
//         )
//     }
// };


import {useNavigate} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import { useState } from "react";
import {roles} from "../config/strings";
import {
  getRoles,
  getAllOrganizations,
  getloggedUser,
} from "../api/gamma";
import {getUiConfig,getLegal, getLegals} from "../api/local";
import {currentLogo, currentTheme} from "../api/themes/ThemeService"
import {
  allRolesUpdate,
  allOrganizations,
  loggedUser,
  setActiveOrganization, appConfigLoader, allRaffleLotteries, updateTaxCondition, organizationConfiguration,
} from "../store/slices/gammaSlice";
import {
  uiConfigUpdate,
  uiLogosUpdate,
  uiProfilePictureUpdate,
  legalTermsUpdate,
  uiOrganizationLogo,
  resetLogos
} from "../store/slices/localSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import { ComponentError } from "../components/ErrorPages/ComponentsError"
import { getAgenciesRelationed } from "../api/agencies/AgenciesService";
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from "react-i18next";
import {getAllConfigurations} from "../api/config/ConfigService";
import { getOrganizationConfiguration } from "../api/general/generalService";

export const Preload = () => {
  const navigate = useNavigate();
  const {keycloak} = useKeycloak();
  const currentUser = useSelector((state) => state.gamma.logged_user);
  const activeOrganization = useSelector((state) => state.gamma.activeOrganization);
  const {uiConfig,legalTerms} = useSelector((state) => state.local);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [backError, setBackError] = useState({
    error: false
  });
  const [stateLegal, setStateLegal] = useState({});
  const [loadedConfigurations, setLoadedConfigurations] = useState(false);
  const [loadedLoggedUser, setLoadedLoggedUser] = useState(false);
  const [loadedCurrentTheme, setLoadedCurrentTheme] = useState(false);
  const [loadedCurrentLogo, setLoadedCurrentLogo] = useState(false);
  const [loadedOrganizations, setLoadedOrganizations] = useState(false);
  const [loadedRoles, setLoadedRoles] = useState(false);
  const [loadedLegal, setLoadedLegal] = useState(false);
  const [loadedLegals, setLoadedLegals] = useState(false);
  const [loadedAgencies, setLoadedAgencies] = useState(false);
  const [loadedOrganizationConfigurations, setLoadedOrganizationConfigurations] = useState(false);
  useEffect(() => {
    if(uiConfig) {
      dispatch(resetLogos());
      if(uiConfig.profilePicture) {
        dispatch(uiProfilePictureUpdate(uiConfig.profilePicture));
      }
      if(uiConfig.logos) {
        dispatch(uiLogosUpdate(uiConfig.logos));
      }
    }
  }, [dispatch,uiConfig])

  const isErrorResponse = (res) => {
    return res?.status && !(res?.status >= 200 && res?.status < 300);
  }

  const handleLoadingError = (e, message) => {
    setBackError({
      error: true,
      code: message || e?.status || 500
    });
  }

  const allConfigurationsLoaded = () => {
    return loadedConfigurations && loadedLoggedUser && loadedCurrentTheme && loadedCurrentLogo && loadedOrganizations && loadedRoles && loadedLegal && loadedLegals && loadedAgencies && loadedOrganizationConfigurations;
  }

  const isValidMemberships = (memberships) => {
    return  Array.isArray(memberships) && 
            memberships.length > 0 && 
            memberships[0].organization && 
            memberships[0].organization.id &&
            memberships[0].organization.name &&
            Array.isArray(memberships[0].roles) && 
            memberships[0].roles.length > 0;
  }

  useEffect(() => {
    if(!keycloak.subject) {
      /* Usuario no logueado correctamente */
      navigate(`../admin/logout`)
    }
    if(!loadedConfigurations) {
      getAllConfigurations()
        .then((res) => {
          if(isErrorResponse(res)) {
            handleLoadingError(res, t('loading.configurationError'));
          } else {
            dispatch(appConfigLoader(res.content));
            setLoadedConfigurations(true);
            console.log("allConfig Loaded ...", res.content);
          }      
        })
        .catch((e) => {
          handleLoadingError(e, t('loading.configurationError'));
        });
    }
    if(loadedConfigurations && !loadedLoggedUser) {
      getloggedUser(keycloak.subject)
      .then((res) => {
        if(isErrorResponse(res)) {
          handleLoadingError(res, t('loading.loggedUserError'));
        } else {
          res.json()
          .then(res => {
            const responseMembership = Array.isArray(res?.memberships) ? res?.memberships[0] : undefined; 
            const newLoggedUser = {...res, memberships: [{...responseMembership, agencies: null}]};
            if(isValidMemberships(newLoggedUser?.memberships)) {
              const userOrganization = newLoggedUser.memberships[0].organization;
              dispatch(setActiveOrganization(userOrganization));
              dispatch(loggedUser(newLoggedUser));
              setLoadedLoggedUser(true);
            } else {
              navigate(`../admin/logout`);
            }
          })
          .catch(e => {
            console.log(e);
            handleLoadingError(e, t('loading.loggedUserError'));
          })
        }
      })
      .catch((e) => {
        handleLoadingError(e, t('loading.loggedUserError'));
      });
    }
    if(loadedConfigurations && loadedLoggedUser && !loadedCurrentTheme) {
      currentTheme(activeOrganization.id)
        .then(currentSelectedTheme => {
          if(isErrorResponse(currentSelectedTheme)) {
            handleLoadingError(currentSelectedTheme, t('loading.themeError'));
          } else {
            const themeColors = {
              id: currentSelectedTheme.id,
              title: currentSelectedTheme.displayName,
              primary: {
                main: currentSelectedTheme.primary.normal,
                light: currentSelectedTheme.primary.light,
                dark: currentSelectedTheme.primary.dark
              },
              secondary: {
                main: currentSelectedTheme.secondary.normal,
                light: currentSelectedTheme.secondary.light,
                dark: currentSelectedTheme.secondary.dark
              },
              custom: (uiConfig?.custom ? uiConfig.custom : currentSelectedTheme.custom) || {}
            }
            dispatch(uiConfigUpdate(themeColors));
            setLoadedCurrentTheme(true);
          }
        }).catch((e) => {
          /* Ver si incluir esto o mandar error cuando no se pueden cargar los temas */
          /*
          dispatch(uiConfigUpdate(defaultThemeColors));
          */
          handleLoadingError(e, t('loading.themeError'));
        });
    }
    if(loadedConfigurations && loadedLoggedUser && !loadedCurrentLogo) {
      currentLogo(activeOrganization.id)
      .then(currLogo => {
        if(isErrorResponse(currLogo)) {
          handleLoadingError(currLogo, t('loading.logoError'));
        } else {
          dispatch(uiOrganizationLogo(currLogo));
          setLoadedCurrentLogo(true);
        }
      })
      .catch((e) => {
        handleLoadingError(e, t('loading.logoError'));
      });
    }
    if(loadedConfigurations && loadedLoggedUser && !loadedOrganizations) {
      getAllOrganizations()
        .then((res) => {
          if(isErrorResponse(res)) {
            handleLoadingError(res, t('loading.organizationsError'));
          } else {
            dispatch(allOrganizations(res.content));
            setLoadedOrganizations(true);
          }
        })
        .catch((e) => {
          handleLoadingError(e, t('loading.organizationsError'));
        });
    }
    if (loadedConfigurations && loadedLoggedUser && !loadedRoles) {
      getRoles()
      .then(res => {
        if(isErrorResponse(res)) {
          handleLoadingError(res, t('loading.rolesError'));
        } else {
          dispatch(allRolesUpdate(res))
          setLoadedRoles(true);
        }
      })
      .catch((e) => {
        handleLoadingError(e, t('loading.rolesError'));
      });
    }
    if (loadedConfigurations && loadedLoggedUser && !loadedOrganizationConfigurations) {
      const userOrganization = currentUser?.memberships[0]?.organization;
      getOrganizationConfiguration(userOrganization?.id)
        .then((res) => { 
          if(isErrorResponse(res)) {
            handleLoadingError(res, t('loading.organizationConfigurationError'));
          } else {
            dispatch(organizationConfiguration(res));
            setLoadedOrganizationConfigurations(true);
          }
        })
        .catch((e) => {
          handleLoadingError(e, t('loading.organizationConfigurationError'));
        });
    }
    if (loadedConfigurations && loadedLoggedUser && !loadedLegals) {
      const userOrganization = currentUser?.memberships[0]?.organization;
      getLegals(userOrganization?.id)
      .then(currentLegalTerms => {
        // TODO: validar que hacer si no hay current Terms
        setStateLegal(currentLegalTerms);
        setLoadedLegals(true);
      })
      .catch((e) => {
        handleLoadingError(e, t('loading.legalsError'));
      });
    }
    if (loadedConfigurations && loadedLoggedUser && loadedLegals && !loadedLegal) {
      const userOrganization = currentUser?.memberships[0]?.organization;
      if (stateLegal?.id) {
        getLegal(keycloak.subject, userOrganization?.id, stateLegal.id)
          .then(resp => {
            dispatch(legalTermsUpdate(resp))
            setLoadedLegal(true)})
          .catch((e) => {
            handleLoadingError(e, t('loading.legalError'));
          });  
      }
      else{
        setLoadedLegal(true);
      }
    }
    if (loadedConfigurations && loadedLoggedUser & !loadedAgencies) {
      getAgenciesRelationed(keycloak.subject, currentUser?.memberships[0]?.organization?.id)
        .then(response => {
          if (response?.content) {
            //const ids = response?.content?.map(id => id * 1)
            dispatch(loggedUser({ ...currentUser, memberships: [{ ...currentUser.memberships[0], agencies: response?.content }] }))
          } else {
            dispatch(loggedUser({ ...currentUser, memberships: [{ ...currentUser.memberships[0], agencies: [] }] }))
          }
          setLoadedAgencies(true);
        })
        .catch((e) => {
          handleLoadingError(e, t('loading.agenciesError'));
        });
    }

    if(allConfigurationsLoaded()) {
      if (Array.isArray(currentUser?.memberships) && currentUser?.memberships[0]?.roles[0]?.name === roles.administradorLoteria) {
        /* ES ADMINISTADOR DE LOTERIA */
        navigate(`../admin/users`)
      } else if (currentUser?.memberships[0]?.roles[0]?.name === roles.agenciero || currentUser?.memberships[0]?.roles[0]?.name === roles.concentradora || currentUser?.memberships[0]?.roles[0]?.name === roles.empleado) {
        /* ES AGENCIERO */
        if (stateLegal?.id) {
          legalTerms.accepted
            ? navigate(`../main/agencias`)
            : navigate("../terms")
        }
        else {
          /* NO HAY TERMINOS INGRESA DIRECTO */
          navigate(`../main/agencias`)
        }
      } else {
        /* TODO: No tiene Rol hay que poner pantalla de No autorizado con logout automatico  */
        navigate(`../admin/logout`)
    }
    }
  }, [loadedConfigurations, loadedLoggedUser, loadedCurrentTheme, loadedCurrentLogo, loadedOrganizations, loadedRoles, loadedLegals, loadedLegal, loadedAgencies]);

  const styles = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const stylesH4 = {
    fontSize: '1.8rem',
    fontWeight: '300',
    fontFamily: 'Roboto, sans-serif'
  }

  return (
    backError?.error ? <ComponentError code={backError?.code}/> :
      <div style={styles}>
        <CircularProgress size={'2rem'}/>
        <h4 style={stylesH4}>{t('validateData')}</h4>
      </div>
  )
  
};

