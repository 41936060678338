// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServerError_container__j4uoz {\n    width: 100%;\n    height: 86vh;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.ServerError_title__KFjPX{\n    font-family: sans-serif;\n    font-weight: 500;\n    font-size: 3em;\n}\n\n.ServerError_title500__O6oZJ{\n    font-family: sans-serif;\n    font-weight: 500;\n    text-align: center;\n    font-size: 3em;\n    margin-left: 30px;\n    margin-right: 30px;\n}\n\n.ServerError_container_error__1gjrX{\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.ServerError_errorImage__tUjvL {\n    width: 20vw;\n    margin-top: 10vw;\n}\n\n.ServerError_errorCode__cuesJ {\n    font-size: 1em;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/ErrorPages/ServerError/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".container {\n    width: 100%;\n    height: 86vh;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.title{\n    font-family: sans-serif;\n    font-weight: 500;\n    font-size: 3em;\n}\n\n.title500{\n    font-family: sans-serif;\n    font-weight: 500;\n    text-align: center;\n    font-size: 3em;\n    margin-left: 30px;\n    margin-right: 30px;\n}\n\n.container_error{\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.errorImage {\n    width: 20vw;\n    margin-top: 10vw;\n}\n\n.errorCode {\n    font-size: 1em;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ServerError_container__j4uoz",
	"title": "ServerError_title__KFjPX",
	"title500": "ServerError_title500__O6oZJ",
	"container_error": "ServerError_container_error__1gjrX",
	"errorImage": "ServerError_errorImage__tUjvL",
	"errorCode": "ServerError_errorCode__cuesJ"
};
export default ___CSS_LOADER_EXPORT___;
