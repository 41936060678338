export const byNumberAndSubNumber = (numberStrA, numberStrB) => {
    const numberA = extractNumberAndSubNumber(numberStrA);
    const numberB = extractNumberAndSubNumber(numberStrB);
    return numberA.number === numberB.number 
        ? numberA.subNumber - numberB.subNumber 
        : numberA.number - numberB.number;
}

export const extractNumberAndSubNumber = (numberStr) => {
    const parts = numberStr.split('-');
    return parts && parts.length > 0 ? {
        number: parseInt(parts[0]) || 0,
        subNumber: parts.length > 1 ? parseInt(parts[1]) : -1
    } : null;
}

// const ordenarMultiNivel = (ranking) => {
//     return ranking.sort((a, b) => 
//       a.agencia === b.agencia ? 
//         a.subAgencia === b.subAgencia ?
//           a.maquina === b.maquina ? a.juego - b.juego
//         : a.maquina - b.maquina
//       : a.subAgencia - b.subAgencia
//     : a.agencia - b.agencia
//     );
//   }


//   export const byNumberAndSubNumber = (numberStrA, numberStrB) => {
//     const numberA = extractNumberAndSubNumber(numberStrA);
//     const numberB = extractNumberAndSubNumber(numberStrB);
//     return numberA === numberA ?
//         numberB === numberB ?
//         numberStrA.maquina - numberStrB.maquina
//             : numberB - numberB
//         : numberA - numberA
// }