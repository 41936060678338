import zoneData from "../../mock-data/zoneData";
import uiConfig from "../../mock-data/uiConfig"
import favAgencies from "../../mock-data/favAgencies"
import {MAIN_URL} from "../../config/strings"
import allTerms from "../../mock-data/terms/allTerms.json"
import orgTerms from "../../mock-data/terms/orgTerms.json"

export const getZoneData = (userId, location) => {
    return fetch(`${MAIN_URL.localPath}/zonedata/${userId}?location=${location}`)
          .then((response) => response.json())
          .then((json) => {return json})
          .catch((err) => console.log(err));
  };

export const getUiConfig = (userId) => {
    return fetch(`${MAIN_URL.localPath}/uiconfig/${userId}`)
            .then((response) => response.json())
            .then((json) => {return json})
            .catch((err) => console.log(err));
};

export const getLegals = (organizationId) => {
    return fetch(`/api-terms/terms/getCurrentTerms/${organizationId}`)
            .then((response) => response.json())
            .then((json) => {return json})
            .catch((err) => console.log(err));
};

export const getLegal = (userId, orgId, termsId) => {
    return fetch(`/api-terms/terms/agreement?userUUID=${userId}&termsConditionID=${termsId}&organizationId=${orgId}`)
            .then((response) => response.json())
            .then((json) => {return json})
            .catch((err) => console.log(err));
};

export const updateLegal = (payload) => {
    return fetch(`/api-terms/terms/agreement`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json", "accept": "application/json" }
            })
            .then((json) => {return json})
            .catch((err) => console.log(err)); 
}

export const getFavAgencies = (userId) => {
    return fetch(`${MAIN_URL.localPath}/favAgencies/${userId}`)
            .then((response) => response.json())
            .then((json) => {return json})
            .catch((err) => console.log(err));
};
