import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//-Material
import {
  Table, TableContainer, TableHead, TableCell, Stack, IconButton, Box,
  TableRow, TableBody, Checkbox, Grid, MenuItem, Button, Select, CircularProgress, TextField
} from "@mui/material";

import {useNavigate} from "react-router-dom"

import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from "@mui/icons-material/Edit";
import Pagination from '@mui/material/Pagination';
import { Term_and_ConditionTotalElementsSubtract} from "../../../store/slices/paginationSlice"
import Tooltip from "@mui/material/Tooltip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import HandleRequests from "../../Shared/handleRequests/HandleRequests";
import { getAllTermsAndConditions, deleteTermsAndCondition } from "../../../api/terms/TermsService";
import {termsAndConditionsUpdate} from "../../../store/slices/localSlice"
import { configDate } from '../../../helpers/convertDate';
import { formatJava, formatDateTimeTermsCond } from "../../../config/strings";

export const TermsAndConditions = () => {
  const [primaryColor, setPrimaryColor] = useState("");
  const [terms, setTerms] = useState([])
  const {terms_And_Conditions} = useSelector((state) => state.local);
  const { textColor } = useSelector((state) => state.local);
  const backErrors = useSelector(state => state.errors.termsAndConditionError)
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const {formatLocalDate,formatLocalDateTime, } = configDate();
  let navigate = useNavigate();

  const columns = [
    {id: "title", label: t("title"), align: "left"},
    {id: "creationDate", label: t("creationDate"), align: "left"},
    {id: "effectiveDateFrom", label: t("effectiveDate"), align: "left"},
    {id: "effectiveDateTo", label: t("endOfEffectiveDate"), align: "left",},
    {id: "omitAmount", label:t("omitAmount"), align: "left",},
    {id: "published", label: t("published"), align: "left",},
    {id: "edit", label: t("edit"), align: "left",},
    {id: "delete", label: t("delete"), align: "left",}
  ];

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [customPage, setCustomPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllTermsAndConditions(page, perPage, dispatch)
      .then(res => {
        setTerms(res.content);
        setTotalPages(res.totalPages);
        dispatch(termsAndConditionsUpdate(res.content))
      })
      .then(() => setLoading(false));
  }, [page, perPage]);

  const handlePagination = (e, v) => {
    setPage(v);
  }

  const handleDelete = (TermsAndConditions) =>{
    if (TermsAndConditions.published === false){
    Swal.fire({
      title: t(`areYouSureYouWantToDeleteTheTermsAndConditionsDocument?`),
      text: t(`ifYouDeleteA,TheChangeCannotBeReversed.`),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("delete"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTermsAndCondition(TermsAndConditions.id)
          .then(response => {       
            if (response.status === 204) {
              Term_and_ConditionTotalElementsSubtract();
              setPage(1);
              navigate("./")
              Swal.fire(t("deleted"), t("keyRemovedSuccessfully"), "success");
            }
          })
          .then(() => {
            setLoading(true);
            getAllTermsAndConditions(page, perPage, dispatch)
              .then(res => {
                setTerms(res.content);
                setTotalPages(res.totalPages);
              })
              .then(() => setLoading(false));
            navigate("./")
          })
      }else{
        setLoading(false);
      }
    })} else {
      Swal.fire({
        icon: "error",
        title: t("NoDeleteTerm"),
      })
      setLoading(false)
    }
  }

  const handleChangePerPage = (e) => {
    setPerPage(e * 1)
  }

  const routeChange = (id) => {
    let path = `./${id}`;
    navigate(path);
  }
  const newTerm = () => {
    let path = `./add`;
    navigate(path);
  }
  // const titlesFilter = columns?.filter(column => column.id !== "published");

  function formatDate(string) {
    return new Date(string).toLocaleDateString();
  }

  return (
    <div className={styles.container}>
      <HandleRequests error={backErrors}>
      <Grid sx={{width: "100%", display: "flex", justifyContent: "center", marginTop: 2}}>
      </Grid>
      <Grid container className={styles.termsContainer}>
        <Grid item className={styles.termsTitle}>
          <ArticleIcon color={"primary"} sx={{
            fontSize: '1.8rem',
            color: primaryColor,
          }}/>
          <h1 className={styles.title}>{t('termsAndConditions')}</h1>
          <div className={styles.createButton} onClick={newTerm}> 
          <Box sx={{backgroundColor : "primary.main", color: textColor, with:"100%", height:  "100%", borderRadius: 2}} className={styles.btn}>
            {t("add")}
          </Box>
          </div> 
        </Grid>

        <Grid item className={styles.tableContainer}>
          {
            !loading ? <>

            <TableContainer color={"primary"} className={styles.table}>
              <Table sx={{borderstyle: "solid",}}>
                <TableHead>
                  <TableRow color={primaryColor} sx={{ backgroundColor: 'primary.main', color: textColor}}>
                    {columns.map((column) => (
                      <TableCell 
                        key={column.id}
                        align={column.align}
                        className={styles.tableCellHeader}
                        sx={{fontWeight: 500, fontFamily: 'Roboto, sans-Serif', minWidth: column.minWidth, backgroundColor: primaryColor, color: textColor }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tableBody}>
                  {
                    terms?.length ? terms?.map((termsItem, i) => (
                      <TableRow key={i} sx={{"&:hover": {backgroundColor: "secondary.light", cursor: "pointer"}}}>
                        <TableCell sx={{ backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12" }} className={styles.tableCell}>
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItem: 'center'}}>
                              <ArticleIcon sx={{color: 'primary.main'}} />
                              <p style={{margin: 0, marginLeft: '5px'}}>{termsItem.title}</p>
                            </Box>
                        </TableCell>
                        <TableCell align='left'  sx={{ backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12" }}className={styles.tableCell}>
                          {formatLocalDate(termsItem.creationDate, formatDateTimeTermsCond)}
                        </TableCell>
                        <TableCell align='left'  sx={{ backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12" }} className={styles.tableCell}>
                          {formatLocalDate(termsItem.validFrom,formatDateTimeTermsCond)}
                        </TableCell>
                        <TableCell align='left'  sx={{ backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12" }} className={styles.tableCell}>
                          {formatLocalDate(termsItem.validTo,formatDateTimeTermsCond)}
                        </TableCell>
                        <TableCell align='left'  sx={{ backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12" }} className={styles.tableCell}>
                          {termsItem.omitAmount ? termsItem.omitAmount : 0}
                        </TableCell>
                        <TableCell sx={{ backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12" }} className={styles.tableCell}>
                          <Checkbox checked={termsItem.published} />
                        </TableCell>
                        <TableCell align='center'  sx={{ backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12" }} className={styles.tableCell}>
                          <Tooltip title="Editar configuración">
                            <IconButton sx={{margin: 0.15, padding: 0.15}} variant="outlined" onClick={() => routeChange(termsItem.id)}>
                              <EditIcon color={"primary"} sx={{fontSize: '1.8rem', cursor: "pointer", color: primaryColor}} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align='center'  sx={{ backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12" }} className={styles.tableCell}>
                          <Tooltip title="Eliminar Documento">
                            <IconButton sx={{margin: 0.15, padding: 0.15}} variant="outlined" onClick={() => handleDelete(termsItem)}>
                              <DeleteForeverIcon color={"primary"} sx={{ fontSize: '1.8rem', cursor: "pointer", color: primaryColor }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )):<> </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            </> :
              <Grid item xs={11} className={styles.loader}>
                <CircularProgress size={'3rem'}/>
              </Grid>
          }
        </Grid>
        <Grid item container xs={7} sx={{justifyContent: 'space-evenly', alignItems: 'center', mb: 5}}>
          <Select value={perPage} onChange={(e) => handleChangePerPage(e.target.value)}>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
          </Select>  
          <Stack spacing={2} sx={{ margin: 4 }}>
            <Pagination
              showFirstButton 
              showLastButton
              count={totalPages}
              page={page}
              onChange={handlePagination}
              color={"secondary"}
            />
          </Stack>
          <form style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '120px'}} onSubmit={e => e.preventDefault()}>
            <TextField type={'number'} value={customPage} onChange={(e) => setCustomPage(e.target.value)} sx={{width: '100%', mr: '5px'}}/>
            <Button variant='contained' onClick={() => {
              if( 0 <= customPage && customPage <= totalPages){
                setPage(customPage * 1);
              }else{
                setCustomPage(page);
              }
            }}>Ir</Button>
          </form>
        </Grid>
      </Grid>
      </HandleRequests>
    </div>
  );
};
