export const getOnLine = async (
  organizacionId,
  desde,
  hasta,
  agencia,
  subagencia
) => {
  try {
    const response = await fetch(
      `/saaServices/saaServices/services/abaco/detalleMovimientosOnline?organizacionId=${organizacionId}&fechadesde=${desde}&fechahasta=${hasta}&agencia=${agencia}&subagencia=${subagencia}`,
      /*{
        method: "GET",
        headers: {
          Accept: "application/json",

          "Access-Control-Allow-Origin": "*",

          "Content-Type": "application/json",
        },
        mode: "no-cors",
      }*/
    );
    const jsonRes = await response.json();
    console.log(jsonRes);
    return jsonRes;
  } catch (e) {
    console.log(e);
  }
};
