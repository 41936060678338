import { Outlet, Link } from "react-router-dom";
import s from "./NavBar.module.css";
import Grid from "@mui/material/Grid";
import { Header } from "../../Header";
import {
  List,
  ListItem,
  ListItemButton,
  Avatar,
  ListItemText,
  Divider,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import abacoLogo from "../../../assets/abaco-logo-b.png";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import { setActiveOrganization } from "../../../store/slices/gammaSlice";
import { useNavigate } from "react-router-dom";
import { roles } from "../../../config/strings";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import StoreIcon from "@mui/icons-material/Store";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Book from "@mui/icons-material/Book";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StarIcon from "@mui/icons-material/Star";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import HelpIcon from "@mui/icons-material/Help";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { HeaderMobile } from "../../Header/HeaderMobile";
import userImage from "../../../assets/blank-user.jpg";

export const NavBar = () => {
  //TODO: unificar navbar y navbaradmin en uno solo
  const [userName, setUserName] = useState("");
  const [openNav, setOpenNav] = useState(
    window.innerWidth < 1000 ? false : true
  );
  const [selected, setSelected] = useState({});
  const { textColor } = useSelector((state) => state.local);
  const { location } = useSelector((state) => state.local);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const superusuario = keycloak.realmAccess.roles.includes(roles.superusuario);
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult
  );
  const currentUser = useSelector((state) => state.gamma.logged_user);
  const [selectedLotery, setSelectedLotery] = useState(
    currentUser?.memberships[0].organization.name
  );
  const { t } = useTranslation();
  const myAgencies = useSelector((state) => state.myAgencies);
  let navigate = useNavigate();
  useEffect(() => {
    keycloak.loadUserInfo().then((res) => setUserName(res.name));
  }, [keycloak, setUserName]);

  const handleChange = (e) => {
    setSelectedLotery(e.target.value);
    const selectedOrg = currentUser.memberships.find(
      (membership) => membership.organization.name === e.target.value
    );
    dispatch(setActiveOrganization(selectedOrg.organization));
  };
  useEffect(() => {
    setSelected(location);
  }, [location]);

  const urlAgencie = (page) => {
    if (
      typeof agencieSelected === "object" &&
      Object.keys(agencieSelected).length !== 0
    ) {
      if (agencieSelected.agencyLevel === "CONCENTRATOR") {
        let res = `${page}/${agencieSelected.number}`;
        return res;
      } else {
        let res = `${page}/${agencieSelected.number}-${
          agencieSelected.subNumber || 0
        }`;
        return res;
      }
    } else {
      return `${page}`;
    }
  };

  const logOut = () => {
    let path = `logout`;
    Swal.fire({
      title: t("logoutDialog"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(path);
      }
    });
  };

  const text = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.85em",
    fontWeight: "800",
  };

  const openNavMenu = () => {
    setOpenNav(!openNav);
  };

  return (
    <Grid container className={s.container}>
      <Grid
        item
        xs={openNav ? 12 : 5}
        className={`${s.navbar} ${openNav ? s.open : null}`}
        sx={{ bgcolor: "primary.main" }}
      >
        <div className={s.navContent}>
          <div className={s.navHeader}>
            <img className={s.abaco_logo} src={abacoLogo} alt="Logo Abaco" />
            <div className={s.profile}>
              <div className={s.avatar}>
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  alt="Profile picture"
                  src={userImage}
                  className={s.profilepic}
                ></Avatar>
              </div>
              <h3 className={s.username} style={{ color: textColor }}>
                {userName}
              </h3>
            </div>
            <div className={s.selector}>
              <FormControl fullWidth sx={{ color: textColor }}>
                <InputLabel
                  id="select-label"
                  sx={{ color: textColor }}
                ></InputLabel>
                {currentUser?.memberships?.lenght > 1 ? (
                  <Select
                    labelId="select-label"
                    id="select"
                    sx={{
                      color: textColor,
                      borderColor: "black",
                      ":hover": `borderColor: black`,
                      height: "55px",
                      fontSize: "12px",
                    }}
                    value={selectedLotery}
                    onChange={handleChange}
                  >
                    {superusuario ? (
                      currentUser?.memberships?.map((item, i) => (
                        <MenuItem key={i} value={item.organization.name}>
                          {item.organization.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        value={currentUser?.memberships[0]?.organization.name}
                      >
                        {currentUser?.memberships[0].organization.name}
                      </MenuItem>
                    )}
                  </Select>
                ) : (
                  <h3>{currentUser?.memberships[0]?.organization.name}</h3>
                )}
              </FormControl>
            </div>
          </div>

          <Divider />

          <nav aria-label="navigation">
            <List>
              <ListItem
                className={s.itemContainer}
                onClick={() => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ agencias: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${
                    selected.agencias ? s.selectedItem : null
                  }`}
                  to={`agencias`}
                >
                  <ListItemButton className={s.itemButton}>
                    <StoreIcon sx={{ color: "secondary.main", mr: 1 }} />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("salePoint")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              {/*myAgencies.user_agencies
                .map((ag) => ag.subNumber)
                .reduce((acc, b) => b === null || acc, false) && ( )*/}
              <ListItem
                className={s.itemContainer}
                onClick={() => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ cuentacorriente: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${
                    selected.cuentacorriente ? s.selectedItem : null
                  }`}
                  to={urlAgencie("cuenta-corriente")}
                >
                  <ListItemButton className={s.itemButton}>
                    <AttachMoneyIcon sx={{ color: "secondary.main", mr: 1 }} />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("currentAccount")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>

              <ListItem
                className={s.itemContainer}
                onClick={() => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ premios: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${
                    selected.premios ? s.selectedItem : null
                  }`}
                  to={urlAgencie("premios")}
                >
                  <ListItemButton className={s.itemButton}>
                    <WorkspacePremiumOutlinedIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("prizes")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                className={s.itemContainer}
                onClick={() => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ controlcaja: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${
                    selected.controlcaja ? s.selectedItem : null
                  }`}
                  to={urlAgencie("control-caja")}
                >
                  <ListItemButton className={s.itemButton}>
                    <AccountBalanceWalletIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("cashControl")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                className={s.itemContainer}
                onClick={() => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ online: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${
                    selected.online ? s.selectedItem : null
                  }`}
                  to={`on-line`}
                >
                  <ListItemButton className={s.itemButton}>
                    <OnlinePredictionIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("onLine")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                className={s.itemContainer}
                onClick={() => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ mercadoPago: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${
                    selected.mercadoPago ? s.selectedItem : null
                  }`}
                  to={`mercado-pago`}
                >
                  <ListItemButton className={s.itemButton}>
                    <CreditScoreIcon sx={{ color: "secondary.main", mr: 1 }} />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("Mercado Pago")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              {myAgencies?.user_agencies?.some(
                (elem) => elem.agencyLevel === "CONCENTRATOR"
              ) && (
                <ListItem
                  className={s.itemContainer}
                  onClick={() => {
                    window.innerWidth < 1000 && setOpenNav(false);
                    setSelected({ ranking: true });
                  }}
                >
                  <Link
                    className={`${s.listItem} ${
                      selected.ranking ? s.selectedItem : null
                    }`}
                    to={`ranking`}
                  >
                    <ListItemButton className={s.itemButton}>
                      <StarIcon sx={{ color: "secondary.main", mr: 1 }} />
                      <ListItemText
                        sx={{ color: textColor }}
                        primaryTypographyProps={{ style: text }}
                        primary={t("ranking")}
                        className={s.itemText}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              )}
              <ListItem
                className={s.itemContainer}
                onClick={() => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ manualdeuso: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${
                    selected.manualdeuso ? s.selectedItem : null
                  }`}
                  to={`manual-de-uso`}
                >
                  <ListItemButton className={s.itemButton}>
                    <Book sx={{ color: "secondary.main", mr: 1 }} />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("Manual de uso")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>

              
              {/* <ListItem className={s.itemContainer} onClick={() => {
              window.innerWidth < 1000 && setOpenNav(false);
              setSelected({docs: true});
            }}>
              <Link className={`${s.listItem} ${selected.docs ? s.selectedItem : null}`} to={`documentos`}>
                <ListItemButton className={s.itemButton}>
                    <ArticleIcon sx={{color:'secondary.main', mr: 1}}/>
                    <ListItemText sx={{color: textColor}} primaryTypographyProps={{ style: text }} primary={t('myDocuments')} className={s.itemText}/>
                </ListItemButton>
              </Link>
            </ListItem> */}
              {/* <ListItem className={s.itemContainer} onClick={() => {
              window.innerWidth < 1000 && setOpenNav(false);
              setSelected({users: true});
            }}>
              <Link className={`${s.listItem} ${selected.users ? s.selectedItem : null}`} to={`users`}>
                <ListItemButton className={s.itemButton}>
                  <GroupIcon sx={{color:'secondary.main', mr: 1}}/>
                  <ListItemText sx={{color: textColor}} primaryTypographyProps={{ style: text }} primary={t('users')} className={s.itemText}/>
                </ListItemButton>
              </Link>
            </ListItem> */}
              {/* <ListItem className={s.itemContainer}>
                <ListItemButton className={s.itemButton}>
                    <NotificationsIcon sx={{color:'secondary.main', mr: 1}} className={s.itemIcon}/>
                    <ListItemText sx={{color: textColor}} primaryTypographyProps={{ style: text }} primary={t('notifications')} className={s.itemText}/>
                </ListItemButton>
            </ListItem>
            <ListItem className={s.itemContainer} onClick={() => {
              window.innerWidth < 1000 && setOpenNav(false);
              setSelected({help: true});
            }}>
              <Link className={`${s.listItem} ${selected.help ? s.selectedItem : null}`} to={`ayuda`}>
                <ListItemButton className={s.itemButton}>
                    <HelpIcon sx={{color:'secondary.main', mr: 1}}/>
                    <ListItemText sx={{color: textColor}} primaryTypographyProps={{ style: text }} primary={t('help')} className={s.itemText}/>
                </ListItemButton>
              </Link>
            </ListItem> */}
              <ListItem className={s.itemContainer}>
                <div className={s.listItem}>
                  <ListItemButton onClick={logOut} className={s.itemButton}>
                    <LogoutIcon sx={{ color: "secondary.main", mr: 1 }} />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("logOut")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </div>
              </ListItem>
            </List>
          </nav>
        </div>

        <div className={s.arrowNav}>
          <div className={s.arrowIcon} onClick={openNavMenu}>
            <ArrowBackIosIcon sx={{ fontSize: 50, color: "secondary.main" }} />
          </div>
        </div>
      </Grid>

      <div className={`${s.content} ${openNav ? s.openContent : null}`}>
        <div className={s.headerPos}>
          <div className={s.mobileHeader}>
            <HeaderMobile openNav={openNavMenu} />
          </div>
          <div className={s.webHeader}>
            <Header page="control-caja" />
          </div>
        </div>
        <div className={s.outletPos}>
          <Outlet />
        </div>
      </div>
    </Grid>
  );
};
