// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Organizations_container__vseap {\n    width: 100%;\n    height: 86vh;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.Organizations_title__TYjRb{\n    font-family: sans-serif;\n    font-weight: 500;\n    font-size: 3em;\n}\n\n.Organizations_container_terminos__07S2O{\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.Organizations_selectOrganizationImage__MiBTO {\n    width: 40vw;\n    margin-top: 5vw;\n  }", "",{"version":3,"sources":["webpack://./src/components/Pages/Organizations/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,eAAe;EACjB","sourcesContent":[".container {\n    width: 100%;\n    height: 86vh;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.title{\n    font-family: sans-serif;\n    font-weight: 500;\n    font-size: 3em;\n}\n\n.container_terminos{\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.selectOrganizationImage {\n    width: 40vw;\n    margin-top: 5vw;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Organizations_container__vseap",
	"title": "Organizations_title__TYjRb",
	"container_terminos": "Organizations_container_terminos__07S2O",
	"selectOrganizationImage": "Organizations_selectOrganizationImage__MiBTO"
};
export default ___CSS_LOADER_EXPORT___;
