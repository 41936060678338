import { useSelector } from "react-redux";

export const ColorPalette = () => {
  const { uiConfig } = useSelector((state) => state.local);
  const colorPalette = {
    palette: {
      primary: {
        main: uiConfig.primary.main,
        light: uiConfig.primary.light,
        dark: uiConfig.primary.dark,
      },
      secondary: {
        main: uiConfig.secondary.main,
        light: uiConfig.secondary.light,
        dark: uiConfig.secondary.dark,
      },
    },
  };
  return colorPalette;
};
