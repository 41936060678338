import { useTranslation } from "react-i18next";


const Column = () => {
    const { t } = useTranslation();

    const allConcentratorsColumns = [
        { id: "number", label: t("number"), align: "center" },
        { id: "name", label: t("name"), align: "center" },
        { id: "agencyKind", label: t("type"), align: "center" },
        { id: "status", label: t("state"), align: "center" }
    ];
    
    const selecterConcentratorColumns = [
        { id: "name", label: t("contact"), align: "center" },
        { id: "street", label: t("address"), align: "center" },
        { id: "number", label: t("number"), align: "center" },
        { id: "zipcode", label: t("zipcode"), align: "center" },
        {},
        { id: "addRelation", label: t("addRelationship"), align: "center" },
        { id: "edit", label: t("edit"), align: "center"} 
    ];
    
    const subAgenciesColumns = [
        { id: "number", label: t("number"), align: "center" },
        { id: "name", label: t("name"), align: "center" },
        { id: "agencyKind", label: t("type"), align: "center" },
        {},
        { id: "status", label: t("state"), align: "center" },
        {},
        { id: "agencyLevel", label: t("level"), align: "center"},
        { id: "edit", label: t("edit"), align: "center"} 
        
    ];
    
    const relationsShipsColumns = [
        { id: "name", label: t("nameAndSurname"), align: "center" },
        { id: "mail", label: t("mail"), align: "center", },
        { id: "phone", label: t("Telefono"), align: "center", },
        { id: "relationshipKind", label: t("position"), align: "center", },
        { id: "delete", label: t("removeRelations"), align: "center"}
    ];
    
    const selectedSubAgencieColumns = [
        { id: "street", label: t("address"), align: "center" },
        { id: "number", label: t("number"), align: "center" },
        { id: "zipcode", label: t("zipcode"), align: "center" },
        { id: "addRelation", label: t("addRelationship"), align: "center" },
        { id: "edit", label: t("edit"), align: "center"} 
    ];
    
    const extraInfoColumns = [
        { id: "phone", label: t("phone"), align: "center"},
        { id: "documentKind", label: t("documentKind"), align: "center" },
        { id: "documentNumber", label: t("document"), align: "center"},
        { id: "personKind", label: t("personKind"), align: "center" }
    ];

    return {allConcentratorsColumns, selecterConcentratorColumns, subAgenciesColumns, relationsShipsColumns, selectedSubAgencieColumns, extraInfoColumns}
}    

export default Column