import React, { useEffect, useState } from "react";
import s from "./Apuestas.module.css";
import Grid from "@mui/material/Grid";

import { Typography, Modal, Box } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseIcon from "@mui/icons-material/Close";

//translation
import { useTranslation } from "react-i18next";
import { Currency } from "../../../../../helpers/currencyConvert"; //currencyConvert
import { getcoupons } from "../../../../../api/saaservices/prize";
import CircularProgress from "@mui/material/CircularProgress";
import { formatArgTime } from "../../../../../config/strings";
import { configDate } from "../../../../../helpers/convertDate";//convertDate
const ModalApuestas = ({ openDetails, handleDetails, data }) => {
  const [coupons, setCoupons] = useState();
  const [loading, setLoading] = useState(false);
  const { formatLocalDate, formatLocalDateTime } = configDate();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getcoupons(data?.numeroApuesta)
      .then((res) => {
        setCoupons(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Modal open={openDetails}>
      <Box className={s.boxModal} sx={{ bgcolor: "#EEEEEE" }}>
        <div className={s.exit}>
          <div className={s.titleModal}>
            <InfoRoundedIcon
              sx={{ color: "rgb(91, 150, 233)" }}
              className={s.icon}
            />
            <p>{`${t("bet")} ${data.numeroApuesta}`}</p>
          </div>
          <CloseIcon
            onClick={handleDetails}
            className={s.exitIcon}
            sx={{ color: "primary.dark" }}
          />
        </div>
        {loading ? (
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
            }}
          >
            {" "}
            <CircularProgress sx={{ fontSize: "3rem" }} />{" "}
          </Grid>
        ) : (
          <div className={s.info}>
            <div className={s.infoNumbers}>
              <div className={s.row}>
                <p>{t("machineNumber")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.maquina || "-"}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("couponNumber")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.cupon || "-"}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("partNumber")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.parte || "-"}
                </Typography>
              </div>
            </div>
            <div className={s.infoAmount}>
              <div className={s.row}>
                <p>{t("winningAmount")}</p>
                <Typography className={s.span} color="primary.main">
                  {<Currency value={coupons?.importeGanador} /> || "-"}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("retention")}</p>
                <Typography className={s.span} color="primary.main">
                  {<Currency value={coupons?.importeImpuesto} /> || "-"}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("finalAmount")}</p>
                <Typography className={s.span} color="primary.main">
                  {(
                    <Currency
                      value={coupons?.importeGanador - coupons?.importeImpuesto}
                    />
                  ) || "-"}
                </Typography>
              </div>
            </div>
            <div className={s.infoDate}>
              <div className={s.row}>
                <p>{t("state")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.estadoPremio || "-"}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("sellingAgency")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.pdvVendedor || "-"}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("payingAgency")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.pdvPagador || "-"}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("processDate")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.fechaProceso}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("paymentDate")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.fechaPago}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("uploadDate")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.fechaCarga}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("dateOfExpiry")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.fechaCaducidad}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("drawNumber")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.numeroSorteo || "-"}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("drawDate")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.fechaSorteo}
                </Typography>
              </div>
              <div className={s.row}>
                <p>{t("payingUser")}</p>
                <Typography className={s.span} color="primary.main">
                  {coupons?.usuarioPagador || "-"}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default ModalApuestas;
