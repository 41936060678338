import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import { Neto } from "../../../../api/accounting/accountingHelper";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import Chip from "@mui/material/Chip";
import {
  addFavAgencie,
  removeFavAgencie,
  setConcentrator,
  setSelectedAgencieToConsult,
} from "../../../../store/slices/my_agencies";
import { movementsUpdate } from "../../../../store/slices/gammaSlice";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { Currency } from "../../../../helpers/currencyConvert";
import { useTranslation } from "react-i18next";
import { getSettlements } from "../../../../api/general/generalService";
import { getCashStatus } from "../../../../api/saaservices/resumenCaja";

const SubAgenciesCollase = ({
  concentrator,
  favsAgencies,
  selectedSubAgencie,
  colorsPalette,
  saldoNeto,
  esConcentrator,
}) => {
  const navigate = useNavigate();
  // const concentrator = useSelector(state => state.myAgencies?.selected_concentrator );
  const dispatch = useDispatch();
  const { imputations } = useSelector((state) => state.gamma);
  const location = useLocation();
  const [favSubAgencie, setSubFavAgencie] = useState(false);
  const agencySelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult
  );
  const myAgencies = useSelector((state) => state.myAgencies);

  const { t } = useTranslation();
  // const handleSetSubFavAgencie = async (agencieToSetFav) => {
  //     if(favsAgencies?.length && favsAgencies?.some(a => a.id === agencieToSetFav.id)){
  //         dispatch(removeFavAgencie(agencieToSetFav?.id))
  //         setSubFavAgencie(false)
  //     }else if(favsAgencies?.length < 5){
  //         dispatch(addFavAgencie(agencieToSetFav))
  //         setSubFavAgencie(true)
  //     }else{
  //         Swal.fire({
  //             icon: "error",
  //             title: "Máximo 5 agencias como favoritas.",
  //           })
  //     }
  // }
  useEffect(() => {
    console.log("CONCENTRADORA:  ");
    console.log(concentrator);
  }, [selectedSubAgencie]);

  const showSaldo = (credit, debit) => {
    if (debit > 0 && credit == 0) {
      return debit * -1;
    }
    if (credit > 0 && debit == 0) {
      return credit;
    }
    return 0;
  };

  const handleSetAgencieToConsult = (agencie) => {
    dispatch(movementsUpdate([]));
    dispatch(setSelectedAgencieToConsult(agencie));

    dispatch(movementsUpdate([]));
    if (location.pathname.includes("/agencias")) {
      if (agencie?.agencyLevel === "CONCENTRATOR") {
        navigate(`/main/cuenta-corriente/${agencie?.number}`);
      } else {
        navigate(
          `/main/cuenta-corriente/${agencie?.number}-${agencie?.subNumber || 0}`
        );
      }
    }
  };

  useEffect(() => {
    if (
      favsAgencies?.length &&
      favsAgencies?.some(
        (favaAgencie) => favaAgencie?.id === selectedSubAgencie?.id
      )
    ) {
      setSubFavAgencie(true);
    }
  }, [favsAgencies, selectedSubAgencie]);

  return (
    <>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          backgroundColor: colorsPalette.primaryLight,
          borderRadius: "5px",
          padding: "0.8rem",
        }}
      >
        <Grid sx={{ width: "90%" }}>
          <Grid sx={{ fontSize: "1.5rem", display: "flex", ml: 3 }}>
            {/* <Checkbox checked={favSubAgencie} icon={<StarBorderIcon />} checkedIcon={<StarIcon sx={{color: colorsPalette.secondaryMain}}/>}
                    sx={{height: 30, transform:'scale(1.6)',
                    color: colorsPalette.secondaryMain, marginRight: "30px", zIndex: 100}}
                    onChange={() => handleSetSubFavAgencie(selectedSubAgencie)}/>  */}
            {selectedSubAgencie?.subNumber === null
              ? "C"
              : selectedSubAgencie?.subNumber}{" "}
            - {selectedSubAgencie?.name}
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography sx={{ fontSize: "1rem", display: "flex", ml: 3 }}>
              {!esConcentrator ? (
                <>
                  {" "}
                  {`${t("netBalance")}: `} <Currency value={saldoNeto} />
                </>
              ) : (
                <></>
              )}
            </Typography>
            {myAgencies.user_agencies
              .map((ag) => ag.subNumber)
              .reduce((acc, b) => b === null || acc, false) ? (
              <Chip
                label="Consultar"
                color="success"
                sx={{ cursor: "pointer" }}
                onClick={() => handleSetAgencieToConsult(selectedSubAgencie)}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
    </>
  );
};

export default SubAgenciesCollase;
