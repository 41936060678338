import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import StoreIcon from "@mui/icons-material/Store";
import styles from "./Collapse.module.css";
import StarIcon from "@mui/icons-material/Star";
import Checkbox from "@mui/material/Checkbox";
import { Tooltip, Typography } from "@mui/material";
import Chips from "../Chips/Chips";
import {
  addFavAgencie,
  removeFavAgencie,
} from "../../../../store/slices/my_agencies";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import SubAgenciesCollase from "../SubagenciesCollapse/SubAgenciesCollapse";
import ContactsIcon from "@mui/icons-material/Contacts";
import { Currency } from "../../../../helpers/currencyConvert";
import { getSettlements } from "../../../../api/general/generalService";
import { getCashStatus } from "../../../../api/saaservices/resumenCaja";
import { resetGammaSlice } from "../../../../store/slices/gammaSlice";
import { configDate } from "../../../../helpers/convertDate";
import { formatJava } from "../../../../config/strings";

const Collapse = ({ agencie, favsAgencies }) => {
  const [colorsPalette, setColorsPalette] = useState({});
  const [favAgencie, setFavAgencie] = useState(false);
  const [openAcordion, setOpenAcordion] = useState(false);
  const [selectedSubAgencie, setSelectedSubAgencie] = useState(false);
  const [subAgencies, setSubAgencies] = useState([]);
  const [totalRecaudation, setTotalRecaudation] = useState(0);
  const [prizesPaid, setPrizesPaid] = useState(0);
  const dispatch = useDispatch();
  const [moreSubAgencies, setMoreSubAgencies] = useState(false);
  const colors = useSelector((state) => state.local.uiConfig);
  const { t } = useTranslation();
  const [agencySettlement, setAgencySettlement] = useState({});
  const myAgencies = useSelector((state) => state.myAgencies);

  const { formatLocalDate } = configDate();
  // const handleSetFavAgencie = async (agencieToSetFav) => {
  //     if(favsAgencies?.length && favsAgencies?.some(a => a.id === agencieToSetFav?.id)){
  //         dispatch(removeFavAgencie(agencieToSetFav?.id))
  //         openAcordion ? setOpenAcordion(true) : setOpenAcordion(false)
  //         setFavAgencie(false)
  //     }else if(favsAgencies?.length < 5){
  //         dispatch(addFavAgencie(agencieToSetFav))
  //         openAcordion ? setOpenAcordion(true) : setOpenAcordion(false)
  //         setFavAgencie(true)
  //     }else{
  //         Swal.fire({
  //             icon: "error",
  //             title: "Máximo 5 agencias como favoritas.",
  //         })
  //         openAcordion ? setOpenAcordion(true) : setOpenAcordion(false)
  //     }
  // }

  useEffect(() => {
    console.log("ENTRE ACAAAA: ", selectedSubAgencie);
    if (selectedSubAgencie?.subNumber !== null) {
      getCashStatus(
        selectedSubAgencie?.organization?.id,
        selectedSubAgencie?.number,
        selectedSubAgencie?.subNumber
      ).then((res) => {
        setTotalRecaudation(res.totalRecaudacion);
        setPrizesPaid(res.totalPremios);
      });
    } else {
      getCashStatus(
        selectedSubAgencie?.organization?.id,
        selectedSubAgencie?.number,
        -1
      ).then((res) => {
        setTotalRecaudation(res.totalRecaudacion);
        setPrizesPaid(res.totalPremios);
      });
    }
  }, [selectedSubAgencie]);

  useEffect(() => {
    getSettlements(agencie).then((res) => setAgencySettlement(res));
    getCashStatus(agencie.organization.id, agencie.number, -1).then((res) => {
      setTotalRecaudation(res.totalRecaudacion);
      setPrizesPaid(res.totalPremios);
    });
    setSelectedSubAgencie(
      subAgencies?.find((sub) => sub?.agencyLevel === "CONCENTRATOR")
    );
  }, []);

  useEffect(() => {
    setColorsPalette({
      primaryLight: colors.primary.light,
      primaryMain: colors.primary.main,
      primaryDark: colors.primary.dark,
      secondaryLight: colors.secondary.light,
      secondaryMain: colors.secondary.main,
      secondaryDark: colors.secondary.dark,
    });
  }, [colors]);

  useEffect(() => {
    if (
      favsAgencies?.length &&
      favsAgencies?.some((favaAgencie) => favaAgencie?.id === agencie?.id)
    ) {
      setFavAgencie(true);
    }
  }, [favsAgencies, agencie]);

  useEffect(() => {
    if (agencie?.childrenAgencies?.length > 0) {
      if (moreSubAgencies === true) {
        setSubAgencies(
          agencie?.childrenAgencies?.length > 0 &&
            [...agencie?.childrenAgencies]?.reverse()
        );
        setSubAgencies((prevState) => [agencie, ...prevState]);
      } else {
        setSubAgencies(
          agencie?.childrenAgencies?.length > 0 &&
            [...agencie?.childrenAgencies]?.reverse()?.slice(0, 5)
        );
        setSubAgencies((prevState) => [agencie, ...prevState]);
      }
    } else {
      setSelectedSubAgencie(agencie);
    }
  }, [moreSubAgencies, agencie?.childrenAgencies]);

  const showSaldo = (credit, debit) => {
    if (debit > 0 && credit == 0) {
      return debit * -1;
    }
    if (credit > 0 && debit == 0) {
      return credit;
    }
    return 0;
  };
  console.log("sub", subAgencies);

  return (
    <>
      <div
        className={styles.box}
        key={`${agencie?.name}_${Math.random() + agencie?.id + 1}`}
      >
        <Accordion
          sx={{
            width: "100%",
            padding: "5px",
            "&.MuiAccordion-root": {
              borderRadius: 2,
              boxShadow: "0px 0px 15px -3px rgba(0, 0, 0, 0.268)",
            },
          }}
          expanded={openAcordion}
          onChange={() => setOpenAcordion((open) => !open)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: colorsPalette.primaryMain, fontSize: "2rem" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid
              sx={{ fontSize: "1.2rem", display: "flex", alignItems: "center" }}
            >
              <ContactsIcon
                color="primary"
                sx={{ mr: 2, fontSize: "1.5rem" }}
              />
              {/* <Checkbox checked={favAgencie} icon={<StarBorderIcon sx={{fontSize:'1.2rem'}}/>} checkedIcon={<StarIcon sx={{color: colorsPalette.secondaryMain}}/>}
                    sx={{height: 30, transform:'scale(1.6)',
                    color: colorsPalette.secondaryMain, marginRight: "30px", zIndex: 20}}
                    onChange={() => handleSetFavAgencie(agencie)}/> */}
              {`${agencie?.number}${
                agencie?.subNumber ? " - " + agencie?.subNumber : ""
              } ${agencie?.name}`}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1.2rem",
              }}
            >
              <Grid item>
                <p style={{ display: "flex" }}>
                  {t("TotalCollection")} <Currency value={totalRecaudation} />
                </p>
                {console.log(agencySettlement)}
                {selectedSubAgencie?.agencyLevel === "CONCENTRATOR" && (
                  <Grid item sx={{ fontSize: "1.2rem", display: "flex" }}>
                    {agencySettlement.report != null
                      ? agencySettlement?.message + ": "
                      : agencySettlement?.message || "Sin datos de estado"}
                    {selectedSubAgencie?.agencyLevel === "CONCENTRATOR" &&
                    agencySettlement.report != null ? (
                      <Box sx={{ ml: "10px" }}>
                        {console.log(agencySettlement)}
                        <Currency
                          value={showSaldo(
                            agencySettlement?.agencySettlement
                              ?.balanceAmountCredit,
                            agencySettlement?.agencySettlement
                              ?.balanceAmountDebit
                          )}
                        />
                      </Box>
                    ) : null}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1.2rem",
              }}
            >
              <Grid item sx={{ fontSize: "1.2rem", display: "flex" }}>
                {t("prizesPaid")} <Currency value={prizesPaid} />
              </Grid>
              <Grid item xs={6} md={5} lg={5}>
                {selectedSubAgencie?.agencyLevel === "CONCENTRATOR" && (
                  <Typography sx={{ fontSize: "1.2rem" }}>
                    {t("expirationDate")}:{" "}
                    {formatLocalDate(
                      agencySettlement?.agencySettlement?.settlement
                        ?.settlementEvent?.debitBankExpiration1,
                      formatJava
                    ) || "-"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} md={5} lg={5}>
                <p
                  style={{
                    backgroundColor: colorsPalette.secondaryMain,
                    visibility: "hidden",
                    marginLeft: 10,
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  {agencie?.agencyLevel === "CONCENTRATOR"
                    ? t("concentradora")
                    : t("agency")}
                </p>
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <p
                  style={{
                    backgroundColor: colorsPalette.secondaryMain,
                    align: "right",
                    marginLeft: 10,
                    color: "white",
                    padding: 7,
                    borderRadius: 5,
                    textAlign: "center",
                  }}
                >
                  {selectedSubAgencie?.agencyLevel === "CONCENTRATOR"
                    ? t("concentradora")
                    : t("agency")}
                </p>
              </Grid>
            </Grid>
            {agencie?.agencyLevel === "CONCENTRATOR" ? (
              <>
                <div className={styles.agencies}>
                  <Grid>
                    <StoreIcon
                      sx={{
                        color: colorsPalette.primaryMain,
                        height: "60px",
                        fontSize: "2rem",
                        marginTop: "30px",
                      }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid className={styles.chips}>
                      {Array.isArray(subAgencies) && subAgencies?.length ? (
                        subAgencies
                          ?.sort((a, b) => a.subNumber - b.subNumber)
                          .filter((sub) => sub.status !== "DISABLED")
                          ?.map((subAgencie) => {
                            return (
                              <Chips
                                key={`${subAgencie?.name}_${
                                  Math.random() + subAgencie?.id + 1
                                }`}
                                subAgencie={subAgencie}
                                setOpenAcordion={setOpenAcordion}
                                colorsPalette={colorsPalette}
                                selectedSubAgencie={selectedSubAgencie}
                                setSelectedSubAgencie={setSelectedSubAgencie}
                              />
                            );
                          })
                      ) : (
                        <Chips />
                      )}
                    </Grid>
                    <Grid sx={{ height: "60px", marginTop: "15px" }}>
                      {agencie?.childrenAgencies?.length > 5 ? (
                        <>
                          {!moreSubAgencies ? (
                            <Tooltip
                              title="Ver más subagencias"
                              onClick={() => setMoreSubAgencies(true)}
                            >
                              <PlaylistAddIcon
                                sx={{
                                  color: colorsPalette.primaryMain,
                                  fontSize: "2.5rem",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Ocultar subagencias"
                              onClick={() => setMoreSubAgencies(false)}
                            >
                              <PlaylistRemoveIcon
                                sx={{
                                  color: colorsPalette.primaryMain,
                                  fontSize: "2.5rem",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.selectedSubAgencie}>
                  {selectedSubAgencie ? (
                    <>
                      {" "}
                      <SubAgenciesCollase
                        favsAgencies={favsAgencies}
                        selectedSubAgencie={selectedSubAgencie}
                        colorsPalette={colorsPalette}
                        setAgencySettlement={setAgencySettlement}
                        saldoNeto={totalRecaudation - prizesPaid}
                        esConcentrator={
                          selectedSubAgencie?.agencyLevel === "CONCENTRATOR"
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={styles.selectedSubAgencie}>
                  {
                    <SubAgenciesCollase
                      concentrator={agencie}
                      favsAgencies={favsAgencies}
                      selectedSubAgencie={selectedSubAgencie}
                      colorsPalette={colorsPalette}
                      setAgencySettlement={setAgencySettlement}
                      saldoNeto={totalRecaudation - prizesPaid}
                      esConcentrator={
                        selectedSubAgencie?.agencyLevel === "CONCENTRATOR"
                      }
                    />
                  }
                </div>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Collapse;
