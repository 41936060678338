import { Login } from "../../../../components/Pages/Login";
import { NavBar } from "../../../../components/Navigation/NavBar";
import { Main } from "../../../../components/Pages/Main";
// import { PageNotFound } from "../../../../components/ErrorPages/PageNotFound";
import { Agencias } from "../../../../components/Pages/Agencias";
import { CuentaCorriente } from "../../../../components/Pages/CuentaCorriente";
import { Premios } from "../../../../components/Pages/Premios";
import { ControlCaja } from "../../../../components/Pages/ControlCaja";
import { OnLine } from "../../../../components/Pages/OnLine";
import { Ranking } from "../../../../components/Pages/Ranking";
import { Documentos } from "../../../../components/Pages/Documentos";
import { Ayuda } from "../../../../components/Pages/Ayuda";
import { NavBarAdmin } from "../../../../components/Navigation/NavBarAdmin";
import { Users } from "../../../../components/Pages/Users";
import { TermsAndConditions } from "../../../../components/Pages/TermsAndConditions";
import { Colors } from "../../../Pages/Colors";
import Concentrators from "../../../../components/Pages/Concentrators/Concentrators";
import { LogOut } from "../../../../actions/LogOut";
import { LoginTerms } from "../../../../components/Pages/LoginTerms";
import { CreateUser } from "../../../../components/Pages/Users/CreateUser/CreateUser";
import { Configure } from "../../../../components/Pages/Configure";
import PrivateRoute from "../../../../helpers/PrivateRoute";
import AgencieSelected from "../../../Pages/Concentrators/AgencieSelected/AgencieSelected.jsx";
import { ChangeTerms } from "../../../../components/Pages/TermsAndConditions/changeTerms";
import { AddTerms } from "../../../../components/Pages/TermsAndConditions/addTerms.jsx/index";
import { CreateConfigure } from "../../../../components/Pages/Configure/addConfigure";
import { AboutVersion } from "../../../../components/Pages/AboutVersion";
import { Preload } from "../../../../helpers/Preload";
import { Organizations } from "../../../Pages/Organizations";
import { Logos } from "../../../Pages/Logos";
import ConcentratorSelected from "../../../Pages/Concentrators/ConcentratorSelected/ConcentratorSelected";
import { ServerError } from "../../../ErrorPages/ServerError";
import UsersRefactor from "../../../Pages/Refactor User";
import { MercadoPago } from "../../../Pages/MercadoPago";
import ManualDeUso from "../../../Pages/ManualDeUso";

export const keycloakRoutes = [
  {
    path: "/main",
    element: (
      <PrivateRoute>
        <NavBar />
      </PrivateRoute>
    ),
    children: [
      // {
      //   path: "users",
      //   element: <PrivateRoute><UsersRefactor /></PrivateRoute>
      // },
      // {
      //   path: "usersrefactor",
      //   element: <PrivateRoute><UsersRefactor /></PrivateRoute>
      // },
      // {
      //   path: "users/create",
      //   element: <PrivateRoute><CreateUser /></PrivateRoute>,
      // },
      {
        path: "panel",
        element: (
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        ),
      },
      {
        path: "agencias",
        element: (
          <PrivateRoute>
            <Agencias />
          </PrivateRoute>
        ),
      },
      {
        path: "cuenta-corriente/:num",
        element: (
          <PrivateRoute>
            <CuentaCorriente />
          </PrivateRoute>
        ),
      },
      {
        path: "cuenta-corriente",
        element: (
          <PrivateRoute>
            <CuentaCorriente />
          </PrivateRoute>
        ),
      },
      {
        path: "premios",
        element: (
          <PrivateRoute>
            <Premios />
          </PrivateRoute>
        ),
      },
      {
        path: "premios/:num",
        element: (
          <PrivateRoute>
            <Premios />
          </PrivateRoute>
        ),
      },
      {
        path: "control-caja",
        element: (
          <PrivateRoute>
            <ControlCaja />
          </PrivateRoute>
        ),
      },
      {
        path: "control-caja/:num",
        element: (
          <PrivateRoute>
            <ControlCaja />
          </PrivateRoute>
        ),
      },
      {
        path: "on-line",
        element: (
          <PrivateRoute>
            <OnLine />
          </PrivateRoute>
        ),
      },
      {
        path: "mercado-pago",
        element: (
          <PrivateRoute>
            <MercadoPago />
          </PrivateRoute>
        ),
      },
      {
        path: "manual-de-uso",
        element: (
          <PrivateRoute>
            <ManualDeUso />
          </PrivateRoute>
        ),
      },
      {
        path: "ranking",
        element: (
          <PrivateRoute>
            <Ranking />
          </PrivateRoute>
        ),
      },
      {
        path: "documentos",
        element: (
          <PrivateRoute>
            <Documentos />
          </PrivateRoute>
        ),
      },
      {
        path: "ayuda",
        element: (
          <PrivateRoute>
            <Ayuda />
          </PrivateRoute>
        ),
      },
      {
        path: "logout",
        element: (
          <PrivateRoute>
            <LogOut />
          </PrivateRoute>
        ),
      },
      {
        path: "about",
        element: (
          <PrivateRoute>
            <AboutVersion />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <Login />,
    // errorElement: <PageNotFound />,
  },
  {
    path: "/check-user",
    element: <Preload />,
  },
  {
    path: "/terms",
    element: (
      <PrivateRoute>
        <LoginTerms />
      </PrivateRoute>
    ),
    // errorElement: <PageNotFound />,
  },
  {
    path: "/organizations",
    element: (
      <PrivateRoute>
        <Organizations />
      </PrivateRoute>
    ),
  },
  {
    path: "/server-error/:code",
    element: (
      <PrivateRoute>
        <ServerError />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin",
    element: (
      <PrivateRoute>
        <NavBarAdmin />
      </PrivateRoute>
    ),
    children: [
      {
        path: "users",
        element: (
          <PrivateRoute>
            <UsersRefactor />
          </PrivateRoute>
        ),
      },
      // {
      //   path: "usersrefactor",
      //   element: <PrivateRoute><UsersRefactor /></PrivateRoute>
      // },
      // {
      //   path: "users/create",
      //   element: <PrivateRoute><CreateUser /></PrivateRoute>,
      // },
      {
        path: "sales_points",
        element: (
          <PrivateRoute>
            <Concentrators />
          </PrivateRoute>
        ),
      },
      {
        path: "sales_points/:name/:numAndSub/:id",
        element: (
          <PrivateRoute>
            <ConcentratorSelected />
          </PrivateRoute>
        ),
      },
      {
        path: "sale_point/:name/:numAndSub/:id",
        element: (
          <PrivateRoute>
            <AgencieSelected />
          </PrivateRoute>
        ),
      },
      {
        path: "terms_and_conditions",
        element: (
          <PrivateRoute>
            <TermsAndConditions />
          </PrivateRoute>
        ),
      },
      {
        path: "terms_and_conditions/add",
        element: (
          <PrivateRoute>
            <AddTerms />
          </PrivateRoute>
        ),
      },
      {
        path: "terms_and_conditions/:id",
        element: (
          <PrivateRoute>
            <ChangeTerms />
          </PrivateRoute>
        ),
      },
      {
        path: "colors",
        element: (
          <PrivateRoute>
            <Colors />
          </PrivateRoute>
        ),
      },
      {
        path: "logos",
        element: (
          <PrivateRoute>
            <Logos />
          </PrivateRoute>
        ),
      },
      {
        path: "configure",
        element: (
          <PrivateRoute>
            <Configure />
          </PrivateRoute>
        ),
      },
      {
        path: "configure/create",
        element: (
          <PrivateRoute>
            <CreateConfigure />
          </PrivateRoute>
        ),
      },
      {
        path: "logout",
        element: (
          <PrivateRoute>
            <LogOut />
          </PrivateRoute>
        ),
      },
    ],
  },
];
